import React, {useState} from "react";
import {Auth, Hub} from 'aws-amplify';
import {TextInput} from "../FormInput/TextInput";
import {Text, VStack, Center, Heading, Button} from "native-base";
import {SpinnerComponent} from "../SpinnerComponent/spinner";
import { SignUpTypes } from "./signInConstants";

export function SignIn({email, emailErrorText, handleEmailChange, validateEmail, setAuthType, setCognitoUser, setAlert, setFormState}) {
    const [sendingOTP, setSendingOTP] = useState(false)

    /**
     * Listens to sign in failure events and updates the form accordingly
     */
    Hub.listen('auth', (data) => {
        const event = data.payload.event;
        if (event === "signIn_failure") {
            if (data.payload.data.code === 'UserNotFoundException') {
                setAlert({
                    header: 'Sign In Failure',
                    body: 'User Not Found! Please Sign Up',
                    status: 'error'
                })
            } else if(data.payload.data.code === 'UserNotConfirmedException') {
                setAlert({
                    header: 'Sign In Failure',
                    body: 'User Not Confirmed! Please provide verification code sent to your email',
                    status: 'error'
                })
                setFormState("confirmUser");
            } else {
                console.error(`Sign In failed with ${data.payload.data.code} error`)
                setAlert({
                    header: 'Sign In Failure',
                    body: `Error Occurred! Please try again`,
                    status: 'error'
                })
            }
        }
    })

    /**
     * Sends OTP to Chime or Slack channel if user is already signed up with any one method 
     */
    async function signIn() {
        try {
            if (!validateEmail(email)) {
                return;
            }
            setSendingOTP(true)
            await Auth.signIn(email.toLowerCase()).then(async (user) => {
                setCognitoUser(user)
                if (user.challengeName === 'CUSTOM_CHALLENGE') {
                    setSendingOTP(false)
                    setAlert({
                        header: 'OTP Sent Success',
                        body: 'OTP was sent successfully! Please check your Chime or Slack channel',
                        status: 'success'
                    })
                    setFormState('signInOTP')
                }
            });
        } catch (err) { 
            setSendingOTP(false)
            console.error('Error encountered while signing in user', err)
         }
    }

    return (
        <VStack space={3} marginTop={"1rem"}>
            <Center><Heading size="md">Sign In</Heading></Center>
            <TextInput value={email} errorText= {emailErrorText} onChangeHandler = {handleEmailChange} placeholder='Email Address'/>
            {
                sendingOTP  &&
                <SpinnerComponent text = {'Sending OTP to Chime or Slack channel....'} />
            }
            <Button colorScheme="lightBlue" variant="rounded" 
                onPress={() => {signIn()}} >
                Sign In
            </Button>
            <VStack space={3} marginTop={"2rem"}>
                <Text bold>Not a signed up user ?</Text>
                <Button colorScheme="lightBlue" variant="rounded" 
                    onPress={() => {
                        setAuthType(SignUpTypes.CHIME)
                        setFormState('signUp')}}>
                    Sign Up With Chime
                </Button>
                <Button colorScheme="lightBlue" variant="rounded" 
                    onPress={() => {
                        setAuthType(SignUpTypes.SLACK)
                        setFormState('signUp')}}>
                    Sign Up With Slack
                </Button>
            </VStack>
        </VStack>
    );
}
