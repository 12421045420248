import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../../styles/style";
import React, {useState} from "react";
import {View} from "react-native-web";
import {
    NativeBaseProvider,
    Button,
    Divider,
    Box
} from "native-base";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import TextComponent, {STOP_WORKFLOW} from "../../components/TextInputComponent/component";
import DynamicTextComponent from "../../components/DynamicTextComponent/dynamic-text-component";
import {
    CHECKIN_PAGE_INDEX_START_INCLUSIVE,
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    MULTI_CHECK_IN_PAGE,
    REVIEW_RE_CHECK_IN_PAGE
} from "../../utils/constants";
import AlertText from "../../components/AlertText/alert-text-component";

function ReCheckIn(props) {
    const [nextDisabled, setNextDisabled] = useState(true)
    const [prevDisabled, setPrevDisabled] = useState(false)
    const [stopCheckInFlow, setStopCheckInFlow] = useState(props.checkInFormData[STOP_WORKFLOW])
    const newCheckInNote = "Note: For more than 10 representatives, please perform new check-in."
    const reCheckInNote = "To continue easy check-in for the selected supplier, please continue by answering below questions."

    function handleNextButtonClick() {
        if (props.checkInFormData['supplierNamesList'] === undefined || props.checkInFormData['supplierNamesList'].length === 0 ) {
            props.checkInValidationSetter((prev) => { return  {
                ...prev,
                supplierNamesList : 'Please provide at least a supplier name and click + button'}
            })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, supplierNamesList : ''} })
        }
        return props.checkInPageChanger(stopCheckInFlow === true ? CHECKOUT_PAGE_ACTION_PAGE_INDEX : REVIEW_RE_CHECK_IN_PAGE);
    }

    function handleNewCheckInClick() {
        // On easy check-in page if there are more than 10 supplier rep to be checked-in and
        // when new check-in button is clicked, this makes sure
        // that supplier representative name field is empty while other form fields are prefilled for the user.
        props.checkInFormDataSetter(previous => {
            return {
                ...previous,
                supplierNamesList: []
            }
        });
        // clears any validation error retained from this(easy check-in) page
        props.clearCheckInFormValidationErrorsMessages();
        return props.checkInPageChanger(CHECKIN_PAGE_INDEX_START_INCLUSIVE);
    }

    return (
        <View style={styles.header}>
            <>
                <AlertText padding={'1rem'} header={newCheckInNote} >
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        <Button
                            marginTop={"1rem"}
                            minW={"250"}
                            colorScheme="lightBlue"
                            variant="rounded"
                            onPress={() => handleNewCheckInClick()}>
                            New Check-In
                        </Button>
                    </NativeBaseProvider>
                </AlertText>
                <Divider mt={"0.5rem"}
                         mb={"0.5rem"}
                         bg="grey"
                         thickness="4"
                         orientation="horizontal" />
                <AlertText header={reCheckInNote} />
            </>

            <Box>
                <DynamicTextComponent attributeName = 'supplierNamesList'
                                      placeholder='Full Name'
                                      {... props}/>
                <TextComponent placeholder = "Contractor/Vendor Company"
                           readonly={true}
                           avettaDisabled={false}
                           required={true}
                           label = "Name of the company providing services?"
                           maxWidth='300'
                           minWidth='300'
                           rightElement = {true}
                           setNextDisabled = {setNextDisabled}
                           setPrevDisabled = {setPrevDisabled}
                           setStopCheckInFlow = {setStopCheckInFlow}
                           attributeName = 'contractorName'
                           {...props}
                />
            </Box>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button
                    onPress= {() => handleNextButtonClick()}
                    marginTop={"1rem"}
                    colorScheme="lightBlue"
                    variant="rounded"
                    isDisabled={nextDisabled}
                    minW={300}
                >
                    Next
                </Button>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkInPageChanger(MULTI_CHECK_IN_PAGE)}
                        variant="rounded"
                        isDisabled={prevDisabled}
                        minW={300}>
                        Previous
                </Button>
            </NativeBaseProvider>
        </View>
    );
}

export default withAuthenticator(ReCheckIn);