import React, {useEffect} from "react";
import {Badge, Box, Button, Center, Container, FormControl, HStack, NativeBaseProvider, VStack} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import Info from "../components/Info/info";
import getCheckins from "../getCheckIns";
import {
    CHECKIN_LIST_PAGE_INDEX,
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    SITE_CHECKINS_FORM, YELLOW_BADGE_CHECKINFLOW, YELLOW_BADGE_CHECKOUT, YELLOW_BADGE_PROFILE,
    YELLOW_BADGE_SETUP
} from "../utils/constants";
import {selfCheckIn} from "../service-integrations/self-check-in";
import {getProfile} from "../service-integrations/profile";
import {performAvettaCall} from "../service-integrations/avetta-integration";

async function submitCheckIn (props) {
    let result = await performAvettaCall(props)
    alert(result)
    selfCheckIn(props)
}

export function WelcomeSelfServiceFlow({ pageChanger,
                                signOut,
                                user,
                                checkInDataSetter,
                                pendingCheckoutListSetter,
                                myCheckInListDataSetter,
                                checkInData,
                                pendingCheckOutList,
                                myCheckInList,
                                siteCheckInListSetter,
                                siteCheckInList,
                                loggedInUser,
                                ...props}) {
    useEffect( () => {
        getCheckins(checkInDataSetter, pendingCheckoutListSetter, myCheckInListDataSetter)
        getProfile(props, loggedInUser)
    }, []);

    return (
        <Box>
            <Center>
                <Info
                    heading= "Yellow Badge Home screen"/>
            </Center>
            <VStack>
                <NativeBaseProvider theme={RoundedButtonTheme}>
                    <Center>
                        <FormControl.Label>Self Check-In/Check-Out Tool</FormControl.Label>
                    </Center>
                    <Button size={"lg"}
                            minWidth={300}
                            marginTop={"2rem"}
                            colorScheme="lightBlue"
                            onPress={() => pageChanger(YELLOW_BADGE_PROFILE)}
                            variant="rounded">
                        My Profile
                    </Button>
                    <Button size={"lg"}
                            minWidth={300}
                            marginTop={"2rem"}
                            colorScheme="lightBlue"
                            onPress={() => submitCheckIn(props)}
                            variant="rounded">
                        1-Click Check In
                    </Button>
                    <VStack>
                        <Badge
                            marginTop={"2rem"}
                            marginRight={"0.5rem"}
                            colorScheme={pendingCheckOutList.length === 0 ? "success:" : "danger"}
                            rounded="full" mb={-4} mr={-4} zIndex={1} variant="solid" alignSelf="flex-end" _text={{
                            fontSize: 12
                        }}>
                            {pendingCheckOutList.length}
                        </Badge>
                        <Button bg={pendingCheckOutList.length === 0 ? "green.500": "orange.500"} size={"lg"} minWidth={300} colorScheme="orange" onPress={() =>
                            pageChanger(CHECKOUT_PAGE_ACTION_PAGE_INDEX)
                        } variant="rounded"> My Pending Check-Outs </Button>
                    </VStack>
                    <Button bg={"lightBlue.500"}
                            size={"lg"}
                            marginTop={"2rem"}
                            minWidth={300}
                            colorScheme={"lightBlue.500"}
                            onPress={
                                () =>
                                    pageChanger(YELLOW_BADGE_CHECKOUT)
                            } variant="rounded"> 1-Click Check out </Button>
                </NativeBaseProvider>
            </VStack>
        </Box>
    )
}