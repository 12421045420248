import { Auth } from 'aws-amplify'
import {isLseEvent} from "../utils/constants";
import {
    PROFILE_COUNTRY,
    PROFILE_JOB_ASSESSMENT,
    PROFILE_ORG,
    PROFILE_SITE_CODE
} from "../constants/attribute-names-constants";
import {performAvettaCall} from "./avetta-integration";

const sleep = ms => new Promise((resolve) => setTimeout(resolve, ms));

export function selfCheckIn(props) {
    return sleep(10).then(() => {
        const formDataPayload = getSubmitPayload(props)
        let token = "";
        Auth.currentAuthenticatedUser().then((data) => {
            let myHeaders = new Headers();
            token = data.signInUserSession.idToken.jwtToken;
            myHeaders.append("Authorization", "Bearer " + token)
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(formDataPayload),
                redirect: 'follow',
            };

            console.log(JSON.stringify(requestOptions));
            // TODO: When implementing self serve changes. Use the correct endpoint based on region/country.
            const url = 'placeholder.a2z.com'
            fetch(url, requestOptions)
                .then((response) => {
                    return response.json().then((data) => ({
                        response: response,
                        status: response.status,
                        data,
                    }));
                })
                .then((result) => {
                    if (result.response.ok) {
                        //propsWithAllFormData.checkInPageChanger(nextPage)
                        alert("Check in data Submitted!")
                    } else {
                        alert('Error occurred while checking in the supplier, please try again.')
                    }
                })
                .catch(error => {
                    alert('Error occurred while checking in the supplier, please try again.')
                });
        });
    });
}


function getSubmitPayload(propsWithAllFormData) {
    let checkOutDate = new Date();
    checkOutDate.setDate(new Date().getDate() + ((propsWithAllFormData.checkInFormData.daysOnSite || 1) - 1 ))
    const isThisLseEvent = isLseEvent(propsWithAllFormData)

    const payloadValuesJson = {
        "cpoc_amazon_alias": propsWithAllFormData.checkInFormData.alias,
        "supplier_representatives_list": [propsWithAllFormData.checkInFormData.alias],
        "avetta_compliance_status_code": propsWithAllFormData.checkInFormData.avettaComplianceStatusCode,
        "cpoc_org_name": propsWithAllFormData.checkInFormData[PROFILE_ORG],
        "supplier_company_name": propsWithAllFormData.checkInFormData.contractorName,
        "country": propsWithAllFormData.checkInFormData[PROFILE_COUNTRY],
        "site_code": propsWithAllFormData.checkInFormData[PROFILE_SITE_CODE],
        "job_type": propsWithAllFormData.checkInFormData[PROFILE_JOB_ASSESSMENT],
        "containsSev": false,
        "checkout_day": checkOutDate.toISOString(),
        "po_number": '',
        "po_order_valid": true,
        "supplier_virtual_orientation_completed": true,
        "jha_approved_by_whs": true,
        "supplier_category_approved": true
    };

    return payloadValuesJson;
}
