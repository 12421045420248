import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../styles/style";
import TextComponent, {STOP_WORKFLOW} from "../components/TextInputComponent/component";
import React, {useState} from "react";
import {View} from "react-native-web";
import {Button, Center, Divider, NativeBaseProvider} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import Info from "../components/Info/info";
import Label from "../components/Label/label";
import {TEXT_COMPONENT_ATTRIBUTE_NAME_KEY} from "../constants/attribute-names-constants";

function Page2(props) {
    const [nextDisabled, setNextDisabled] = useState(true)
    const [prevDisabled, setPrevDisabled] = useState(false)
    const [stopCheckInFlow, setStopCheckInFlow] = useState(props.checkInFormData[STOP_WORKFLOW])
    const avettaDisabled = props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY]
        && props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY].trim().length === 0

    return (
        <View style={styles.header}>
            <Center>
                <Info heading= "Note to Contractor's / Vendor's Point of Contact"
                      content={"If the person you are signing in works for a sub-contractor, please enter the sub-contractor’s name under name of the company below."}/>
                <Label labelText={'Avetta compliance screen'}></Label>
            </Center>

            <TextComponent placeholder = "Contractor/Vendor Company"
                           required={true}
                           avettaDisabled={avettaDisabled}
                           label = "Name of the company providing services?"
                           maxWidth='300'
                           minWidth='300'
                           rightElement = {true}
                           setNextDisabled = {setNextDisabled}
                           setPrevDisabled = {setPrevDisabled}
                           setStopCheckInFlow = {setStopCheckInFlow}
                           attributeName = 'contractorName'
                           {...props}
            />
            <Center>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button
                    onPress= {() => props.checkInPageChanger(stopCheckInFlow === true ? 8 : 3)}
                    marginTop={"1rem"}
                    colorScheme="lightBlue"
                    variant="rounded"
                    isDisabled={nextDisabled}
                    minW={300}
                >
                    Next
                </Button>
            </NativeBaseProvider>

            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkInPageChanger(1)}
                        variant="rounded"
                        isDisabled={prevDisabled}
                        minW={300}>
                    Previous
                </Button>
            </NativeBaseProvider>
            </Center>
        </View>
    );
}

export default withAuthenticator(Page2);