import {
    SUPPLIER_CHECK_IN_AWS_REGION,
    COGNITO_IDENTITY_POOL_ID,
    COGNITO_REGION,
    COGNITO_USER_POOL_ID,
    COGNITO_USER_POOL_WEB_CLIENT_ID
} from "src/config";

const awsmobile = {
    "aws_project_region": SUPPLIER_CHECK_IN_AWS_REGION,
    "aws_cognito_identity_pool_id": COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": COGNITO_REGION,
    "aws_user_pools_id": COGNITO_USER_POOL_ID,
    "aws_user_pools_web_client_id": COGNITO_USER_POOL_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsmobile;
