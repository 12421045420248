import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../styles/style";
import {Button, Center, FlatList, NativeBaseProvider, Text, View} from "native-base";
import AlertText from "../components/AlertText/alert-text-component";
import StyledRecord from "../components/StyledRecord/styled-record";
import {isEligibleForMultiDaysCheckIn} from "../utils/utils";
import Label from "../components/Label/label";
import React from "react";
import {HOME_PAGE_INDEX} from "../utils/constants";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";

function MultiDateCheckInPage(props) {
    const checkInRecords = props.checkInData ? props.checkInData : [];
    const eligibleMultiDaysCheckInRecord = checkInRecords.filter(record => isEligibleForMultiDaysCheckIn(record))
    return (
        <View>
            <View style={styles.header}>
                <AlertText
                    header={"If you do not find the check-in you are looking for please perform a fresh check-in"}
                />
                {
                    eligibleMultiDaysCheckInRecord && eligibleMultiDaysCheckInRecord.length > 0 ?
                    (
                        <Center>
                            <Label labelText={"Eligible Multi-Date Check-ins!"} />
                        </Center>

                    ) : (
                        <Center>
                            <Label labelText={"No Multi-Date Check-ins record found!"} />
                            <NativeBaseProvider theme={RoundedButtonTheme}>
                                <Button marginTop={"2rem"}
                                        colorScheme="lightBlue"
                                        onPress={() => props.pageChanger(HOME_PAGE_INDEX)}
                                        variant="rounded">
                                    Back to home
                                </Button>
                            </NativeBaseProvider>
                        </Center>
                        )
                }
            </View>
            <FlatList
                data={eligibleMultiDaysCheckInRecord}
                renderItem={({item: record}) =>
                    <StyledRecord key={record.sk} record={record} buttonLabel={'Re-Check In'} props={props}/>
            }/>
        </View>
    )
}

export default withAuthenticator(MultiDateCheckInPage);
