import {withAuthenticator} from "@aws-amplify/ui-react";
import React from "react";
import {View} from "react-native";
import {
    NativeBaseProvider,
    Button,
    Box,
    FormControl
} from "native-base";
import {styles} from "../../styles/style";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import {
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    CHECKOUT_PAGE_END_INDEX_INCLUSIVE, CHECKOUT_PAGE_REVIEW,
    HOME_PAGE_INDEX
} from "../../utils/constants";
import Label from "../../components/Label/label";
import SimpleRadio from "../../components/SimpleRadio/simple-radio";

function CheckoutScreen(props) {
    const validateCheckOutScreen1 = (props) => {
        if (props.workScopeRadio['workScopeCompleted'] === undefined || props.workScopeRadio['workScopeCompleted'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, workScopeCompleted : 'Please select an option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, workScopeCompleted : ''} })
        }

        if (props.workAuditRadio['workAudit'] === undefined || props.workAuditRadio['workAudit'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, workAudit : 'Please select an option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, workAudit : ''} })
        }

        if (props.escortContractorRadio['escortContractor'] === undefined || props.escortContractorRadio['escortContractor'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, escortContractor : 'Please select an option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, escortContractor : ''} })
        }

        // if everything is great then move on
        props.checkOutIndexChanger(CHECKOUT_PAGE_END_INDEX_INCLUSIVE)
    }

    return (
        <View style={styles.header}>
            <Box>
                <Label labelText="Supplier representative name"
                       truncated={false}
                       marginTop={"1rem"}
                       maxWidth={300}
                       showAsRequired={false}
                >
                </Label>
                <FormControl.Label>
                    {props.checkInFormData.checkOutSupplierNameKey}
                </FormControl.Label>
                <Label labelText="Scope of work was completed?"
                       truncated={false}
                       maxWidth={300}
                       showAsRequired={true}
                       marginTop={"1rem"}
                >

                </Label>
                <SimpleRadio placeholder = "Scope of work was completed?"
                             colorScheme = "amber"
                             options = {[
                                 {
                                     id: 'Yes',
                                     value: 'Yes'
                                 },
                                 {
                                     id: 'No',
                                     value: 'No'
                                 },
                                 {
                                     id: 'Partial',
                                     value: 'Partial'
                                 }
                             ]}
                             attributeName='workScopeCompleted'
                             checkInFormDataRadio = {props.workScopeRadio}
                             checkInFormDataRadioSetters = {props.workScopeRadioSetter}
                             {... props}
                />

                <Label labelText="Did you audit the work performed?"
                       truncated={false}
                       maxWidth={300}
                       showAsRequired={true}
                       marginTop={"1rem"}
                >

                </Label>
                <SimpleRadio placeholder = "Did you audit the work performed?"
                             required = {false}
                             colorScheme = "amber"
                             options = {[
                                 {
                                     id: 'Yes',
                                     value: 'Yes'
                                 },
                                 {
                                     id: 'No',
                                     value: 'No'
                                 }
                             ]}
                             attributeName='workAudit'
                             checkInFormDataRadio = {props.workAuditRadio}
                             checkInFormDataRadioSetters = {props.workAuditRadioSetter}
                             {... props}
                />

                <Label labelText="Did you escort representative to the exit?"
                       truncated={false}
                       maxWidth={300}
                       showAsRequired={true}
                       marginTop={"1rem"}
                >

                </Label>
                <SimpleRadio placeholder = "Did you escort the contractor/subcontractor to the exit(front gate)"
                             required = {false}
                             colorScheme = "amber"
                             options = {[
                                 {
                                     id: 'Yes',
                                     value: 'Yes'
                                 },
                                 {
                                     id: 'No',
                                     value: 'No'
                                 }
                             ]}
                             attributeName='escortContractor'
                             checkInFormDataRadio = {props.escortContractorRadio}
                             checkInFormDataRadioSetters = {props.escortContractorRadioSetter}
                             {... props}
                />
            </Box>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"} colorScheme="lightBlue" onPress={() =>
                    validateCheckOutScreen1(props)
                } variant="rounded"> Next </Button>
                <Button marginTop={"1.5rem"} colorScheme="lightBlue" onPress={() =>
                    props.pageChanger(CHECKOUT_PAGE_ACTION_PAGE_INDEX)
                } variant="rounded"> Cancel </Button>
            </NativeBaseProvider>

        </View>
    );
}

export default withAuthenticator(CheckoutScreen);