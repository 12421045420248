import React, {useState, useEffect} from "react";
import {Box, Button, Center, Divider, Flex, HStack, NativeBaseProvider, Text, View, VStack} from "native-base";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import Label from "../../components/Label/label";
import {
    CHECKIN_LIST_PAGE_INDEX,
    CHECKIN_PAGE_INDEX_START_INCLUSIVE,
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    CHECKOUT_PAGE_START_INDEX_INCLUSIVE, HOME_PAGE_INDEX
} from "../../utils/constants";
import checkOutSupplierAndUpdatePage from "../../processCheckout";




function CheckOutReview(props) {

    const [checkoutButtonDisabled, setCheckoutButtonDisabled] = useState(false)
    const [labelsMapCheckOut, setLabelsMapCheckOut] = useState({
        checkoutsuppliernamekey : 'Supplier Representative',
        checkoutsupplierrecordpk : 'Site',
        badgecolor: 'Badge Color',
        workscopedetails: 'Work Scope Details',
        workscopecompleted: 'Work Scope Completed',
        workaudit: 'Work is audited',
        escortcontractor: 'Contractor is escorted',
        collectbadge: 'Badge collected',
        companyname: 'Supplier/Vendor Company Name'
    });

    const handleCheckoutAndUpdateRecord = (props) => {
        setCheckoutButtonDisabled(true)
        return checkOutSupplierAndUpdatePage(props, HOME_PAGE_INDEX)
    }

    // uncomment only for local testing
    // useEffect(()=> {
    //     console.log("Page 4-> state of the form Now is " + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])
    //
    // useEffect(()=> {
    //     console.log("Page 4-> state of  lse radio " + JSON.stringify(props.lseRadioData))
    //         console.log("Page 4-> state of cat radio " + JSON.stringify(props.catRadioData))
    //         console.log("Page 4-> state of orientation radio " + JSON.stringify(props.orientationRadioData))
    //         console.log("Page 4-> state of jha radio " + JSON.stringify(props.jhaRadioData))
    //         console.log("Page 4-> state of po check radio " + JSON.stringify(props.poCheckRadioData))
    // }, [
    //     props.lseRadioData,
    //     props.catRadioData,
    //     props.orientationRadioData,
    //     props.jhaRadioData,
    //     props.poCheckRadioData
    //     ]
    // )

    return (
        <View minW={300} maxW={300} style={{
            flexDirection: "column",
            padding: 1
        }}>
            <Center>
                <Label labelText = "Review Check-Out Details" ></Label>
            </Center>

            <VStack marginTop={'1rem'}>
                {
                    Object.keys(props.checkInFormData).map(key => (
                            <Box>
                                { labelsMapCheckOut[key.toLowerCase()] !== undefined &&
                                    <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                        <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                            <Text style={{ padding: '0.3rem'}}>{ labelsMapCheckOut[key.toLowerCase()] }</Text>
                                        </View>
                                        <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                            <Text>
                                                {props.checkInFormData[key]}
                                            </Text>
                                        </Flex>
                                    </Box>
                                }
                            </Box>
                        )
                    )
                }
                {
                    Object.keys(props.workScopeRadio).map(key => (
                            <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                    <Text style={{ padding: '0.3rem'}}>{labelsMapCheckOut[key.toLowerCase()]}</Text>
                                </View>
                                <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                    <Box>
                                        <Text> {props.workScopeRadio[key]} </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    )
                }
                {
                    Object.keys(props.workAuditRadio).map(key => (
                            <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                    <Text style={{ padding: '0.3rem'}}>{labelsMapCheckOut[key.toLowerCase()]}</Text>
                                </View>
                                <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                    <Box>
                                        <Text> {props.workAuditRadio[key]} </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    )
                }
                {
                    Object.keys(props.escortContractorRadio).map(key => (
                            <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                    <Text style={{ padding: '0.3rem'}}>{labelsMapCheckOut[key.toLowerCase()]}</Text>
                                </View>
                                <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                    <Box>
                                        <Text> {props.escortContractorRadio[key]} </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    )
                }
                {
                    Object.keys(props.collectBadgeRadio).map(key => (
                            <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                    <Text style={{ padding: '0.3rem'}}>{labelsMapCheckOut[key.toLowerCase()]}</Text>
                                </View>
                                <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                    <Box>
                                        <Text> {props.collectBadgeRadio[key]} </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    )
                }
            </VStack>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"}
                        onPress={() => handleCheckoutAndUpdateRecord(props)}
                        colorScheme="lightBlue"
                        variant="rounded"
                        isDisabled={checkoutButtonDisabled}> Complete Check Out </Button>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkOutIndexChanger(CHECKOUT_PAGE_START_INDEX_INCLUSIVE)}
                        variant="rounded"
                        isDisabled={checkoutButtonDisabled}> Edit </Button>
            </NativeBaseProvider>
        </View>
    )
}
export default withAuthenticator(CheckOutReview)
