import {styles} from "./style";
import {Image} from "react-native-web";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {
    Button,
    Center,
    Divider,
    Flex,
    NativeBaseProvider
} from "native-base";
import React from "react";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import logo from '../../assets/gpo_tech_1.png'

function Logo({ pageChanger, signOut, user, setSignIn }) {
    return (
        <NativeBaseProvider theme={RoundedButtonTheme}>
            <Center>
                <Image defaultSource = {logo} style={styles.image} />
            </Center>
            <Center>
                <Flex direction="row" height={50}>
                    <Button size={"lg"}
                            marginTop = {"1rem"}
                            minWidth={140}
                            colorScheme="lightBlue" onPress={() =>
                        pageChanger(0)
                    } variant="rounded"> Home </Button>
                    <Divider marginTop = {"0.5rem"}
                             bg="lightBlue.500"
                             thickness="5"
                             mx="1"
                             orientation="vertical" />
                    <Button size={"lg"}
                            marginTop = {"1rem"}
                            minWidth={140}
                            colorScheme="lightBlue"
                            onPress = { () => {
                                signOut()
                                setSignIn()
                            }
                    } variant="rounded"> Sign Out </Button>
                </Flex>
            </Center>
        </NativeBaseProvider>
 );
}

export default withAuthenticator(Logo);