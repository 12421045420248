import {withAuthenticator} from "@aws-amplify/ui-react";
import React from "react";
import {View} from "react-native";
import {
    NativeBaseProvider,
    Button,
    Box,
} from "native-base";
import {styles} from "../../styles/style";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import {
    CHECKOUT_PAGE_END_INDEX_INCLUSIVE,
    CHECKOUT_PAGE_REVIEW,
    CHECKOUT_PAGE_START_INDEX_INCLUSIVE
} from "../../utils/constants";
import Label from "../../components/Label/label";
import SimpleRadio from "../../components/SimpleRadio/simple-radio";
import SimpleDropdown from "../../components/SimpleDropdown/simple-dropdown";
import TextAreaComponent from "../../components/TextArea/text-area";

function CheckoutFinalScreen(props) {
    const validateCheckOutScreen2 = (props) => {
        if (props.collectBadgeRadio['collectBadge'] === undefined || props.collectBadgeRadio['collectBadge'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, collectBadge : 'Please select an option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, collectBadge : ''} })
        }

        if (props.checkInFormData['badgeColor'] === undefined || props.checkInFormData['badgeColor'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, badgeColor : 'Please select badge Color'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, badgeColor : ''} })
        }
        // if (props.checkInFormData['workScopeDetails'] === undefined || props.checkInFormData['workScopeDetails'] === ''){
        //     props.checkInValidationSetter((prev) => { return  {...prev, workScopeDetails : 'Please provide few lines of work details'} })
        //     return;
        // } else {
        //     props.checkInValidationSetter((prev) => { return  {...prev, workScopeDetails : ''} })
        // }

        // if everything is great then move on
        props.checkOutIndexChanger(CHECKOUT_PAGE_REVIEW)
    }
    return (
        <View style={styles.header}>
            <Box>
                <Label labelText="Did the supplier return visitor or temporary badge to security?"
                       marginTop={"1rem"}
                       maxWidth={200}
                       showAsRequired={true}
                >
                </Label>
                <SimpleRadio placeholder = "Did the supplier return the temporary/visitor's badge to security?"
                             colorScheme = "amber"
                             options = {[
                                 {
                                     id: 'Yes',
                                     value: 'Yes'
                                 },
                                 {
                                     id: 'No',
                                     value: 'No'
                                 }
                             ]}
                             attributeName='collectBadge'
                             checkInFormDataRadio = {props.collectBadgeRadio}
                             checkInFormDataRadioSetters = {props.collectBadgeRadioSetter}
                             {... props}
                />
                <SimpleDropdown label = "Please confirm color of the badge"
                                placeholder = "Confirm Color of the badge"
                                required = {true}
                                minWidth={300}
                                options = {[
                                    {
                                        id: 'Red and White',
                                        value: 'red&white'
                                    },
                                    {
                                        id: 'Yellow',
                                        value: 'yellow'
                                    },
                                    {
                                        id: 'Red',
                                        value: 'red'
                                    },
                                    {
                                        id: 'White',
                                        value: 'white'
                                    },
                                    {
                                        id: 'Green',
                                        value: 'green'
                                    }
                                ]}
                                defaultValue='yellow'
                                attributeName = 'badgeColor'
                                { ...props }
                />
                {/*<Label labelText="Scope of Work completed"*/}
                {/*       truncated={false}*/}
                {/*       marginTop={"1rem"}*/}
                {/*       maxWidth={300}*/}
                {/*       showAsRequired={true}*/}
                {/*/>*/}
                {/*<TextAreaComponent placeholder = "Please specify in maximum 200 characters!"*/}
                {/*                   required = {true}*/}
                {/*                   minWidth={300}*/}
                {/*                   maxWidth={300}*/}
                {/*                   maxLength={200}*/}
                {/*                   attributeName = 'workScopeDetails'*/}
                {/*                   { ...props }*/}
                {/*/>*/}
            </Box>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"} colorScheme="lightBlue" onPress={() => {
                    validateCheckOutScreen2(props)
                }
                } variant="rounded"> Review </Button>
                <Button marginTop={"1.5rem"} colorScheme="lightBlue" onPress={() =>
                    props.checkOutIndexChanger(CHECKOUT_PAGE_START_INDEX_INCLUSIVE)
                } variant="rounded"> Previous </Button>
            </NativeBaseProvider>
        </View>
    );
}

export default withAuthenticator(CheckoutFinalScreen);