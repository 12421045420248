import { REGION } from "./constants/region-country-constants";

export let COGNITO_IDENTITY_POOL_ID;
export let COGNITO_REGION;
export let COGNITO_USER_POOL_ID;
export let COGNITO_USER_POOL_WEB_CLIENT_ID;
export let stage;
export const SUPPLIER_CHECK_IN_AWS_REGION = "us-west-2";
export const origin = window.location.origin;

export const STAGE = {
    PROD: 'prod',
    GAMMA: 'gamma',
    BETA: 'beta',
    DEV: 'dev',
};

switch (true) {
    case origin.includes('beta.supplier-checkin.iscore.a2z.com') :
        stage = STAGE.BETA;
        break;
    case origin.includes('gamma.supplier-checkin.iscore.a2z.com') :
        stage = STAGE.GAMMA;
        break;
    case origin.includes('supplier-checkin.iscore.a2z.com') :
        stage = STAGE.PROD;
        break;
    default :
        stage = STAGE.DEV;
}

if(stage === STAGE.PROD) {
    COGNITO_IDENTITY_POOL_ID = "us-west-2:a8e4a816-33bd-4a2c-aa59-d48ac0e2e2a1"
    COGNITO_REGION = "us-west-2"
    COGNITO_USER_POOL_ID = "us-west-2_K33YuyGAs"
    COGNITO_USER_POOL_WEB_CLIENT_ID = "4bnjq1m6kcfc17mkc50f9qms18"
} else if(stage === STAGE.GAMMA) {
    COGNITO_IDENTITY_POOL_ID = "us-west-2:84fabccf-cfe1-4b3e-a0a0-c6a38fad52db"
    COGNITO_REGION = "us-west-2"
    COGNITO_USER_POOL_ID = "us-west-2_o0TA2JVK4"
    COGNITO_USER_POOL_WEB_CLIENT_ID = "qkds8f0pcivacnhoenp3qhako"
} else {
    COGNITO_IDENTITY_POOL_ID = "us-west-2:ecf78ba7-36cd-4e5e-b3e4-2f66bd4712db"
    COGNITO_REGION = "us-west-2"
    COGNITO_USER_POOL_ID = "us-west-2_nc9j4rzrx"
    COGNITO_USER_POOL_WEB_CLIENT_ID = "3tsdqq9nb82ka4nnnhi29r7q78"
}


const NA_PROD_SUPPLIER_CHECK_IN_API_ENDPOINT = "https://yuktqyrs18.execute-api.us-west-2.amazonaws.com/prod";
const EU_PROD_SUPPLIER_CHECK_IN_API_ENDPOINT = "https://bevtwfj28h.execute-api.eu-west-1.amazonaws.com/prod";
const GAMMA_SUPPLIER_CHECK_IN_API_ENDPOINT = "https://bzw3156ijl.execute-api.us-west-2.amazonaws.com/gamma";
const BETA_SUPPLIER_CHECK_IN_API_ENDPOINT = "https://uf45a52k7h.execute-api.us-west-2.amazonaws.com/beta";

export function getSupportedRegions() {
    if(stage == STAGE.PROD) {
        return [REGION.NA, REGION.EU];
    } else {
        return [REGION.NA]
    }
}

export function getSupplierCheckInApiEndpoint(region) {
    if(stage === STAGE.PROD) {
        if(region === REGION.NA) {
            return NA_PROD_SUPPLIER_CHECK_IN_API_ENDPOINT;
        } else {
            return EU_PROD_SUPPLIER_CHECK_IN_API_ENDPOINT;
        }
    } else if(stage === STAGE.GAMMA) {
        return GAMMA_SUPPLIER_CHECK_IN_API_ENDPOINT;
    } else {
        return BETA_SUPPLIER_CHECK_IN_API_ENDPOINT;
    }
}
