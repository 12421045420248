import React from "react";
import {
    Input,
    IconButton,
    Text,
    VStack,
    HStack,
    Center,
    NativeBaseProvider,
    AddIcon, CloseIcon, FormControl, ScrollView, Flex, WarningOutlineIcon, useToast, View, Box
} from "native-base";

import {useState} from "react";

const DynamicTextComponent = (props) => {
    const [attributeName, setAttributeName] = useState(props.attributeName)
    const [list, setList] = React.useState(props.checkInFormData[attributeName]);
    const [inputValue, setInputValue] = React.useState("");

    const toast = useToast();
    const addItem = name => {
        // needs to change later
        if (props.checkInFormData[attributeName].length == 10) {
            props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : 'Exceeded 10 supplier representative(s) limit'} })
            return;
        }
        if (/^[A-Za-z\s]*$/.test(name)) {
            if (name === '' || name === undefined) {
                props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : 'Name cannot be empty!'} })
                return;
            } else {
                props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
            }
            if (name.length > 25) {
                props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : 'Please limit name to maximum 25 characters.'} })
                return;
            } else {
                props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
            }
            setList(prevList => {
                return [...prevList, {
                    name: name
                }];
            });
            props.checkInFormDataSetter({
                ...props.checkInFormData,
                [attributeName] : [...props.checkInFormData[attributeName], { name : name }]
            });
            // clear the errors on valid user selection
            props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : 'Only letters are allowed in the name.'} })
        }
    };

    const handleStatusChange = (itemName, index) => {
        props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
        setList(prevList => {
            const temp = prevList.filter((_, itemI) => itemI !== index);
            props.checkInFormDataSetter({
                ...props.checkInFormData,
                [attributeName] : temp
            });
            return temp;
        });
    };

    return (
        <FormControl isRequired isInvalid={props.checkInValidationError !== undefined && attributeName in props.checkInValidationError && props.checkInValidationError[attributeName] !== ''} >
        <FormControl.Label >Supplier representative name</FormControl.Label>
        <VStack>
            <HStack space={0.1}>
                <Input minWidth={280}
                       placeholder = {props.placeholder}
                       onChangeText={v => {
                           props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
                           setInputValue(v)
                       } }
                       value={inputValue}
                       variant = "underlined"
                />
                <IconButton icon= {<AddIcon color="emerald.500" size={'sm'} />} onPress={() => {
                    addItem(inputValue);
                    setInputValue("");
                }} />
            </HStack>
        </VStack>
            {/*<ScrollView>*/}
            <VStack direction="column">
                    {
                        list.map((item, itemI) =>
                                <HStack space={2} key={item.name + itemI.toString()} >
                                     <View borderRadius="md" bgColor={`lightBlue.${itemI === 0 ? (itemI + 1) *100 : itemI * 100}`}
                                           style={{
                                               flexDirection: "row"
                                           }}>
                                         <Text isTruncated={true} onPress={() => {
                                                handleStatusChange(item.name, itemI)
                                            }}>
                                                {item.name}
                                            </Text>
                                            <IconButton icon= {<CloseIcon color="white" size={'sm'} />}
                                                        onPress={() => {
                                                            handleStatusChange(item.name, itemI)
                                                        }}/>
                                     </View>
                                </HStack>
                        )
                    }
            </VStack>
            {/*</ScrollView>*/}
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                { props.checkInValidationError !== undefined && props.checkInValidationError[attributeName] }
            </FormControl.ErrorMessage>
        </FormControl>
    )
};

export default (props) => {
    return (
        <NativeBaseProvider>
            {/*<Center flex={1}>*/}
            <View style={{marginTop: 70}}>
                <DynamicTextComponent {...props} />
            </View>
            {/*</Center>*/}
        </NativeBaseProvider>
    );
};