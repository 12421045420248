import React from "react"

export const HOME_PAGE_INDEX = 0
export const CHECKIN_PAGE_INDEX_START_INCLUSIVE = 1
export const CHECKIN_NEXT_STEPS = 6
export const CHECKIN_PAGE_INDEX_END_INCLUSIVE = 7
export const CHECKOUT_PAGE_ACTION_PAGE_INDEX = 8
export const CHECKIN_LIST_PAGE_INDEX = 9
export const CHECKOUT_PAGE_START_INDEX_INCLUSIVE = 10
export const CHECKOUT_PAGE_END_INDEX_INCLUSIVE = 11
export const CHECKOUT_PAGE_REVIEW = 12
export const SITE_CHECKINS_FORM = 13
export const SITE_CHECKINS_LIST = 14
export const YELLOW_BADGE_SETUP = 15
export const YELLOW_BADGE_PROFILE = 16
export const YELLOW_BADGE_CHECKINFLOW = 17
export const YELLOW_BADGE_CHECKOUT = 18
export const YELLOW_BADGE_CHECKIN_LIST = 19
export const MULTI_CHECK_IN_PAGE = 20
export const RE_CHECK_IN_PAGE = 21
export const REVIEW_RE_CHECK_IN_PAGE = 22

export const NON_HRO_OPERATION = 'Non-High Risk Operation (No Avetta requirement)'
export const OTHER_HRO_OPERATION = 'Other - HRO'
// Currently when no Avetta compliance is found we consider supplier compliance status as NA (Not avaialble)
export const AVETTA_COMPLIANCE_DEFAULT_STATUS_CODE = 'Not Found'
export const MAX_DAYS_ON_SITE = 15
export const DEFAULT_NUM_OF_DAYS_ON_SITE = 1


export const GET_AVETTA_COMPLIANCE_PATH = '/get-avetta-compliance-info'
export const CHECK_IN_PATH = '/check-in'
export const CHECK_OUT_PATH = '/check-out'




export function isLseEvent(propsWithAllFormData) {
  return propsWithAllFormData.lseRadioData.lseEvent === "Sev-1" || propsWithAllFormData.lseRadioData.lseEvent === "Sev-2" || propsWithAllFormData.lseRadioData.lseEvent === "Peak";
}

export function isHro(props) {
    return props.checkInFormData["jobRiskAssessment"] !== undefined && props.checkInFormData["jobRiskAssessment"] !== null && props.checkInFormData["jobRiskAssessment"] !== NON_HRO_OPERATION;
  }
  
export function isNotAvettaCompliantOrNotAvailable(complianceStatus) {
    return complianceStatus === undefined || complianceStatus === "Red";
  }
  
  
export function isAvettaCompliant(complianceStatus) {
    return complianceStatus === "Green";
  }
  
export function isAvettaConditionalyCompliant(complianceStatus) {
    return complianceStatus === "Amber";
  }

export function getAvettaColorCodeFromCheckInEntryRecord(checkInEntry) {
    return (
        checkInEntry.avetta_compliance_status_code === 'NA' ||
        checkInEntry.avetta_compliance_status_code === 'Not Found' ||
        checkInEntry.avetta_compliance_status_code === undefined ||
        checkInEntry.avetta_compliance_status_code === ''
    ) ? 'gray' : checkInEntry.avetta_compliance_status_code;
}