import {withAuthenticator} from "@aws-amplify/ui-react";
import React, {useEffect, useRef, useState} from "react";
import {View} from "react-native-web";
import {
    NativeBaseProvider
} from "native-base";
import CheckoutScreen from "./checkout-screen";
import {
    CHECKOUT_PAGE_END_INDEX_INCLUSIVE, CHECKOUT_PAGE_REVIEW,
    CHECKOUT_PAGE_START_INDEX_INCLUSIVE
} from "../../utils/constants";
import CheckOutScreenFinal from "./check-out-screen-final";
import CheckOutReview from "./review-checkout";

/** TODO  https://issues.amazon.com/issues/ISC-ST-1934 */

function CheckOutWorkflow(props) {

    const [checkOutScreenIndex, setCheckOutScreenIndex] = useState(CHECKOUT_PAGE_START_INDEX_INCLUSIVE);

    return (
        <NativeBaseProvider>
            <View>
                { checkOutScreenIndex === CHECKOUT_PAGE_START_INDEX_INCLUSIVE &&
                    <CheckoutScreen
                        page={CHECKOUT_PAGE_START_INDEX_INCLUSIVE}
                        pageChanger={props.pageChanger}
                        checkOutIndexChanger={setCheckOutScreenIndex}
                        { ...props }
                    />
                }

                { checkOutScreenIndex === CHECKOUT_PAGE_END_INDEX_INCLUSIVE &&
                    <CheckOutScreenFinal page={CHECKOUT_PAGE_END_INDEX_INCLUSIVE}
                                         pageChanger={props.pageChanger}
                                         checkOutIndexChanger={setCheckOutScreenIndex}
                                         { ...props }
                    />
                }

                { checkOutScreenIndex === CHECKOUT_PAGE_REVIEW &&
                    <CheckOutReview page={CHECKOUT_PAGE_REVIEW}
                                         pageChanger={props.pageChanger}
                                         checkOutIndexChanger={setCheckOutScreenIndex}
                                         { ...props }
                    />
                }
            </View>
        </NativeBaseProvider>
    )

}


export default withAuthenticator(CheckOutWorkflow)