import {useState} from "react";
import {Modal, View} from "react-native";
import {withAuthenticator} from "@aws-amplify/ui-react";
import RatingUp from "../Ratings/up";
import RatingDown from "../Ratings/down";
import {Box, Button, Center, Divider, Flex, FormControl, Input, NativeBaseProvider} from "native-base";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import React from "react";
import Logo from "../Header/logo";

function RateAppModal(props) {
    const [isVisible, setIsVisible] = useState(false);
    let handleClose = function () {
        setIsVisible(false)
        props.pageChanger(props.nextPage)
    }
    return (
        <View>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"2rem"}
                        onPress={
                    () => {
                        setIsVisible(false)
                        props.pageChanger(props.nextPage)
                    }
                }
                        colorScheme="lightBlue"
                        variant="rounded"> Acknowledge </Button>
            </NativeBaseProvider>

            <Modal
                animationType={props.animationType}
                onRequestClose={handleClose}
                visible={isVisible}
            >
                <View>
                    <Center>
                        <Box marginTop={"10rem"}>
                        <FormControl isRequired >
                            <Center>
                                <FormControl.Label marginTop={"1rem"} isRequired={false}>How was your Check In Experience?</FormControl.Label>
                                <FormControl.Label marginTop={"1rem"} isRequired={false}>Your feedback will help us improve!</FormControl.Label>
                                <FormControl.Label marginTop={"1rem"} isRequired={false}>As a token of gratitude from us, earn a phone tool!</FormControl.Label>
                            </Center>
                        </FormControl>
                            <Center>
                                <Flex direction="row" h="58" p="4">
                                    <RatingUp/>
                                    <Divider  marginTop ={'3rem'} bg="primary.900" thickness="1" mx="2" orientation="vertical" />
                                    <RatingDown/>
                                </Flex>
                            </Center>

                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        <Center>
                            <Button maxWidth={200}
                                    marginTop={"5rem"}
                                    colorScheme="lightBlue"
                                    onPress={handleClose}
                                    variant="rounded"> Close
                            </Button>
                        </Center>
                    </NativeBaseProvider>
                    </Box>
                        </Center>
                </View>
            </Modal>
        </View>
    );
}

export default withAuthenticator(RateAppModal);