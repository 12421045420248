import { Auth } from 'aws-amplify'
import { getSupplierCheckInApiEndpoint } from './config';
import { COUNTRY_TO_REGION_MAP } from './constants/region-country-constants';
import { CHECK_OUT_PATH } from './utils/constants';

function checkOutSupplierAndUpdatePage(propsWithAllCheckOutFormData, nextPage) {
    const checkOutPayload = getProcessCheckoutPayload(propsWithAllCheckOutFormData)
    try {
        let token = "";
            Auth.currentAuthenticatedUser().then((data) => {
                let myHeaders = new Headers();
                token = data.signInUserSession.idToken.jwtToken;
                myHeaders.append("Authorization", "Bearer " + token)
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(checkOutPayload),
                    redirect: 'follow',
                };
                const url = getSupplierCheckInApiEndpoint(COUNTRY_TO_REGION_MAP[propsWithAllCheckOutFormData.checkInFormData.country])
                 + CHECK_OUT_PATH;
                fetch(url, requestOptions)
                .then((response) => {
                    return response.json().then((data) => ({
                        response: response,
                        status: response.status,
                        data,
                    }));
                })
                .then((result) => {
                    if (result.response.ok) {
                        propsWithAllCheckOutFormData.clearCheckOutForm();
                        propsWithAllCheckOutFormData.pageChanger(nextPage)
                    } else {
                        alert('Error occurred when checking out the supplier, please try again after sometime.')
                    }
                })
                .catch(error => {
                    alert('Error occurred when checking out the supplier, please try again after sometime.')
                });
            });   
    } catch (err) {
    }
}

function getProcessCheckoutPayload(propsWithAllCheckOutFormData) {
    const {
        checkOutSupplierRecordPk,
        checkOutSupplierRecordSk,
        checkOutSupplierNameKey,
        badgeColor,
        workScopeDetails,
        index,
        isMetadataV2Available
    } = propsWithAllCheckOutFormData.checkInFormData
    const badgeCollected = propsWithAllCheckOutFormData.collectBadgeRadio.collectBadge === "Yes"
    const workScopeCompleted = propsWithAllCheckOutFormData.workScopeRadio.workScopeCompleted === "Yes"
    const workAuditPerformed = propsWithAllCheckOutFormData.workAuditRadio.workAudit === "Yes"
    const supplierEscorted = propsWithAllCheckOutFormData.escortContractorRadio.escortContractor === "Yes"

    const payloadValuesJson = {
        "query_pk": checkOutSupplierRecordPk,
        "query_sk": checkOutSupplierRecordSk,
        "supplier_representative_name": checkOutSupplierNameKey,
        "badge_color": badgeColor,
        "work_scope_details": workScopeDetails,
        "badge_collected": badgeCollected,
        "work_scope_completed": workScopeCompleted,
        "work_audit_performed": workAuditPerformed,
        "supplier_escorted_to_gate": supplierEscorted,
        "index": index,
        "isMetadataV2Available": isMetadataV2Available
    };
    return payloadValuesJson;
}    

export default checkOutSupplierAndUpdatePage
