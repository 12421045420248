import React, { useState, useEffect } from "react";
import '@aws-amplify/ui-react/styles.css';
import { View} from "react-native";
import {styles} from "./styles/style"
import {withAuthenticator} from '@aws-amplify/ui-react';
import Logo from "./components/Header/logo";
import {NativeBaseProvider, Box, Button, Divider, ScrollView, Center, Container, HStack} from "native-base";
import {WelcomePage} from "./pages/welcome";
import Checkinlist from "./pages/checkinlist";
import CheckOutWorkflow from "./pages/CheckoutFlow/check-out-flow";
import {
    CHECKIN_LIST_PAGE_INDEX,
    CHECKIN_PAGE_INDEX_END_INCLUSIVE,
    CHECKIN_PAGE_INDEX_START_INCLUSIVE,
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    CHECKOUT_PAGE_REVIEW,
    CHECKOUT_PAGE_START_INDEX_INCLUSIVE,
    HOME_PAGE_INDEX, SITE_CHECKINS_FORM, YELLOW_BADGE_PROFILE, YELLOW_BADGE_SETUP
} from "./utils/constants";
import {Auth} from "aws-amplify";
import SiteCheckInList from "./pages/SiteCheckIns";
import {STOP_WORKFLOW} from "./components/TextInputComponent/component";
import YellowBadgeSetup from "./pages/yellowBadgeSetup";
import {WelcomeSelfServiceFlow} from "./pages/welcome-self-service";
import ProfileSetup from "./pages/self-service/my-profile";
import CheckInWorkflow from "./pages/checkinflow";

function App({ signOut, user, setSignIn }) {
    const ALIAS_ATTRIBUTE = 'alias'
    const [page, setPage] = useState(0);
    const [checkInData, setCheckInData] = useState([]);
    const [pendingCheckOutList, setPendingCheckOutList] = useState([]);
    const [myCheckInList, setMyCheckInList] = useState([]);

    const [siteCheckInList, setSiteCheckInList] = useState([]);

    const [checkInValidationError, setCheckInValidationError] = useState({});
    const [complianceNumbers, setComplianceNumbers] = useState({
        gray: 0,
        green: 0,
        red: 0,
        amber:0,
        total:0
    })

    const initialState = {
        supplierNamesList : []
    }
    const [checkInFormData, setCheckInFormData] = useState(initialState);

    const [loggedInUser, setLoggedInUser] = useState()
    const [badgeType, setBadgeType] = useState('')

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((data) => {
            if (loggedInUser === undefined) {
                let emailAddress = data.attributes.email
                let index = emailAddress.indexOf('@')
                let loggedUserAlias = emailAddress.slice(0,index)
                let badgeType = data.attributes['custom:badgeType']
                if (badgeType === 'yellow') {
                    setBadgeType('yellow')
                }
                setLoggedInUser(loggedUserAlias)
                setCheckInFormData({
                    ...checkInFormData,
                    [ALIAS_ATTRIBUTE] : loggedUserAlias
                });
            }
        });
    }, [loggedInUser])

    /**
     * This method clears up the
     * state for a fresh check in
     * form.
     */
    const clearCheckInForm = () => {
        setCheckInFormData({
            supplierNamesList: [],
            [ALIAS_ATTRIBUTE]: loggedInUser
        });
        setCheckInFormData((previous) => {
            return {
                ...previous,
                [STOP_WORKFLOW] : false
            }
        });
        setCheckInFormDataPOCheckRadio({
            poCheck:''
        });
        setCheckInFormDataLSERadio({
            lseEvent:''
        });
        setCheckInFormDataOrientationRadio({
            orientation:''
        });
        setCheckInFormDataJHARadio({
            jha:''
        });
        setCheckInFormDataCatRadio({
            catApproved:''
        });
    }

    /**
     * This method clears up the validation error
     * messages state for a fresh check in form.
     */
    const clearCheckInFormValidationErrorsMessages = () => {
        setCheckInValidationError({})
    }

    // Radio button values are messing with other formData and hence keeping it separate for now
    //TODO Added a sim to address this: https://issues.amazon.com/issues/ISC-ST-1964
    const [checkInFormDataRadio, setCheckInFormDataRadioSetters] = useState({
        lseEvent:''
    });

    const [checkInFormDataPOCheckRadio, setCheckInFormDataPOCheckRadio] = useState({
        poCheck:''
    });

    const [checkInFormDataLSERadio, setCheckInFormDataLSERadio] = useState({
        lseEvent:''
    });

    const [checkInFormDataOrientationRadio, setCheckInFormDataOrientationRadio] = useState({
        orientation:''
    });

    const [checkInFormDataJHARadio, setCheckInFormDataJHARadio] = useState({
        jha:''
    });

    const [checkInFormDataCatRadio, setCheckInFormDataCatRadio] = useState({
        catApproved:''
    });

    /**
     * Check out form states
     */
    const [checkOutFormData, setCheckOutFormData] = useState({});

    const clearCheckOutForm = () => {
        setCheckOutFormData({})
        setCheckOutFormDataWorkScopeRadio({
            workScopeCompleted : ''
        });
        setCheckOutFormDataWorkAuditRadio({
            workAudit: ''
        })
        setCheckOutFormDataEscortContractorRadio({
            escortContractor: ''
        })
        setCheckOutFormDataCollectBadgeRadio({
            collectBadge: ''
        })
    }

    const [checkOutFormDataWorkScopeRadio, setCheckOutFormDataWorkScopeRadio] = useState({
        workScopeCompleted : ''
    });

    const [checkOutFormDataWorkAuditRadio, setCheckOutFormDataWorkAuditRadio] = useState({
        workAudit: ''
    });

    const [checkOutFormDataEscortContractorRadio, setCheckOutFormDataEscortContractorRadio] = useState({
        escortContractor: ''
    });

    const [checkOutFormDataCollectBadgeRadio, setCheckOutFormDataCollectBadgeRadio] = useState({
        collectBadge: ''
    });

    const clearForm = () => {
        // clear everything
        setCheckInFormData(initialState);
        setCheckInFormDataLSERadio(initialState);
    }

    return (
        <NativeBaseProvider>
            <Box>
                <Center>
                    <View style={styles.app}>
                        <Center>
                            <Logo pageChanger={setPage} signOut ={signOut} user= {user} setSignIn={setSignIn}/>
                        </Center>

                        <ScrollView width={330}
                                    showsVerticalScrollIndicator={false}
                                    showsHorizontalScrollIndicator={false}>
                            {
                                badgeType !== undefined && badgeType !== 'yellow' &&
                            <Center>
                                { page === HOME_PAGE_INDEX &&
                                    <WelcomePage pageChanger={setPage}
                                                 signOut ={signOut}
                                                 user= {user}
                                                 checkInFormData={checkInFormData}
                                                 lseRadioData={checkInFormDataLSERadio}
                                                 catRadioData={checkInFormDataCatRadio}
                                                 orientationRadioData={checkInFormDataOrientationRadio}
                                                 poCheckRadioData={checkInFormDataPOCheckRadio}
                                                 jhaRadioData={checkInFormDataJHARadio}
                                                 checkInFormDataSetter={setCheckInFormData}
                                                 lseRadioSetter={setCheckInFormDataLSERadio}
                                                 catRadioSetter= {setCheckInFormDataCatRadio}
                                                 orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                                 poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                                 jhaRadioSetter={setCheckInFormDataJHARadio}
                                                 page = { page }
                                                 checkInDataSetter = {setCheckInData}
                                                 pendingCheckoutListSetter = {setPendingCheckOutList}
                                                 myCheckInListDataSetter = {setMyCheckInList}
                                                 checkInData = {checkInData}
                                                 pendingCheckOutList = {pendingCheckOutList}
                                                 myCheckInList = {myCheckInList}
                                                 siteCheckInList = {siteCheckInList}
                                                 siteCheckInListSetter={setSiteCheckInList}
                                                 clearCheckInForm = {clearCheckInForm}
                                                 clearCheckInFormValidationErrorsMessages= {clearCheckInFormValidationErrorsMessages}
                                    />
                                }
                                { page >= CHECKIN_PAGE_INDEX_START_INCLUSIVE &&
                                    page <= CHECKIN_PAGE_INDEX_END_INCLUSIVE &&
                                    <CheckInWorkflow
                                        pageChanger={setPage}
                                        signOut ={signOut}
                                        user= {user}
                                        checkInFormData={checkInFormData}
                                        lseRadioData={checkInFormDataLSERadio}
                                        catRadioData={checkInFormDataCatRadio}
                                        orientationRadioData={checkInFormDataOrientationRadio}
                                        poCheckRadioData={checkInFormDataPOCheckRadio}
                                        jhaRadioData={checkInFormDataJHARadio}
                                        checkInFormDataSetter={setCheckInFormData}
                                        lseRadioSetter={setCheckInFormDataLSERadio}
                                        catRadioSetter= {setCheckInFormDataCatRadio}
                                        orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                        poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                        jhaRadioSetter={setCheckInFormDataJHARadio}
                                        page = { page }
                                        loggedInUser = {loggedInUser}
                                        checkInValidationError = {checkInValidationError}
                                        checkInValidationSetter = {setCheckInValidationError}
                                        clearCheckInForm = {clearCheckInForm}
                                        checkInData = {checkInData}
                                        clearCheckInFormValidationErrorsMessages = {clearCheckInFormValidationErrorsMessages}
                                    />
                                }
                                { page === CHECKOUT_PAGE_ACTION_PAGE_INDEX &&
                                  <Checkinlist pageChanger={setPage}
                                               signOut ={signOut}
                                               user= {user}
                                               checkout={true}
                                               data={pendingCheckOutList}
                                               checkOutFormDataSetter={setCheckOutFormData}/>
                                }
                                { page === CHECKIN_LIST_PAGE_INDEX &&
                                  <Checkinlist pageChanger={setPage}
                                               signOut ={signOut}
                                               user= {user}
                                               checkout={false}
                                               data={myCheckInList}
                                               checkOutFormDataSetter={setCheckOutFormData}/>
                                }
                                { page >= CHECKOUT_PAGE_START_INDEX_INCLUSIVE &&
                                  page <= CHECKOUT_PAGE_REVIEW &&
                                  <CheckOutWorkflow pageChanger={setPage}
                                                    signOut ={signOut}
                                                    user= {user}
                                                    checkout={false}
                                                    checkInFormData={checkOutFormData}
                                                    workScopeRadio = {checkOutFormDataWorkScopeRadio}
                                                    workAuditRadio = {checkOutFormDataWorkAuditRadio}
                                                    escortContractorRadio={checkOutFormDataEscortContractorRadio}
                                                    collectBadgeRadio={checkOutFormDataCollectBadgeRadio}
                                                    checkInFormDataSetter={setCheckOutFormData}
                                                    workScopeRadioSetter={setCheckOutFormDataWorkScopeRadio}
                                                    workAuditRadioSetter={setCheckOutFormDataWorkAuditRadio}
                                                    escortContractorRadioSetter={setCheckOutFormDataEscortContractorRadio}
                                                    collectBadgeRadioSetter={setCheckOutFormDataCollectBadgeRadio}
                                                    page = { page }
                                                    checkInValidationError = {checkInValidationError}
                                                    checkInValidationSetter = {setCheckInValidationError}
                                                    clearCheckOutForm = {clearCheckOutForm}
                                  />
                                }
                                {
                                    page === SITE_CHECKINS_FORM && <SiteCheckInList pageChanger={setPage}
                                                                                    signOut ={signOut}
                                                                                    user= {user}
                                                                                    checkInFormData={checkInFormData}
                                                                                    lseRadioData={checkInFormDataLSERadio}
                                                                                    catRadioData={checkInFormDataCatRadio}
                                                                                    orientationRadioData={checkInFormDataOrientationRadio}
                                                                                    poCheckRadioData={checkInFormDataPOCheckRadio}
                                                                                    jhaRadioData={checkInFormDataJHARadio}
                                                                                    checkInFormDataSetter={setCheckInFormData}
                                                                                    lseRadioSetter={setCheckInFormDataLSERadio}
                                                                                    catRadioSetter= {setCheckInFormDataCatRadio}
                                                                                    orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                                                                    poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                                                                    jhaRadioSetter={setCheckInFormDataJHARadio}
                                                                                    checkInDataSetter = {setCheckInData}
                                                                                    pendingCheckoutListSetter = {setPendingCheckOutList}
                                                                                    myCheckInListDataSetter = {setMyCheckInList}
                                                                                    checkInData = {checkInData}
                                                                                    pendingCheckOutList = {pendingCheckOutList}
                                                                                    myCheckInList = {myCheckInList}
                                                                                    siteCheckInList = {siteCheckInList}
                                                                                    siteCheckInListSetter={setSiteCheckInList}
                                                                                    page = { page }
                                                                                    loggedInUser = {loggedInUser}
                                                                                    checkInValidationError = {checkInValidationError}
                                                                                    checkInValidationSetter = {setCheckInValidationError}
                                                                                    clearCheckInForm = {clearCheckInForm}
                                                                                    checkOutFormDataSetter={setCheckOutFormData}
                                                                                    complianceNumbers = {complianceNumbers}
                                                                                    complianceNumbersSetter = {setComplianceNumbers}
                                    />
                                }
                                {
                                    page === YELLOW_BADGE_SETUP && <YellowBadgeSetup pageChanger={setPage}
                                                                                    signOut ={signOut}
                                                                                    user= {user}
                                                                                    checkInFormData={checkInFormData}
                                                                                    lseRadioData={checkInFormDataLSERadio}
                                                                                    catRadioData={checkInFormDataCatRadio}
                                                                                    orientationRadioData={checkInFormDataOrientationRadio}
                                                                                    poCheckRadioData={checkInFormDataPOCheckRadio}
                                                                                    jhaRadioData={checkInFormDataJHARadio}
                                                                                    checkInFormDataSetter={setCheckInFormData}
                                                                                    lseRadioSetter={setCheckInFormDataLSERadio}
                                                                                    catRadioSetter= {setCheckInFormDataCatRadio}
                                                                                    orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                                                                    poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                                                                    jhaRadioSetter={setCheckInFormDataJHARadio}
                                                                                    checkInDataSetter = {setCheckInData}
                                                                                    pendingCheckoutListSetter = {setPendingCheckOutList}
                                                                                    myCheckInListDataSetter = {setMyCheckInList}
                                                                                    checkInData = {checkInData}
                                                                                    pendingCheckOutList = {pendingCheckOutList}
                                                                                    myCheckInList = {myCheckInList}
                                                                                    siteCheckInList = {siteCheckInList}
                                                                                    siteCheckInListSetter={setSiteCheckInList}
                                                                                    page = { page }
                                                                                    loggedInUser = {loggedInUser}
                                                                                    checkInValidationError = {checkInValidationError}
                                                                                    checkInValidationSetter = {setCheckInValidationError}
                                                                                    clearCheckInForm = {clearCheckInForm}
                                                                                    checkOutFormDataSetter={setCheckOutFormData}
                                                                                    complianceNumbers = {complianceNumbers}
                                                                                    complianceNumbersSetter = {setComplianceNumbers}
                                    />
                                }
                            </Center>
                            }
                            {
                                badgeType !== undefined && badgeType === 'yellow' &&
                                <Center>
                                    {
                                        page === HOME_PAGE_INDEX &&
                                        <WelcomeSelfServiceFlow
                                            pageChanger={setPage}
                                            signOut={signOut}
                                            user={user}
                                            checkInFormData={checkInFormData}
                                            lseRadioData={checkInFormDataLSERadio}
                                            catRadioData={checkInFormDataCatRadio}
                                            orientationRadioData={checkInFormDataOrientationRadio}
                                            poCheckRadioData={checkInFormDataPOCheckRadio}
                                            jhaRadioData={checkInFormDataJHARadio}
                                            checkInFormDataSetter={setCheckInFormData}
                                            lseRadioSetter={setCheckInFormDataLSERadio}
                                            catRadioSetter={setCheckInFormDataCatRadio}
                                            orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                            poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                            jhaRadioSetter={setCheckInFormDataJHARadio}
                                            page={page}
                                            checkInDataSetter={setCheckInData}
                                            pendingCheckoutListSetter={setPendingCheckOutList}
                                            myCheckInListDataSetter={setMyCheckInList}
                                            checkInData={checkInData}
                                            pendingCheckOutList={pendingCheckOutList}
                                            myCheckInList={myCheckInList}
                                            siteCheckInList={siteCheckInList}
                                            siteCheckInListSetter={setSiteCheckInList}
                                            checkInValidationError = {checkInValidationError}
                                            checkInValidationSetter = {setCheckInValidationError}
                                            clearCheckInForm = {clearCheckInForm}
                                        >
                                    </WelcomeSelfServiceFlow>
                                    }
                                    {
                                        page === YELLOW_BADGE_PROFILE &&
                                        <ProfileSetup
                                            pageChanger={setPage}
                                            signOut={signOut}
                                            user={user}
                                            checkInFormData={checkInFormData}
                                            lseRadioData={checkInFormDataLSERadio}
                                            catRadioData={checkInFormDataCatRadio}
                                            orientationRadioData={checkInFormDataOrientationRadio}
                                            poCheckRadioData={checkInFormDataPOCheckRadio}
                                            jhaRadioData={checkInFormDataJHARadio}
                                            checkInFormDataSetter={setCheckInFormData}
                                            lseRadioSetter={setCheckInFormDataLSERadio}
                                            catRadioSetter={setCheckInFormDataCatRadio}
                                            orientationRadioSetter={setCheckInFormDataOrientationRadio}
                                            poCheckRadioSetter={setCheckInFormDataPOCheckRadio}
                                            jhaRadioSetter={setCheckInFormDataJHARadio}
                                            page={page}
                                            checkInDataSetter={setCheckInData}
                                            pendingCheckoutListSetter={setPendingCheckOutList}
                                            myCheckInListDataSetter={setMyCheckInList}
                                            checkInData={checkInData}
                                            pendingCheckOutList={pendingCheckOutList}
                                            myCheckInList={myCheckInList}
                                            siteCheckInList={siteCheckInList}
                                            siteCheckInListSetter={setSiteCheckInList}
                                            checkInValidationError = {checkInValidationError}
                                            checkInValidationSetter = {setCheckInValidationError}
                                            clearCheckInForm = {clearCheckInForm}
                                        >
                                        </ProfileSetup>
                                    }
                                </Center>
                            }
                        </ScrollView>
                        {/*<Center>*/}
                        {/*    <HStack marginTop={'5rem'} backgroundColor={"lightBlue.500"} minWidth={300}>*/}
                        {/*        <Center>*/}
                        {/*            <ActionSheetComponent/>*/}
                        {/*        </Center>*/}
                        {/*    </HStack>*/}
                        {/*</Center>*/}
                    </View>
                </Center>
                {/*<HStack><Label labelText={'Copyright Amazon Inc (2022-2023)'}></Label></HStack>*/}

            </Box>
        </NativeBaseProvider>
    );
}
export default withAuthenticator(App);
