import { Alert, Box, Center, VStack } from "native-base";
import { withAuthenticator } from "@aws-amplify/ui-react";

const AlertText = (props) => {
    return (
        <Box>
            <Center>
                <Alert
                    padding={props.padding || '1rem'}
                    colorScheme={props.colorScheme || 'info'}
                >
                    <VStack space={2} flexShrink={1}>
                        <Box _text="coolGray.600">
                            {props.header}
                        </Box>
                        {props.children}
                    </VStack>
                </Alert>
            </Center>
        </Box>
    )
}

export default withAuthenticator(AlertText);