import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../styles/style";
import React, {useEffect, useState} from "react";
import {View} from "react-native-web";
import {
    NativeBaseProvider,
    Box, Center, VStack, Progress, Text,
} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import SimpleDropdown from "../components/SimpleDropdown/simple-dropdown";
import { COUNTRIES } from "src/constants/region-country-constants";
import Checkinlist from "./checkinlist";
import getCheckInsBySite from "../getCheckInsBySite";
import {SpinnerComponent} from "../components/SpinnerComponent/spinner";
import { COUNTRY_TO_SITE_CODES_MAP } from "../constants/sites-dropdown-constants";

function SiteCheckInList(props) {

    // un comment only for testing
    // useEffect(()=> {
    //     console.log("useEffect%Page 1-> Drop down data" + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])
    //
    // useEffect(()=> {
    //     console.log("useEffect%Page 1-> Radio  data" + JSON.stringify(props.lseRadioData))
    // }, [props.checkInFormDataRadio, props.lseRadioData])

    // useEffect(() => {
    //     getCheckins(checkInDataSetter, pendingCheckoutListSetter, myCheckInListDataSetter)
    //     getCheckInsBySite(siteCheckInListSetter)
    // }, []);

    // props.checkInFormDataSetter(prev => {
    //     return {
    //         ...prev,
    //         siteLevelDataSiteCode: undefined
    //     }
    // });
    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
        async function fetchData() {
            // You can await here
            let siteCode = props.checkInFormData['siteLevelDataSiteCode']
            let country = props.checkInFormData['country']
            if (siteCode !== undefined && siteCode != '') {
                setInProgress(true)
                await getCheckInsBySite(props.siteCheckInListSetter, siteCode, country, props.complianceNumbersSetter, setInProgress)
                    .then(response => {})
            }
        }
        fetchData();
    }, [props.checkInFormData['siteLevelDataSiteCode']]);

    const handleChangeInCountry = (attributeName) => {
        if(attributeName === 'country') {
            props.checkInFormDataSetter((prev) =>  {
                    return {
                        ...prev,
                        'siteLevelDataSiteCode' : ''
                    }
                }
            );
        }
    }

    return (
        <View style={styles.header}>
            <Box>
                <SimpleDropdown label = "Country where services being provided"
                                placeholder = "In what Country are services being provided?"
                                required = {true}
                                minWidth={300}
                                options = {Object.values(COUNTRIES)}
                                attributeName = 'country'
                                handleChange = {handleChangeInCountry}
                                { ...props }
                />
                <SimpleDropdown label = "Site code"
                                placeholder = "Site code"
                                required = {true}
                                minWidth={300}
                                options = {COUNTRY_TO_SITE_CODES_MAP[props.checkInFormData['country']]}
                                attributeName = 'siteLevelDataSiteCode'
                                { ...props }/>
            </Box>
            <Box>
                <VStack>
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        {/*<VStack>*/}
                        {/*    <Badge*/}
                        {/*        marginTop={"2rem"}*/}
                        {/*        marginRight={"0.5rem"}*/}
                        {/*        colorScheme={"info"}*/}
                        {/*        rounded="full" mb={-4} mr={-4} zIndex={1} variant="solid" alignSelf="flex-end" _text={{*/}
                        {/*        fontSize: 12*/}
                        {/*    }}>*/}
                        {/*        {props.siteCheckInList.length}*/}
                        {/*    </Badge>*/}
                        {/*</VStack>*/}

                        { props.complianceNumbers.total !== 0 && props.complianceNumbers.total !== -1 &&
                            <View>
                                <Center>
                                    <Text marginTop="1rem">
                                        Non-Compliant
                                    </Text>
                                    <Text>
                                        {(props.complianceNumbers.red/props.complianceNumbers.total * 100).toFixed(2)} %
                                    </Text>
                                    <Progress colorScheme="red"
                                              marginTop="1rem"
                                              value={(props.complianceNumbers.red/props.complianceNumbers.total * 100).toFixed(2).toString()}
                                              max={100}
                                              minW={300}/>
                                </Center>

                                <Center>
                                    <Text marginTop="1rem">
                                        Conditionally-Compliant
                                    </Text>
                                    <Text>
                                        {(props.complianceNumbers.amber/props.complianceNumbers.total * 100).toFixed(2)} %
                                    </Text>
                                    <Progress colorScheme="amber"
                                              marginTop="1rem"
                                              value={(props.complianceNumbers.amber/props.complianceNumbers.total * 100).toFixed(2).toString()}
                                              max={100}
                                              minW={300}/>
                                </Center>

                                <Center>
                                    <Text marginTop="1rem">
                                        Not found/InActive/Deactivated in Avetta
                                    </Text>
                                    <Text>
                                        {(props.complianceNumbers.gray/props.complianceNumbers.total * 100).toFixed(2)} %
                                    </Text>
                                    <Progress colorScheme="gray"
                                              marginTop="1rem"
                                              value={(props.complianceNumbers.gray/props.complianceNumbers.total * 100).toFixed(2).toString()}
                                              max={100}
                                              minW={300}/>
                                </Center>
                                <Center>
                                    <Text marginTop="1rem">
                                        Compliant
                                    </Text>
                                    <Text>
                                        {(props.complianceNumbers.green/props.complianceNumbers.total * 100).toFixed(2)} %
                                    </Text>
                                    <Progress colorScheme="green"
                                              marginTop="1rem"
                                              value={(props.complianceNumbers.green/props.complianceNumbers.total * 100).toFixed(2).toString()}
                                              max={100}
                                              minW={300}/>
                                </Center>
                                <Checkinlist pageChanger={props.pageChanger}
                                             signOut ={props.signOut}
                                             user= {props.user}
                                             checkout={false}
                                             data={props.siteCheckInList}
                                             checkOutFormDataSetter={props.checkOutFormDataSetter}
                                             title = 'Site Data'
                                />
                            </View>
                        }
                        { !inProgress  && props.complianceNumbers.total == 0  && props.checkInFormData['siteLevelDataSiteCode'] != undefined 
                         && props.checkInFormData['siteLevelDataSiteCode'] != '' &&
                            <View>
                                <Center>
                                    <Text marginTop="1rem">
                                        No Record(s) found!
                                    </Text>
                                </Center>
                            </View>
                        }
                        { inProgress &&
                            <View>
                                <Center>
                                    <SpinnerComponent text='Please wait! We are crunching numbers for you.'></SpinnerComponent>
                                </Center>
                            </View>
                        }
                    </NativeBaseProvider>
                </VStack>
            </Box>

        </View>
    );
}

export default withAuthenticator(SiteCheckInList);