import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../styles/style";
import React, {useEffect, useState} from "react";
import {View} from "react-native-web";
import {
    NativeBaseProvider,
    Button,
    Box,
    Center,
    Divider
} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import SimpleDropdown from "../components/SimpleDropdown/simple-dropdown";
import SimpleRadio from "../components/SimpleRadio/simple-radio";
import { JOB_TYPE_ACTIVITY_LIST } from "../constants/activity-job-list-constants";
import AlertText from "../components/AlertText/alert-text-component";
import { MULTI_CHECK_IN_PAGE} from "../utils/constants";
import {
    AVETTA_COMPLIANCE_CHECK_KEY,
    AVETTA_COMPLIANCE_CHECK_MESSAGE,
    AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS,
    AVETTA_COMPLIANCE_CPOC_NEXT_STEPS,
    AVETTA_COMPLIANCE_STATUS_CODE
} from "../constants/attribute-names-constants";
import { COUNTRIES } from "src/constants/region-country-constants";
import { COUNTRY_TO_SITE_CODES_MAP } from "../constants/sites-dropdown-constants";

function Page1(props) {
    // un comment only for testing
    // useEffect(()=> {
    //     console.log("useEffect%Page 1-> Drop down data" + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])
    //
    // useEffect(()=> {
    //     console.log("useEffect%Page 1-> Radio  data" + JSON.stringify(props.lseRadioData))
    // }, [props.checkInFormDataRadio, props.lseRadioData])

    useEffect(() => {
        props.checkInFormDataSetter((prev) =>  {
            return {
                    ...prev,
                    [AVETTA_COMPLIANCE_CHECK_KEY]: undefined,
                    [AVETTA_COMPLIANCE_CHECK_MESSAGE]: undefined,
                    [AVETTA_COMPLIANCE_STATUS_CODE]: undefined ,
                    [AVETTA_COMPLIANCE_CPOC_NEXT_STEPS]: undefined,
                    [AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS]: undefined,
                    sk : undefined
                }
            }
        );
    },[])

    const validate = (props) => {
        if (props.checkInFormData['country'] === undefined || props.checkInFormData['country'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, country : 'Please select country'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, country : ''} })
        }
        if (props.checkInFormData['siteCode'] === undefined || props.checkInFormData['siteCode'] === ''){
            props.checkInValidationSetter((prev) => { return  {...prev, siteCode : 'Please select site code'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, siteCode : ''} })
        }
        if (props.checkInFormData['jobRiskAssessment'] === undefined || props.checkInFormData['jobRiskAssessment'] === ''){
            props.checkInValidationSetter((prev) => { return  {...prev, jobRiskAssessment : 'Please select job operation'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, jobRiskAssessment : ''} })
        }
        if (props.lseRadioData['lseEvent'] === undefined || props.lseRadioData['lseEvent'] === ''){
            props.checkInValidationSetter((prev) => { return  {...prev, 'lseEvent' : 'Please select an event'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, 'lseEvent' : ''} })
        }
        // if everything is great then move on
        props.checkInPageChanger(2)
    }

    const multiDayCheckInNote = 'Note: For check-ins required for multiple days, please click this button for easy check in.'
    const freshCheckInNote = 'For fresh/new check-in please continue by answering the below questions and clicking Next.'

    const handleChangeInCountry = (attributeName) => {
        if(attributeName === 'country') {
            props.checkInFormDataSetter((prev) =>  {
                    return {
                        ...prev,
                        'siteCode' : ''
                    }
                }
            );

            props.checkInValidationSetter((prev) => {
                    return  {
                        ...prev,
                        'siteCode' : ''
                    }
                }
            );
        }
    }

    return (
        <View style={styles.header}>
            <AlertText padding={'1rem'} header={multiDayCheckInNote} >
                <NativeBaseProvider theme={RoundedButtonTheme}>
                    <Button marginTop={"1rem"} minW={"250"} colorScheme="lightBlue" variant="rounded" onPress={() => props.checkInPageChanger(MULTI_CHECK_IN_PAGE)}>
                        Re Check-In Multi-Day Supplier(s)
                    </Button>
                </NativeBaseProvider>
            </AlertText>
            <Divider mt={"0.5rem"}
                     mb={"0.5rem"}
                     bg="grey"
                     thickness="4"
                     orientation="horizontal" />
            <AlertText header={freshCheckInNote} />
            <Box>
                <SimpleDropdown label = "Country where services being provided"
                                placeholder = "In what Country are services being provided?"
                                required = {true}
                                minWidth={300}
                                options = {Object.values(COUNTRIES)}
                                attributeName = 'country'
                                handleChange = {handleChangeInCountry}
                                { ...props }
                />

                <SimpleDropdown label = "Site code"
                                placeholder = "Site code"
                                required = {true}
                                minWidth={300}
                                options = {COUNTRY_TO_SITE_CODES_MAP[props.checkInFormData['country']]}
                                defaultValue='DSM5'
                                attributeName = 'siteCode'
                                { ...props }

                />

                <SimpleDropdown label = "Type of work being performed"
                                placeholder = "Type of work being performed"
                                required = {true}
                                minWidth={300}
                                options = {JOB_TYPE_ACTIVITY_LIST}
                                defaultValue='Fix Conveyer- HRO'
                                attributeName = 'jobRiskAssessment'
                                { ...props }
                />


                <SimpleRadio label = "Select an event (If applicable)"
                             placeholder = "Select an event (If applicable)"
                             required = {true}
                             colorScheme = "red"
                             options = {[
                                 {
                                     id: 'Sev-1',
                                     value: 'Sev-1',
                                     key: 'sev1'
                                 },
                                 {
                                     id: 'Sev-2',
                                     value: 'Sev-2',
                                     key: 'sev2'
                                 },
                                 {
                                     id: 'Work Order/Project',
                                     value: 'Work Order/Project',
                                     key: 'workOrderProject'
                                 }
                             ]}
                             attributeName = 'lseEvent'
                             checkInFormDataRadio = {props.lseRadioData}
                             checkInFormDataRadioSetters = {props.lseRadioSetter}
                             { ...props }
                />
            </Box>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Center>
                <Button marginTop={"1rem"} minW={300} colorScheme="lightBlue" onPress={() => validate(props)}
                     variant="rounded"> Next </Button>
                </Center>
            </NativeBaseProvider>
        </View>
    );
}

export default withAuthenticator(Page1);