import { Auth } from "aws-amplify";
import {
  CHECK_IN_PATH,
  getAvettaColorCodeFromCheckInEntryRecord,
} from "./utils/constants";
import { getSupplierCheckInApiEndpoint } from "./config";
import { COUNTRY_TO_REGION_MAP } from "./constants/region-country-constants";

const initialComplianceNumbers = {
  gray: 0,
  green: 0,
  red: 0,
  amber: 0,
  total: 0,
};
const getCheckinsBySite = async (
  siteCheckInListSetter,
  siteCode,
  country,
  complianceNumbersSetter,
  setInProgress
) => {
  try {
    let token = "";
    complianceNumbersSetter((prev) => {
      return initialComplianceNumbers;
    });
    setInProgress(true);
    Auth.currentAuthenticatedUser().then((data) => {
      token = data.signInUserSession.idToken.jwtToken;
      // const cpocEmail = data.attributes.email
      // const alias = cpocEmail.substring(0, cpocEmail.lastIndexOf("@"));
      let requestHeaders = new Headers();
      requestHeaders.append("Authorization", "Bearer " + token);
      requestHeaders.append("operation_type", "ReadByPk");
      requestHeaders.append("query_pk", siteCode);
      const requestOptions = {
        method: "GET",
        headers: requestHeaders,
        redirect: "follow",
      };
      const url =
        getSupplierCheckInApiEndpoint(COUNTRY_TO_REGION_MAP[country]) +
        CHECK_IN_PATH;
      return fetch(url, requestOptions)
        .then((response) => {
          return response.json().then((data) => ({
            status: response.status,
            data,
          }));
        })
        .then((result) => {
          if (result.status === 200) {
            const data = result.data.Items;
            if (data.length > 0) {
              siteCheckInListSetter(
                getCheckInListWithOrWithoutCheckedOut(
                  data,
                  complianceNumbersSetter,
                  false
                )
              );
            } else {
              // no-op
              siteCheckInListSetter([]);
              setInProgress(false);
              complianceNumbersSetter((prev) => {
                return {
                  ...prev,
                  total: -1,
                };
              });
            }
            setInProgress(false);
          }
        })
        .catch((error) => {
          console.log(
            "Error while fetching and parsing site checkin list: " + error
          );
          console.log(error.stack);
        });
    });
  } catch (err) {}
};

/**
 * This is redundant code and needs to be removed from here!
 * TODO - Tech Debt - https://issues.amazon.com/issues/ISC-ST-2166
 * @param checkInList
 * @param complianceNumbersSetter
 * @param excludeCheckedOut
 * @returns {*[]}
 */
function getCheckInListWithOrWithoutCheckedOut(
  checkInList,
  complianceNumbersSetter,
  excludeCheckedOut
) {
  const checkInsList = [];
  complianceNumbersSetter((prev) => {
    return {
      ...prev,
      total: 0,
    };
  });
  checkInList.forEach((checkInEntry) => {
    let checkInObj = {};
    let pk = checkInEntry.pk;
    let sk = checkInEntry.sk;
    let job_type = checkInEntry.job_type;
    let alias = checkInEntry.alias;
    let country = checkInEntry.country;
    let supplierRepEntries = Object.entries(
      checkInEntry.supplier_representatives_metadata_v2 ||
        checkInEntry.supplier_representatives_metadata
    );
    complianceNumbersSetter((prev) => {
      return {
        ...prev,
        total: prev.total + supplierRepEntries.length,
      };
    });
    let avetta_status = checkInEntry.avetta_compliance_status;
    // default to color gray if company is not found
    let avetta_color_code =
      getAvettaColorCodeFromCheckInEntryRecord(checkInEntry);
    let eventFlag = checkInEntry.containsSev;
    let supplierCompanyName = checkInEntry.supplier_company_name || "";
    supplierRepEntries.forEach((supplierInfo) => {
      checkInObj["supplierNameKey"] = supplierInfo[0];

      // If using supplier_representatives_metadata_v2 then supplierInfo[1] is an array of representatives records,
      // we find the entry with the biggest index value, that is the latest representatives records
      // If using supplier_representatives_metadata, then we use supplierInfo[1] directly since is just an object
      const supplierDetails =
        checkInEntry.supplier_representatives_metadata_v2 &&
        Array.isArray(supplierInfo[1])
          ? supplierInfo[1].reduce((prev, current) =>
              prev.index > current.index ? prev : current
            )
          : supplierInfo[1];

      // This field is used in checkout lambda to update the correct meta_data attribute
      checkInObj["isMetadataV2Available"] =
        checkInEntry.supplier_representatives_metadata_v2 !== undefined;
      checkInObj["index"] = supplierDetails.index ? supplierDetails.index : 0;

      checkInObj["name"] = supplierDetails.contractor_name;
      checkInObj["checkInTime"] = new Date(
        supplierDetails.checkInTime
      ).toLocaleString();
      checkInObj["checkInBy"] = alias;
      checkInObj["checkedOut"] = supplierDetails.checkedOut;
      checkInObj["checkOutTime"] = supplierDetails.actualCheckOutTime
        ? new Date(supplierDetails.actualCheckOutTime).toLocaleString()
        : "";
      checkInObj["pk"] = pk;
      checkInObj["sk"] = sk;
      checkInObj["jobType"] = job_type;
      checkInObj["avetta_status"] = avetta_status;
      checkInObj["avetta_color_code"] = avetta_color_code;
      complianceNumbersSetter((prev) => {
        return {
          ...prev,
          [avetta_color_code.toLowerCase()]:
            prev[avetta_color_code.toLowerCase()] + 1,
        };
      });
      checkInObj["eventFlag"] = eventFlag;
      checkInObj["supplierCompanyName"] = supplierCompanyName;
      checkInObj["siteCode"] = pk;
      checkInObj["country"] = country;
      if (excludeCheckedOut) {
        if (!supplierDetails.checkedOut) {
          checkInsList.push(checkInObj);
        }
      } else {
        checkInsList.push(checkInObj);
      }
      checkInObj = {};
    });
  });
  return checkInsList;
}

export default getCheckinsBySite;
