import React, {useState} from 'react'
import {Auth} from 'aws-amplify';
import {TextInput} from "../FormInput/TextInput";
import {VStack, Center, Heading, Button} from "native-base";
import {SpinnerComponent} from "../SpinnerComponent/spinner";

export function ConfirmUser({email, setAlert, setFormState}) {
    const [verificationCode, setVerificationCode] = useState('')
    const [verificationCodeError, setVerificationCodeError] = useState('')
    const [validatingCode, setValidatingCode] = useState(false)

    /**
     * Validates the code sent to email and Signs Up user
     */
    async function confirmUser() {
        setAlert(undefined)
        if (!verificationCode) {
            setVerificationCodeError('Please provide verification code')
            return;
        }
        setVerificationCodeError('')
        try {
            setValidatingCode(true)
            await Auth.confirmSignUp(email, verificationCode);
            setAlert({
                header: 'Sign Up Success',
                body: 'Please Sign In Now',
                status: 'success'
            })
            setValidatingCode(false)
            setFormState('signIn')
        } catch (err) {
            console.error('Error confirming sign up', err);
            setValidatingCode(false)
            setVerificationCodeError('Invalid verification code provided, please try again.')
        }
    }

    return (
        <VStack space={3} marginTop={"3rem"}>
            <Center><Heading size="md" marginTop={"1rem"}>Confirm User</Heading></Center>
            <TextInput value={verificationCode} errorText= {verificationCodeError} onChangeHandler = {setVerificationCode} placeholder='Verification Code sent to your email'/>
            {
                validatingCode &&
                <SpinnerComponent text = {'Validating verification code...'} />
            }
            <Button marginTop={"2rem"}
                colorScheme="lightBlue"
                variant={"rounded"}
                onPress={confirmUser}>
                    Verify Account
            </Button>
        </VStack>
    )
}
