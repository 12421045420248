import React from "react";
import {Input, FormControl, WarningOutlineIcon } from "native-base";

interface TextInputInterface {
    label?: string;
    value?: string;
    placeholder?: string;
    helperText?: string;
    errorText?: string;
    isRequired?: boolean;
    isReadOnly?: boolean;
    onChangeHandler: Function;
}

export function TextInput(props: TextInputInterface) {
    let invalid = false
    if(props.errorText != undefined && props.errorText != '') {
        invalid = true
    } else {
        invalid = false
    }

    return (
        <FormControl isInvalid = {invalid} >
            <FormControl.Label>{props.label}</FormControl.Label>
            <Input 
                variant="underlined"
                minWidth={300}
                value={props.value}
                placeholder={props.placeholder}
                onChangeText={text => props.onChangeHandler(text)}
             />
            <FormControl.HelperText>
                {props.helperText}
            </FormControl.HelperText>
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {props.errorText}
            </FormControl.ErrorMessage>
        </FormControl>
    );
}
