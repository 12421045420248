import React, {useState, useEffect} from "react";
import {Box, Button, Center, Flex, NativeBaseProvider, Text, View, VStack} from "native-base";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Label from "../../components/Label/label";
import {
    CHECKIN_NEXT_STEPS,
    RE_CHECK_IN_PAGE
} from "../../utils/constants";
import submitDataAndUpdateThePage from "../../submitData";

function ReviewReCheckIn(props) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    /**
     * This labels maps keeps the translation of
     * various keys to user friendly label names.
     */
    const [labelsMap, setLabelsMap] = useState({
        suppliernameslist : 'Supplier Representative(s)',
        alias : 'Amazon Alias',
        jobriskassessment: 'Job Assessment',
        country: 'Country',
        sitecode: 'Site Code',
        contractorname: 'Supplier/Contractor/Vendor',
        avettacompliance: 'Avetta Compliance Status',
    });


    /**
     * Stub to submit the data
     */
    const submitCheckInData = () => {
        setButtonDisabled(true)
        submitDataAndUpdateThePage(props, CHECKIN_NEXT_STEPS)
    }
    return (
        <View minW={300} maxW={300} style={{
            flexDirection: "column",
            padding: 1
        }}>
            <Center>
                <Label labelText = "Review Re-Check-In Details" ></Label>
            </Center>

            <VStack marginTop={'1rem'}>
                {
                    Object.keys(props.checkInFormData).map(key => (
                            <Box>
                                { labelsMap[key.toLowerCase()] !== undefined &&
                                    <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                        <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                            <Text style={{ padding: '0.3rem'}}>{ labelsMap[key.toLowerCase()] }</Text>
                                        </View>
                                        <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                            <Text>
                                                {
                                                    key.toLowerCase() === 'suppliernameslist' && <Box>
                                                        {
                                                            props.checkInFormData[key].map((item) => item.name)
                                                        }

                                                    </Box>
                                                }
                                                {
                                                    key.toLowerCase() === 'country' && <Box>
                                                        {props.checkInFormData[key].toUpperCase()}
                                                    </Box>
                                                }
                                                {
                                                    key.toLowerCase() === 'avettacompliance' && <Box>
                                                        {props.checkInFormData[key].toUpperCase()}
                                                    </Box>
                                                }
                                                {
                                                    key.toLowerCase() !== 'suppliernameslist' &&
                                                    key.toLowerCase() !== 'country'  &&
                                                    key.toLowerCase() !== 'avettacompliance' && <Box>
                                                        {props.checkInFormData[key]}
                                                    </Box>
                                                }
                                            </Text>
                                        </Flex>
                                    </Box>
                                }
                            </Box>
                        )
                    )
                }
            </VStack>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"}
                        onPress= {submitCheckInData}
                        colorScheme="lightBlue"
                        variant="rounded"
                        isDisabled={buttonDisabled}> Complete Check In </Button>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkInPageChanger(RE_CHECK_IN_PAGE)}
                        variant="rounded"
                        isDisabled={buttonDisabled}> Previous
                </Button>
            </NativeBaseProvider>
        </View>
    )
}
export default withAuthenticator(ReviewReCheckIn)