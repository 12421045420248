import { Auth } from 'aws-amplify'
import {isLseEvent} from "../utils/constants";
import {
    ALIAS,
    PROFILE_CONTRACTOR,
    PROFILE_COUNTRY, PROFILE_JOB_ASSESSMENT, PROFILE_LSE_EVENT,
    PROFILE_ORG,
    PROFILE_SITE_CODE
} from "../constants/attribute-names-constants";

function getSubmitPayload(props) {
    const payloadValuesJson = {
        "pk": props.checkInFormData[ALIAS],
        "siteCode" : props.checkInFormData[PROFILE_SITE_CODE],
        "operationType" : props.checkInFormData[PROFILE_JOB_ASSESSMENT],
        "country" : props.checkInFormData[PROFILE_COUNTRY],
        "orgName" : props.checkInFormData[PROFILE_ORG],
        "contractor" : props.checkInFormData[PROFILE_CONTRACTOR],
        "lseEvent" : props.lseRadioData[PROFILE_LSE_EVENT]
    };
    return payloadValuesJson;
}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function saveProfile(props) {
    const formDataPayload = getSubmitPayload(props)
    return sleep(10).then(() => {
        let token = "";
        Auth.currentAuthenticatedUser().then((data) => {
            let myHeaders = new Headers();
            token = data.signInUserSession.idToken.jwtToken;
            myHeaders.append("Authorization", "Bearer " + token)
            const requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(formDataPayload),
                redirect: 'follow',
            };
            // TODO: When implementing self serve changes. Use the correct endpoint based on region/ country
            const url = 'placeholder.a2z.com'
            fetch(url, requestOptions)
                .then((response) => {
                    return response.json().then((data) => ({
                        response: response,
                        status: response.status,
                        data,
                    }));
                })
                .then((result) => {
                    if (result.response.ok) {
                        //propsWithAllFormData.checkInPageChanger(nextPage)
                        //alert("Profile Submitted!")
                    } else {
                        alert('Error occurred while checking in the supplier, please try again.')
                    }
                })
                .catch(error => {
                    alert('Error occurred while checking in the supplier, please try again.')
                });
        });
    });
}

export const getProfile = async (props) => {
    console.log("get profile data payload " + JSON.stringify(props.checkInFormData))

    console.log("get profile data Radio  " + JSON.stringify(props))
    return sleep(10).then(() => {
        let token = "";
        Auth.currentAuthenticatedUser().then((data) => {
            let emailAddress = data.attributes.email
            let index = emailAddress.indexOf('@')
            let loggedUserAlias = emailAddress.slice(0,index)
            let myHeaders = new Headers();
            token = data.signInUserSession.idToken.jwtToken;
            myHeaders.append("Authorization", "Bearer " + token)
            myHeaders.append("pk", loggedUserAlias)
            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };

            // TODO: When implementing self serve changes. Use the correct endpoint based on region/country.
            const url = 'placeholder.a2z.com'
            fetch(url, requestOptions)
                .then((response) => {
                    return response.json().then((data) => ({
                        response: response,
                        status: response.status,
                        data,
                    }));
                })
                .then((result) => {
                    //alert(JSON.stringify(result))
                    if (result.status === 200) {
                        //propsWithAllFormData.checkInPageChanger(nextPage)
                        //alert("Profile Fetched")
                        props.checkInFormDataSetter((prev) => {
                            return {
                                ...prev,
                                [PROFILE_COUNTRY] : result.data.Items[0].country,
                                [PROFILE_CONTRACTOR]: result.data.Items[0].contractor,
                                [PROFILE_SITE_CODE] : result.data.Items[0].siteCode,
                                [PROFILE_ORG] : result.data.Items[0].orgName,
                                [PROFILE_JOB_ASSESSMENT]: result.data.Items[0].operationType
                            }
                        });
                        props.lseRadioSetter((prev) => {
                            return {
                                ...prev,
                                [PROFILE_LSE_EVENT]: result.data.Items[0].lseEvent// to-do change
                            }
                        })
                        return result.data
                    } else {
                        alert('Error occurred please try again.')
                    }
                })
                .catch(error => {
                    alert('Error occurred while checking in the supplier, please try again.')
                });
        });
    });
}