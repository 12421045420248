import {Auth} from "aws-amplify";
import {
    AVETTA_COMPLIANCE_STATUS_CODE,
    PROFILE_CONTRACTOR,
    PROFILE_COUNTRY, PROFILE_JOB_ASSESSMENT, PROFILE_ORG,
    PROFILE_SITE_CODE,
    TEXT_COMPONENT_ATTRIBUTE_NAME_KEY
} from "../constants/attribute-names-constants";
import {selfCheckIn} from "./self-check-in";

export const performAvettaCall = async (props) => {
    try {
        let token = "";
        Auth.currentAuthenticatedUser().then((data) => {
            token = data.signInUserSession.idToken.jwtToken;
            let requestHeaders = new Headers();
            let companyName = props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY]
            requestHeaders.append("Authorization", "Bearer " + token);
            requestHeaders.append(
                "country_code",
                props.checkInFormData["country"] === undefined ? props.checkInFormData[PROFILE_COUNTRY].toLowerCase() :
                    props.checkInFormData["country"].toLowerCase()
            );
            requestHeaders.append(
                "supplier_company_name",
                companyName
            );
            requestHeaders.append(
                "site_code",
                props.checkInFormData["siteCode"] === undefined ? props.checkInFormData[PROFILE_SITE_CODE].toLowerCase() :
                    props.checkInFormData["siteCode"].toLowerCase()
            );
            requestHeaders.append("default_sk", "amazon us supplier");
            const requestOptions = {
                method: "GET",
                headers: requestHeaders,
                redirect: "follow",
            };
            // TODO: When implementing self serve changes. Use the correct endpoint based on region/country.
            const url = 'placeholder.a2z.com'
            fetch(url, requestOptions)
                .then((response) => {
                    return response.json().then((data) => ({
                        status: response.status,
                        data,
                    }));
                })
                .then((result) => {
                    const responseStatus = result.status;
                    props.checkInFormDataSetter((previous) => {
                        return {
                            ...props.checkInFormData,
                            [TEXT_COMPONENT_ATTRIBUTE_NAME_KEY] : companyName,
                            [AVETTA_COMPLIANCE_STATUS_CODE] : result.data["avetta_compliance_status"]
                        }
                    });
                    if(responseStatus === 409){ //multiple choices
                        // setAvettaComplianceTextForMultipleCompanyFound(result.data);
                    } else if (responseStatus === 404) {
                        return;
                    }
                    else if(responseStatus === 412) {
                        return;
                    }
                    else if (responseStatus >= 500) {
                        return;
                    } else if (responseStatus === 200) {
                        // props.checkInFormDataSetter((prev) => {
                        //     return {
                        //         ...prev,
                        //         [AVETTA_COMPLIANCE_STATUS_CODE] : result.data["avetta_compliance_status"]
                        //     }
                        // });
                        return props.checkInFormData[AVETTA_COMPLIANCE_STATUS_CODE]
                    } else {
                        return;
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
        });
    } catch (err) {
    }
}