export const REGION = {
    NA: 'NA',
    EU: 'EU'
};

export const COUNTRIES = {
    US: {
        id: 'United States',
        value: 'us'
    }, 
    CA: {
        id: 'Canada',
        value: 'ca'
    },
    UK: {
        id: 'United Kingdom',
        value: 'uk'
    },
    IN: {
        id: 'India',
        value: 'in'
    }, 
    AE: {
        id: 'United Arab Emirates',
        value: 'ae'
    },
    EG: {
        id: 'Egypt',
        value: 'eg'
    },
    SA: {
        id: 'Saudi Arabia',
        value: 'sa'
    }
}

export const REGION_TO_COUNTRY_MAP = {
    NA: [ 'US', 'CA'], 
    EU: [ 'UK', 'IN', 'AE', 'EG', 'SA' ]
}


export const COUNTRY_TO_REGION_MAP = {}
export const COUNTRY_CODE_TO_NAME_MAP = {}


for(let region of Object.keys(REGION_TO_COUNTRY_MAP)) {
    for(let country of REGION_TO_COUNTRY_MAP[region]) {
        COUNTRY_TO_REGION_MAP[COUNTRIES[country].value] = region;
        COUNTRY_CODE_TO_NAME_MAP[COUNTRIES[country].value] = COUNTRIES[country].id;
    }
}
