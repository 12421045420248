import {withAuthenticator} from "@aws-amplify/ui-react";
import {styles} from "../styles/style";
import React, {useState} from "react";
import {View} from "react-native-web";
import SimpleDropdown from "../components/SimpleDropdown/simple-dropdown";
import {NativeBaseProvider, Button} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import SimpleRadio from "../components/SimpleRadio/simple-radio";
import Label from "../components/Label/label";
import {ScrollView, Text, Modal, Link} from "native-base";
import { ORGANIZATIONS_DROPDOWN_LIST } from "../constants/org-names-dropdown-constants";
import TextComponent from "../components/TextInputComponent/component";
 
function Page3(props) {
 
    const [aliasReadOnly, setAliasReadOnly] = useState(props.loggedInUser);
    // uncomment for internal testing only
    // useEffect(()=> {
    //     console.log("Page 3-> state of the form Now is " + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])
    //
    // useEffect(() => {
    //         console.log("Page 3-> state of  lse radio " + JSON.stringify(props.lseRadioData))
    //         console.log("Page 3-> state of cat radio " + JSON.stringify(props.catRadioData))
    //         console.log("Page 3-> state of orientation radio " + JSON.stringify(props.orientationRadioData))
    //         console.log("Page 3-> state of jha radio " + JSON.stringify(props.jhaRadioData))
    //         console.log("Page 3-> state of po check radio " + JSON.stringify(props.poCheckRadioData))
    //     }, [
    //         props.lseRadioData,
    //         props.catRadioData,
    //         props.orientationRadioData,
    //         props.jhaRadioData,
    //         props.poCheckRadioData
    //     ]
    // )
    const [poWarningModalVisible, setPOWarningModalVisible] = useState(false);
 
    const handlePORadioChange = (poRadioValue) => {
        if(poRadioValue === "No") {
            setPOWarningModalVisible(true)
        }
    }
 
    const validatePage3 = (props) => {
        if (props.checkInFormData['orgName'] === undefined || props.checkInFormData['orgName'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, orgName : 'Please select org'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, orgName : ''} })
        }
 
        if (props.checkInFormData['preRequisite'] === undefined || props.checkInFormData['preRequisite'] === '' ) {
            props.checkInValidationSetter((prev) => { return  {...prev, preRequisite : 'Please select pre-requisite(s) option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, preRequisite : ''} })
        }
 
        if (props.poCheckRadioData['poCheck'] === undefined || props.poCheckRadioData['poCheck'] === ''){
            props.checkInValidationSetter((prev) => { return  {...prev, 'poCheck' : 'Please select an option'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, 'poCheck' : ''} })
        }
 
        if (props.poCheckRadioData['poCheck'] !== undefined && props.poCheckRadioData['poCheck'] === 'Yes') {
            if (props.checkInFormData['poNumber'] === undefined || props.checkInFormData['poNumber'].length === 0){
                props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'PO number cannot be blank'} })
                return;
            } else {
                 if (props.checkInFormData['poNumber'].includes(" ") || props.checkInFormData['poNumber'].includes('\t')){
                    props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'PO number cannot contain spaces or tabs'} })
                    return;
                }
                if (props.checkInFormData['poNumber'] !== undefined && props.checkInFormData['poNumber'].length >= 13){
                    props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'PO number cannot be more than 13 characters'} })
                    return;
                }
                if (props.checkInFormData['poNumber'] !== undefined && props.checkInFormData['poNumber'].length <= 8){
                    props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'PO number cannot be less than 9 characters'} })
                    return;         
                }
                if (props.checkInFormData['poNumber'] !== undefined &&
                    props.checkInFormData['poNumber'].length >= 9 &&
                    props.checkInFormData['poNumber'].length <= 12) {
                    let index = props.checkInFormData['poNumber'].indexOf('-')
                    let prefix = props.checkInFormData['poNumber'].slice(0, index)
                    let ignorePrefixCase = prefix.toLowerCase()
                    let ponumber = props.checkInFormData['poNumber'].slice(index + 1)
                    
                    try {
                       
                        var isPositiveInteger = value =>/^\d+$/.test(value);
 
                           if (isPositiveInteger(ponumber))
                       { props.checkInValidationSetter((prev) => { return  {...prev, poNumber : ''} })
                         
                        } else {
                            props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'Invalid format. The PO number should start with 2D- or 5Z- followed by numbers.'} })
                            return;
                          
                        }
 
                    } catch (e) {
                        console.log('No-Op');
                    }
                    if (ignorePrefixCase !== '5z' && ignorePrefixCase !== '2d') {
                        props.checkInValidationSetter((prev) => { return  {...prev, poNumber : 'PO number need to start with 2D- or 5Z-'} })
                        return;
                    } else {
                        props.checkInValidationSetter((prev) => { return  {...prev, poNumber : ''} })
                    }
                   
                } else if (props.checkInFormData['poNumber'] === undefined ||  props.checkInFormData['poNumber'] === '') {
                    props.checkInValidationSetter((prev) => { return  {...prev, poNumber : ''} })
                }
            }
        } else if (props.poCheckRadioData['poCheck'] !== undefined && props.poCheckRadioData['poCheck'] === 'No') {
            props.checkInValidationSetter((prev) => { return  {...prev, 'poNumber' : ''} })
            props.checkInFormDataSetter((prev) => { return {...prev, 'poNumber': ''} })
        }
        // if everything is great then move on
        props.checkInPageChanger(5)
    }
 
    return (
        <View style={styles.header}>
 
            <Label labelText={'Your Amazon alias'}></Label>
 
            <Label labelText={aliasReadOnly}></Label>
 
            <SimpleDropdown label = "Provide your Org Name"
                            placeholder = "Provide your org name"
                            required = {true}
                            minWidth={100}
                            maxWidth={300}
                            options = {ORGANIZATIONS_DROPDOWN_LIST}
                            defaultValue='GPT'
                            attributeName = 'orgName'
                            { ...props }
            />
            <Label labelText="Pre-requisite(s) (VCO, JHA, Category awarded) approved/completed?"
                   truncated={false}
                   marginTop={"1rem"}
                   maxWidth={300}
                   showAsRequired={true}
            />
            <SimpleRadio placeholder = "Pre-Requisite(s)"
                         required = {false}
                         colorScheme = "amber"
                         options = {[
                             {
                                 id: 'Yes',
                                 value: 'Yes'
                             },
                             {
                                 id: 'No',
                                 value: 'No'
                             }
                         ]}
                         attributeName = 'preRequisite'
                         checkInFormDataRadio = {props.checkInFormData}
                         checkInFormDataRadioSetters = {props.checkInFormDataSetter}
                         { ...props }
            />
 
            <Label labelText="Do you have a valid PO?"
                   truncated={false}
                   marginTop={"1rem"}
                   maxWidth={300}
                   showAsRequired={true}
            >
            </Label>
            <SimpleRadio placeholder = "Do you have a valid PO?"
                         required = {false}
                         colorScheme = "amber"
                         options = {[
                             {
                                 id: 'Yes',
                                 value: 'Yes'
                             },
                             {
                                 id: 'No',
                                 value: 'No'
                             }
                         ]}
                         attributeName = 'poCheck'
                         checkInFormDataRadio = {props.poCheckRadioData}
                         checkInFormDataRadioSetters = {props.poCheckRadioSetter}
                         handleRadioChange = {handlePORadioChange}
                         { ...props }
            />
            {
                props.poCheckRadioData['poCheck'] === 'Yes'  && <TextComponent label = "Enter valid purchase order number"
                marginTop="1rem"
                placeholder = "2D/5Z-XXXXXXXX(numbers). E.g., 2D-00091567"
                required = {true}
                attributeName='poNumber'
                {... props}
                />
            } 
 
            <Modal isOpen={poWarningModalVisible} onClose={setPOWarningModalVisible} size="md">
                <Modal.Content >
                    <Modal.CloseButton />
                    <Modal.Header>No PO Warning!</Modal.Header>
                    <Modal.Body>
                        <ScrollView>
                            <Text>
                                Per Amazon’s Spending and Transaction policy, all goods and/or services greater than $10K
                                requires a Purchase Order to be issued prior to commitment to spend. By authorizing a vendor
                                to come onsite to delivery goods or perform services of value $10K or higher you are acknowledging
                                that you may be a violator of the policy” For reference here is the link to  
                                <Link href="https://inside.amazon.com/en/services/legal/us/spendingandtransaction/Pages/default.aspx"> Amazon’s S&TP policy</Link>
                            </Text>
                        </ScrollView>
                    </Modal.Body>
                </Modal.Content>
            </Modal>      
 
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"2rem"} colorScheme="lightBlue" onPress= {() => validatePage3(props)} variant="rounded"> Next </Button>
            </NativeBaseProvider>
 
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"2rem"} colorScheme="lightBlue" onPress= {() => props.checkInPageChanger(3)} variant="rounded"> Previous </Button>
            </NativeBaseProvider>
        </View>
    );
}
export default withAuthenticator(Page3);