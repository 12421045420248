import {styles} from "./style";
import {Image} from "react-native-web";
import {
    Button,
    Center,
    Divider,
    Flex,
    NativeBaseProvider
} from "native-base";
import React from "react";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import logo from '../../assets/gpo_tech_1.png'

/**
 * uncomment this when moving to expo
 * https://medium.com/swlh/how-to-obtain-a-uri-for-an-image-asset-in-react-native-with-expo-88dfbe1023b8
 */
// const headerImage = require('./../../assets/gpo_tech_1.png')

function SignInHeader() {
    return (
        <NativeBaseProvider theme={RoundedButtonTheme}>
            <Center>
                <Image defaultSource = {logo} style={styles.image} />
            </Center>
        </NativeBaseProvider>
    );
}

export default SignInHeader;