import React, { useState, useEffect, useCallback, useRef } from "react";
import {Box, Center, View, NativeBaseProvider, Divider, Button} from "native-base";
import {styles} from "./styles/style";
import {RoundedButtonTheme} from "./themes/rounded-button-theme";
import {Authentication} from "./components/SignIn/Authentication"

export function HomeScreen() {
    const source = "vendor_checkin.png"
    const [begin, setBegin] = useState(false)

    const beginApp = () => {
        setBegin(true)
    }
    return (
        <NativeBaseProvider theme={RoundedButtonTheme}>
            <Box>
                <Center>
                    <View style={styles.app}>
                        <Authentication/>
                    </View>
                </Center>
            </Box>
        </NativeBaseProvider>
    );
}
export default HomeScreen
