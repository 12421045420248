import {Box, Center, Spinner} from "native-base";
import Label from "../Label/label";

export function SpinnerComponent(props) {
    return (
        <Box>
            <Spinner size={props.size === undefined ? 'lg' : 'sm'}></Spinner>
            <Center>
                <Label labelText={props.text}></Label>
            </Center>
        </Box>
    )
}