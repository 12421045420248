// to be interoperable we need to import from react-native instead of react-native-web.
// react-native-web module performs the translation.
import { StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    image: {
        borderColor: 'white',
        borderWidth: 0.5,
        height: 50,
        width: 50,
        resizeMode: 'cover',
        align: 'center',
        marginTop: '2rem',
        padding:'3px'
    },
});