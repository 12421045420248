import {withAuthenticator} from "@aws-amplify/ui-react";
import {CheckIcon, FormControl, Select, WarningOutlineIcon} from "native-base";
import React from "react";

/**
 * A map to hold country to SiteCodes to toggle
 * @type {{uk: *, us: *, ca: *}}
 */

function getSelectOptions(options) {
    let _ = require('lodash')
    let selectOptions = []
    if(options != undefined) {
        _.forEach(options, function (object) {
            selectOptions.push(<Select.Item key ={object.id} label={object.id} value={object.value} />)
        })
    }
    return selectOptions
}
function SimpleDropdown(props) {

    // there is a bug if we try to set the default state and hence not adding this code
    // will look once the happy case is ok.
    // TODO https://issues.amazon.com/issues/ISC-ST-1963

    /**
     * Add the value in the formData
     * @param value
     */
    const handleSelection = (value) => {
        props.checkInFormDataSetter((prev) =>  {
            return {
                    ...prev,
                    [props.attributeName] : value
                }
            }
        );
        // clear the errors on valid user selection
        props.checkInValidationSetter((prev) => { return  {...prev, [props.attributeName] : ''} })
        props.handleChange(props.attributeName)
    }

        return (
            <FormControl isRequired={props.required} isInvalid={props.attributeName in props.checkInValidationError && props.checkInValidationError[props.attributeName] !== ''}>
                <FormControl.Label marginTop={"1rem"} minWidth={300}>{props.label}</FormControl.Label>
                <Select marginTop={"1rem"}
                        minWidth={props.minWidth}
                        maxWidth={props.maxWidth ? props.maxWidth : props.minWidth}
                        placeholder = {props.placeholder}
                        accessibilityLabel={props.placeholder}
                        _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5} />
                }} mt="1"
                selectedValue={''+props.checkInFormData[props.attributeName]}
                onValueChange={(value) => {
                    handleSelection(value)
                }}>
                    {getSelectOptions(props.options)}
                </Select>
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {props.checkInValidationError[props.attributeName]}
                </FormControl.ErrorMessage>
            </FormControl>

        );
}

export default withAuthenticator(SimpleDropdown);