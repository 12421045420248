import {FormControl, Text} from "native-base";
import React from "react";
import {withAuthenticator} from "@aws-amplify/ui-react";

function Label(props) {
    return (
        <FormControl.Label
              isRequired={props.showAsRequired}
              isTruncated={props.truncated}
              marginTop={props.marginTop}
              maxWidth={props.maxWidth}
              _disabled={{
                  _text: {
                      color: "red",
                      fontWeight: "bold"
                  }
              }}
        >
            {props.labelText}
        </FormControl.Label>
    )
}

export default Label;