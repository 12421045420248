// to be interoperable we need to import from react-native instead of react-native-web.
// react-native-web module performs the translation.
import { StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    app: {
        marginHorizontal: "auto",
        maxWidth: 500
    },
    logo: {
        height: 80
    },
    header: {
        padding: 20
    },
    title: {
        fontWeight: "normal",
        fontSize: "1.0rem",
        marginVertical: "0.5em",
        textAlign: "left"
    },
    text: {
        lineHeight: "1.5em",
        fontSize: "1.125rem",
        marginVertical: "0.5em",
        textAlign: "left"
    },
    link: {
        color: "#1B95E0"
    },
    code: {
        fontFamily: "monospace, monospace"
    },
    divider: {
        width: 10
    },
    image: {
        borderColor: 'white',
        borderWidth: 0.5,
        height: 250,
        width: 250,
        resizeMode: 'cover',
        align: 'center',
        marginVertical: "3rem"
    }
});