import {extendTheme} from "native-base";

/**
 * https://docs.nativebase.io/customizing-components
 */

export const RoundedButtonTheme = extendTheme({
    components: {
        Button: {
            variants: {
                rounded: ({
                              colorScheme
                          }) => {
                    return {
                        bg: `${colorScheme}.500`,
                        rounded: "full"
                    };
                }
            }
        }
    }
});