import { COUNTRIES } from "./region-country-constants"

const US_SITES_LIST = [
    'ABE2', 'ABE3', 'ABE4', 'ABE5', 'ABE8', 'ABQ1', 'ABQ2', 'ABQ5', 'ABS4', 'ACY1',
    'ACY2', 'ACY5', 'ACY8', 'ACY9', 'AFW1', 'AFW2', 'AFW5', 'AGS1', 'AGS2', 'AGS3',
    'AGS5', 'AIR5', 'AKC1', 'AKR1', 'ALB1', 'AMA1', 'ATL2', 'ATL6', 'ATL7', 'AUN2',
    'AUS2', 'AUS3', 'AUS5', 'AVP1', 'AVP3', 'AVP8', 'AVP9', 'AZA2', 'AZA4', 'AZA5',
    'AZA9', 'BDL2', 'BDL3', 'BDL4', 'BDL5', 'BDL6', 'BDL7', 'BDU2', 'BDU5', 'BFI1',
    'BFI3', 'BFI4', 'BFI5', 'BFI7', 'BFI8', 'BFI9', 'BFL1', 'BFL2', 'BHM1', 'BLV2',
    'BNA2', 'BNA3', 'BNA5', 'BNA6', 'BNA7', 'BNA8', 'BNA9', 'BOI2', 'BOI5', 'BOS3',
    'BOS4', 'BOS5', 'BOS7', 'BTR1', 'BTR9', 'BUF5', 'BUF9', 'BUR7', 'BWI1', 'BWI2',
    'BWI4', 'BWI5', 'CAE1', 'CAE3', 'CAK4', 'CDW5', 'CHA1', 'CHA2', 'CHO1', 'CLE2',
    'CLE3', 'CLE5', 'CLT2', 'CLT3', 'CLT4', 'CLT5', 'CLT6', 'CLT9', 'CMH1', 'CMH2',
    'CMH3', 'CMH4', 'CMH5', 'CMH6', 'CMH7', 'CNO5', 'COS1', 'COS5', 'CSG1', 'CVG19',
    'CVG2', 'CVG5', 'CVG9', 'DAB2', 'DAB4', 'DAB5', 'DAB8', 'DAE1', 'DAE3', 'DAE7',
    'DAL2', 'DAL3', 'DAL9', 'DAS7', 'DAS8', 'DAS9', 'DAT6', 'DAT9', 'DAU1', 'DAU2',
    'DAU5', 'DAU7', 'DAX3', 'DAX5', 'DAX7', 'DAX8', 'DAZ2', 'DAZ4', 'DBA5', 'DBA7',
    'DBA8', 'DBC3', 'DBK1', 'DBK4', 'DBK6', 'DBL1', 'DBL8', 'DBM3', 'DBM5', 'DBO6',
    'DBO7', 'DBO9', 'DBU1', 'DBU2', 'DBU3', 'DBU7', 'DBU9', 'DCA1', 'DCA6', 'DCB4',
    'DCB8', 'DCD6', 'DCH6', 'DCH8', 'DCK1', 'DCK6', 'DCL2', 'DCL3', 'DCL4', 'DCL5',
    'DCL7', 'DCL9', 'DCM2', 'DCM3', 'DCM5', 'DCM6', 'DCN2', 'DCS3', 'DCW8', 'DCX2',
    'DCX5', 'DCX7', 'DCX8', 'DCY1', 'DCY9', 'DDA8', 'DDA9', 'DDC2', 'DDC3', 'DDC4',
    'DDC9', 'DDE8', 'DDE9', 'DDF1', 'DDF2', 'DDF4', 'DDF5', 'DDO6', 'DDP1', 'DDP3',
    'DDP5', 'DDP7', 'DDP9', 'DDT1', 'DDT3', 'DDT4', 'DDT6', 'DDT9', 'DDV2', 'DDV3',
    'DDV4', 'DDV5', 'DDW1', 'DDW7', 'DDX2', 'DDX6', 'DDX7', 'DEB2', 'DEN2', 'DEN3',
    'DEN4', 'DEN5', 'DEN7', 'DEN8', 'DET1', 'DET2', 'DET3', 'DET6', 'DET7', 'DEW5',
    'DFA5', 'DFH1', 'DFH3', 'DFH7', 'DFL3', 'DFL4', 'DFL5', 'DFL7', 'DFL8', 'DFM3',
    'DFM4', 'DFM5', 'DFO2', 'DFO3', 'DFO9', 'DFT4', 'DFW5', 'DFW6', 'DFW7', 'DFW9',
    'DFX4', 'DFX9', 'DGE4', 'DGE7', 'DGE9', 'DGI3', 'DGR3', 'DGR6', 'DGR8', 'DGT2',
    'DGT8', 'DHI2', 'DHO3', 'DHO7', 'DHO8', 'DHT1', 'DHT4', 'DHX1', 'DHX3', 'DHX4',
    'DIA3', 'DIA4', 'DIA5', 'DIB5', 'DIB6', 'DIB7', 'DID2', 'DID3', 'DII3', 'DII4',
    'DIL3', 'DIL5', 'DIL7', 'DIN3', 'DIN4', 'DIN6', 'DIN8', 'DJC5', 'DJE1', 'DJE2',
    'DJE3', 'DJE5', 'DJE9', 'DJR1', 'DJR3', 'DJR5', 'DJT6', 'DJW8', 'DJX1', 'DJX2',
    'DJX3', 'DJX4', 'DJZ2', 'DJZ3', 'DJZ4', 'DJZ5', 'DJZ6', 'DJZ8', 'DKC3', 'DKO1',
    'DKO9', 'DKS3', 'DKY4', 'DKY5', 'DKY6', 'DKY8', 'DKY9', 'DLA8', 'DLB2', 'DLD1',
    'DLD7', 'DLF1', 'DLI3', 'DLI4', 'DLI5', 'DLI6', 'DLI8', 'DLI9', 'DLN2', 'DLN3',
    'DLN4', 'DLN8', 'DLR2', 'DLT2', 'DLT3', 'DLT6', 'DLT7', 'DLT8', 'DLV2', 'DLV3',
    'DLV4', 'DLV7', 'DLX5', 'DLX7', 'DLX8', 'DLX9', 'DMC3', 'DMC4', 'DMD2', 'DMD4',
    'DMD5', 'DMD6', 'DMD8', 'DMD9', 'DMF1', 'DMF3', 'DMF5', 'DMF8', 'DMH4', 'DMH9',
    'DMI6', 'DMI7', 'DMI9', 'DML2', 'DML3', 'DML4', 'DML8', 'DMO3', 'DMO4', 'DMO6',
    'DMP1', 'DMS2', 'DMS6', 'DNA4', 'DNA6', 'DNH2', 'DNJ2', 'DNJ3', 'DNJ4', 'DNJ7',
    'DNK2', 'DNK5', 'DNK7', 'DNO2', 'DOB2', 'DOB4', 'DOB7', 'DOK2', 'DOK3', 'DOK4',
    'DOK6', 'DOM2', 'DOR1', 'DOR2', 'DOR3', 'DOT4', 'DPA7', 'DPD2', 'DPD4', 'DPD6',
    'DPD7', 'DPD8', 'DPH7', 'DPH8', 'DPH9', 'DPL2', 'DPL7', 'DPP1', 'DPP7', 'DPS1',
    'DPS2', 'DPS5', 'DPS6', 'DPX4', 'DPX7', 'DPX8', 'DRC6', 'DRI1', 'DRO2', 'DRT3',
    'DRT4', 'DRT7', 'DRT8', 'DRT9', 'DSC2', 'DSC3', 'DSC4', 'DSD1', 'DSD3', 'DSD4',
    'DSD5', 'DSD8', 'DSE8', 'DSF5', 'DSF7', 'DSF8', 'DSJ5', 'DSJ9', 'DSK4', 'DSM4',
    'DSM5', 'DSM9', 'DSR2', 'DSR4', 'DSR6', 'DSW3', 'DSW5', 'DSX5', 'DSX8', 'DSX9',
    'DTB2', 'DTB4', 'DTB9', 'DTG5', 'DTN6', 'DTN7', 'DTN8', 'DTP3', 'DTP7', 'DTP9',
    'DTU2', 'DTU3', 'DTU6', 'DTU7', 'DTU8', 'DTU9', 'DTW1', 'DTW3', 'DTW5', 'DTW8',
    'DTW9', 'DUR1', 'DUR3', 'DUR9', 'DUT2', 'DUT3', 'DUT4', 'DUT7', 'DVA2', 'DVA3',
    'DVA5', 'DVB4', 'DVB5', 'DVB7', 'DVB8', 'DWA2', 'DWA5', 'DWA6', 'DWA7', 'DWA9',
    'DWD6', 'DWI4', 'DWO1', 'DWO6', 'DWS4', 'DXC3', 'DXC5', 'DXC8', 'DXH5', 'DXH6',
    'DXY4', 'DYN3', 'DYN5', 'DYN7', 'DYN9', 'DYO1', 'DYO2', 'DYO5', 'DYR3', 'DYR7',
    'DYY3', 'DYY4', 'DYY5', 'DYY6', 'DYY8', 'DYY9', 'ELP1', 'EUG5', 'EWR4', 'EWR5',
    'EWR7', 'EWR8', 'EWR9', 'FAR1', 'FAT1', 'FAT2', 'FOE1', 'FSD1', 'FTW1', 'FTW2',
    'FTW3', 'FTW4', 'FTW5', 'FTW6', 'FTW7', 'FTW8', 'FTW9', 'FTY9', 'FWA4', 'FWA6',
    'GEG1', 'GEG2', 'GEU2', 'GEU3', 'GEU5', 'GRR1', 'GSO1', 'GSP1', 'GYR1', 'GYR2',
    'GYR3', 'GYR4', 'HAL1', 'HAT2', 'HAT9', 'HAU1', 'HAU2', 'HBA2', 'HBA3', 'HBA9',
    'HBD1', 'HBF2', 'HBF4', 'HBF5', 'HBF9', 'HBI1', 'HBM3', 'HBN2', 'HBN9', 'HBO1',
    'HBO2', 'HCE2', 'HCH2', 'HCH3', 'HCI1', 'HCL2', 'HCM1', 'HCN1', 'HCO1', 'HCT2',
    'HDA1', 'HDA3', 'HDA8', 'HDA9', 'HDC1', 'HDE2', 'HDS1', 'HDS9', 'HDT2', 'HDT3',
    'HDT9', 'HDY1', 'HDY9', 'HEA2', 'HEU1', 'HEW2', 'HEW4', 'HEW9', 'HFA2', 'HFD5',
    'HGE1', 'HGR1', 'HGR2', 'HGR5', 'HGR6', 'HHO2', 'HHO9', 'HIA1', 'HIN2', 'HIN3',
    'HIN9', 'HIO2', 'HIO9', 'HJX1', 'HKX1', 'HLA1', 'HLA2', 'HLA4', 'HLA8', 'HLA9',
    'HLI1', 'HLI2', 'HLO3', 'HLO9', 'HLU2', 'HLU9', 'HLV1', 'HLX1', 'HMC3', 'HMD3',
    'HME3', 'HME9', 'HMI2', 'HMK2', 'HMK3', 'HMK4', 'HMK9', 'HMO2', 'HMS2', 'HMS9',
    'HMW3', 'HMW4', 'HMY1', 'HNW1', 'HNY1', 'HNY2', 'HNY3', 'HNY5', 'HOK2', 'HOM1',
    'HOU1', 'HOU2', 'HOU3', 'HOU5', 'HOU6', 'HOU7', 'HOU8', 'HPB2', 'HPD1', 'HPH2',
    'HPH4', 'HPS1', 'HPT1', 'HPX2', 'HPX3', 'HPX9', 'HRC1', 'HRC9', 'HRD2', 'HRN1',
    'HRO1', 'HRS1', 'HSA1', 'HSD2', 'HSF2', 'HSF3', 'HSF5', 'HSF9', 'HSL1', 'HSL9',
    'HSM1', 'HSV1', 'HSV2', 'HSX2', 'HSY1', 'HTC1', 'HTC2', 'HTP1', 'HTP9', 'HVB2',
    'HVP1', 'IAH1', 'IAH3', 'IAH5', 'ICT2', 'IGQ1', 'IGQ2', 'ILG1', 'IND1', 'IND2',
    'IND3', 'IND4', 'IND5', 'IND8', 'IND9', 'JAN1', 'JAX2', 'JAX3', 'JAX5', 'JAX7',
    'JAX9', 'JFK8', 'JHW1', 'JVL1', 'KAF5', 'KAFW', 'KBWI', 'KCVG', 'KILN', 'KLAL',
    'KRB1', 'KRB2', 'KRB3', 'KRB4', 'KRB5', 'KRB6', 'KRB7', 'KRB9', 'KRFD', 'KRIV',
    'KSBD', 'LAL4', 'LAN2', 'LAS1', 'LAS2', 'LAS5', 'LAS6', 'LAS7', 'LAS8', 'LAS9',
    'LAX5', 'LAX9', 'LBB5', 'LBE1', 'LDJ5', 'LEX1', 'LEX2', 'LEX5', 'LFT1', 'LGA5',
    'LGA9', 'LGB3', 'LGB4', 'LGB5', 'LGB6', 'LGB7', 'LGB8', 'LGB9', 'LIT1', 'LIT2',
    'LNJ2', 'LPR5', 'LUK2', 'LUK7', 'MCE1', 'MCI3', 'MCI5', 'MCI7', 'MCI9', 'MCO1',
    'MCO2', 'MCO3', 'MCO4', 'MCO5', 'MCO6', 'MCO9', 'MDT1', 'MDT2', 'MDT4', 'MDT5',
    'MDT8', 'MDT9', 'MDW2', 'MDW4', 'MDW5', 'MDW6', 'MDW7', 'MDW8', 'MDW9', 'MEM1',
    'MEM2', 'MEM3', 'MEM4', 'MEM5', 'MEM6', 'MEM8', 'MGE1', 'MGE3', 'MGE5', 'MGE8',
    'MGE9', 'MIA1', 'MIA2', 'MIA5', 'MIA7', 'MIT2', 'MKC4', 'MKC6', 'MKE1', 'MKE2',
    'MKE5', 'MKE6', 'MLI1', 'MMU9', 'MOB5', 'MQJ1', 'MQJ2', 'MQJ5', 'MQJ9', 'MQY1',
    'MSN4', 'MSN5', 'MSP1', 'MSP6', 'MSP7', 'MSP8', 'MSP9', 'MTN1', 'MTN2', 'MTN3',
    'MTN5', 'MTN6', 'MTN7', 'MTN9', 'OAK3', 'OAK4', 'OAK5', 'OAK7', 'OAK9', 'OKC1',
    'OKC2', 'OKC5', 'OKC9', 'OLM1', 'OMA2', 'OMA5', 'ONT1', 'ONT2', 'ONT5', 'ONT6',
    'ONT8', 'ONT9', 'ORD2', 'ORD4', 'ORD5', 'ORD9', 'ORF2', 'ORF3', 'ORH3', 'ORH5',
    'OWD5', 'OWD9', 'OXR1', 'PAE2', 'PBI2', 'PBI3', 'PCA1', 'PCW1', 'PDK2', 'PDX5',
    'PDX6', 'PDX7', 'PDX8', 'PDX9', 'PGA1', 'PHL1', 'PHL4', 'PHL5', 'PHL6', 'PHL7',
    'PHL9', 'PHX3', 'PHX5', 'PHX6', 'PHX7', 'PIT2', 'PIT4', 'PIT5', 'PIT9', 'PNE5',
    'POC3', 'POH2', 'PSC2', 'PSP1', 'PVD2', 'QAZ2', 'QXY8', 'QXY9', 'RAD1', 'RBD5',
    'RDG1', 'RDU1', 'RDU2', 'RDU4', 'RDU5', 'RDU9', 'RFD1', 'RFD2', 'RFD3', 'RFD4',
    'RFD7', 'RIC1', 'RIC2', 'RIC3', 'RIC4', 'RIC5', 'RIC9', 'RMN3', 'RNO4', 'RNT9',
    'ROC1', 'RSW5', 'RYY2', 'SAC5', 'SAN3', 'SAN5', 'SAT1', 'SAT2', 'SAT3', 'SAT3',
    'SAT4', 'SAT5', 'SAT6', 'SAT7', 'SAT9', 'SAV3', 'SAV4', 'SAV7', 'SAX1', 'SAX2',
    'SAX3', 'SAZ1', 'SAZ2', 'SBD1', 'SBD2', 'SBD3', 'SBD6', 'SBW1', 'SCA3', 'SCA4',
    'SCA5', 'SCA7', 'SCK1', 'SCK3', 'SCK4', 'SCK6', 'SCK8', 'SCK9', 'SCO1', 'SDC1',
    'SDF1', 'SDF2', 'SDF4', 'SDF6', 'SDF8', 'SDF9', 'SDL2', 'SDM4', 'SFL1', 'SFL2',
    'SFL3', 'SFL4', 'SFL6', 'SFL7', 'SFL8', 'SGA1', 'SGA2', 'SHV1', 'SIL1', 'SIL2',
    'SIL3', 'SIL4', 'SIN9', 'SJC7', 'SLC1', 'SLC2', 'SLC3', 'SLC4', 'SLC9', 'SMA1',
    'SMA2', 'SMD1', 'SMF1', 'SMF3', 'SMF5', 'SMF6', 'SMF7', 'SMF9', 'SMI1', 'SMN1',
    'SMO1', 'SMO2', 'SNA4', 'SNC2', 'SNC3', 'SNJ1', 'SNJ2', 'SNJ3', 'SNV1', 'SNY2',
    'SNY5', 'SOH1', 'SOH2', 'SOH3', 'SOR3', 'STL3', 'STL4', 'STL5', 'STL6', 'STL8',
    'STL9', 'STN1', 'STP2', 'STX2', 'STX3', 'STX4', 'STX5', 'STX6', 'STX7', 'STX8',
    'STX9', 'SUT1', 'SWA1', 'SWA2', 'SWF1', 'SWF2', 'SWI1', 'SYR1', 'TCY2', 'TCY5',
    'TCY9', 'TEB3', 'TEB4', 'TEB6', 'TEB9', 'TEN1', 'TLH2', 'TMB8', 'TPA1', 'TPA2',
    'TPA3', 'TPA4', 'TPA6', 'TTN2', 'TUL2', 'TUL5', 'TUS1', 'TUS2', 'TUS5', 'TYS1',
    'TYS5', 'VGT1', 'VGT2', 'VGT5', 'WBW2', 'WID1', 'WQQ1', 'XCA2', 'XCL1', 'XLX1',
    'XLX3', 'XLX6', 'XSF1', 'YYC4'
]

const CANADA_SITES_LIST = [
    'DCG4', 'DLC1', 'DLC4', 'DMW2', 'DOI2', 'DOI3', 'DOI5', 'DOI6', 'DON3', 'DON8',
    'DON9', 'DTO1', 'DTO3', 'DTO5', 'DTY4', 'DTY7', 'DVC4', 'DVC6', 'DVV2', 'DVV5',
    'DVY2', 'DVY7', 'DXT2', 'DXT8', 'DYB2', 'DYB3', 'DYH1', 'DYT6', 'DYV1', 'HYC1',
    'HYE1', 'HYV1', 'HYZ1', 'HYZ2', 'YEG1', 'YEG2', 'YEG4', 'YGK1', 'YHM1', 'YHM5',
    'YHM6', 'YHM9', 'YOO1', 'YOW1', 'YOW3', 'YVR2', 'YVR3', 'YVR4', 'YVR7', 'YXU1',
    'YXX2', 'YYC1', 'YYC5', 'YYC6', 'YYZ1', 'YYZ2', 'YYZ3', 'YYZ4', 'YYZ7', 'YYZ8',
    'YYZ9']

const UK_SITES_LIST = [
    'BHX1', 'BHX10', 'BHX2', 'BHX3', 'BHX4', 'BHX5', 'BHX7', 'BRS1', 'BRS2', 'CWL1',
    'DAB1', 'DBH3', 'DBI2', 'DBI4', 'DBI5', 'DBI7', 'DBN2', 'DBR1', 'DBR2', 'DBS2',
    'DBS3', 'DBT3', 'DBT4', 'DCE1', 'DCF1', 'DCR2', 'DCR3', 'DDD1', 'DDN1', 'DEH1',
    'DEX2', 'DHA1', 'DHA2', 'DHP3', 'DHU2', 'DHW1', 'DIG1', 'DIP1', 'DLE2', 'DLS2',
    'DLS4', 'DLU2', 'DME4', 'DNE2', 'DNG1', 'DNG2', 'DNN1', 'DNR1', 'DOX2', 'DPE2',
    'DPN1', 'DPO1', 'DPR1', 'DRG2', 'DRH1', 'DRM2', 'DRM4', 'DRM5', 'DRR1', 'DSA1',
    'DSA6', 'DSA7', 'DSN1', 'DSO2', 'DSS2', 'DST1', 'DWN2', 'DWR1', 'DXE1', 'DXG1',
    'DXG2', 'DXM2', 'DXM3', 'DXM4', 'DXM5', 'DXN1', 'DXP1', 'DXS1', 'DXW2', 'DXW3',
    'EDI4', 'EMA1', 'EMA2', 'EMA3', 'EMA4', 'EUK5', 'HBS2', 'HEH1', 'HIG1', 'HIG3',
    'HOX2', 'HRM2', 'HSA7', 'HST1', 'HTN7', 'HXM1', 'HXW3', 'LBA2', 'LBA4', 'LBA7',
    'LCY2', 'LCY3', 'LPL2', 'LTN1', 'LTN4', 'LTN5', 'LTN7', 'LTN9', 'MAN1', 'MAN2',
    'MAN3', 'MAN4', 'MME1', 'MME2', 'NCL1', 'NCL2', 'UKK1', 'XUKS']

const IN_SITES_LIST = [
    'AGRD', 'AMD2', 'AMDE', 'AMDF', 'ATQD', 'ATX1', 'BBIF', 'BDQE', 'BDQF', 'BHOF',
    'BHOG', 'BHOH', 'BLR4', 'BLR5', 'BLR7', 'BLR8', 'BLRA', 'BLRB', 'BLRG', 'BLRL',
    'BLRM', 'BLRP', 'BLRR', 'BLT1', 'BLT2', 'BLT3', 'BLT4', 'BLUA', 'BOM4', 'BOM5',
    'BOM7', 'BOMC', 'BOME', 'BOMJ', 'BOMK', 'BOML', 'BOMN', 'BOMP', 'BOMS', 'BOMU',
    'BOT2', 'BOT3', 'BRUB', 'BRUC', 'BRUD', 'BWND', 'CCT1', 'CCU1', 'CCUQ', 'CJB1',
    'DED3', 'DED5', 'DEL2', 'DEL4', 'DEL5', 'DEL8', 'DELC', 'DELF', 'DELG', 'DELH',
    'DELI', 'DELK', 'DELL', 'DELN', 'DELO', 'DELR', 'DELT', 'DEX3', 'DLIH', 'EDEJ',
    'FADA', 'FADG', 'FAPD', 'FAPG', 'FBLA', 'FBLB', 'FBLC', 'FBLD', 'FCHD', 'FDLG',
    'FDLH', 'FDLI', 'FMHD', 'FMHI', 'FPBD', 'FRJD', 'FTND', 'FTSD', 'FUPD', 'GAUA',
    'GAUK', 'GDVE', 'GKPL', 'GNNJ', 'GNNM', 'GNNT', 'GNTD', 'GNTJ', 'GOIK', 'GTLK',
    'GYMJ', 'HBAD', 'HBAI', 'HBAL', 'HBAN', 'HBBB', 'HBBD', 'HBBE', 'HBBU', 'HBCD',
    'HBCE', 'HBCH', 'HBDA', 'HBDE', 'HBDH', 'HBGA', 'HBGW', 'HBHE', 'HBID', 'HBIX',
    'HBJD', 'HBJL', 'HBKD', 'HBKE', 'HBKG', 'HBKL', 'HBKM', 'HBKR', 'HBKT', 'HBLM',
    'HBMA', 'HBMB', 'HBMD', 'HBMJ', 'HBMN', 'HBNL', 'HBNZ', 'HBPD', 'HBPG', 'HBPK',
    'HBRA', 'HBSR', 'HBSS', 'HBST', 'HBTA', 'HBUD', 'HBUE', 'HBWD', 'HBWO', 'HCBA',
    'HDLB', 'HGYE', 'HHDA', 'HHDC', 'HISE', 'HJAA', 'HJAB', 'HLKO', 'HMAA', 'HRWA',
    'HWHA', 'HXCH', 'HYBK', 'HYD3', 'HYD8', 'HYDD', 'HYDE', 'HYDH', 'HYDJ', 'HYDK',
    'HYDL', 'HYDP', 'HYT2', 'HYT3', 'HYX1', 'IDRB', 'ISK3', 'ISKE', 'ISKK', 'IXCD',
    'IXCE', 'IXCF', 'IXDD', 'IXDE', 'IXJH', 'IXME', 'IXRJ', 'IXWA', 'JAIE', 'JAIH',
    'JAIR', 'JDHD', 'JHNH', 'JPX1', 'JULD', 'KNUD', 'KNUE', 'KNUO', 'KOLE', 'KRMF',
    'KURD', 'LKO1', 'LKOA', 'LKOD', 'LKOI', 'LUHD', 'LUHF', 'MAA4', 'MAAE', 'MAAG',
    'MAAI', 'MAAJ', 'MAAK', 'MAAL', 'MANH', 'MAT1', 'MHPN', 'MHPQ', 'MMCA', 'MMCE',
    'MPMH', 'MREE', 'MREH', 'MUME', 'MUMQ', 'MUMR', 'MUMV', 'MUMZ', 'MYQD', 'MYQF',
    'NAG1', 'NAGF', 'NAGG', 'NAGL', 'NCRF', 'NCRG', 'NCRJ', 'NCT2', 'NCT3', 'NCT8',
    'NCTC', 'NCTD', 'NCTG', 'NLRJ', 'NZMF', 'NZML', 'NZMM', 'NZMN', 'PATD', 'PNPE',
    'PNQA', 'PNQJ', 'PNQK', 'PNQL', 'PNT1', 'PNYF', 'PNYH', 'PTJD', 'RAJE', 'RDPA',
    'RMUA', 'ROHD', 'RPRC', 'RPRH', 'SBLB', 'SDEF', 'SLIG', 'STVC', 'STVI', 'STVR',
    'SXVE', 'TCRF', 'TLAJ', 'TRVF', 'TRZF', 'VDDE', 'VGAE', 'VGAH', 'VLRH', 'VNSD',
    'VTZE', 'VTZH', 'WARJ'
]

const AE_SITE_LIST = [
    'DAD6', 'DDB3', 'DDB6', 'DDB7', 'DWC3', 'DXB3', 'DXB5'
]

const EG_SITE_LIST = [
    'CAI6', 'DAI3', 'DAI4', 'DEC8', 'DEX5', 'DGI7', 'DTT4', 'SPX5'
]

const SA_SITE_LIST = [
    'DAK1', 'DHU3', 'DJD1', 'DME6', 'DMK2', 'DMM2', 'DQS2', 'DRY3', 'JED3', 'RUH8'
]

export const COUNTRY_TO_SITE_CODES_MAP = {
    [COUNTRIES.US.value]: getSiteListDropdown(US_SITES_LIST),
    [COUNTRIES.CA.value]: getSiteListDropdown(CANADA_SITES_LIST),
    [COUNTRIES.UK.value]: getSiteListDropdown(UK_SITES_LIST),
    [COUNTRIES.IN.value]: getSiteListDropdown(IN_SITES_LIST),
    [COUNTRIES.AE.value]: getSiteListDropdown(AE_SITE_LIST),
    [COUNTRIES.EG.value]: getSiteListDropdown(EG_SITE_LIST),
    [COUNTRIES.SA.value]: getSiteListDropdown(SA_SITE_LIST)
}

function getSiteListDropdown(siteList) {
    const sortedUniqueSiteList = [...new Set(siteList)].sort()
    const siteListDropdown = sortedUniqueSiteList.map(siteCode => ({"id" : siteCode, "value" : siteCode}))
    return siteListDropdown;
}