export const PROFILE_COUNTRY = "profileCountry"
export const PROFILE_SITE_CODE = "profileSiteCode"
export const PROFILE_JOB_ASSESSMENT = "profileJobType"
export const PROFILE_LSE_EVENT = "profileLseEvent"
export const PROFILE_ORG = "profileOrgName"
export const PROFILE_CONTRACTOR = "contractorName"
export const ALIAS = "alias"
export const TEXT_COMPONENT_ATTRIBUTE_NAME_KEY =  'contractorName'
export const AVETTA_COMPLIANCE_STATUS_CODE = 'avettaComplianceStatusCode'
export const AVETTA_COMPLIANCE_CHECK_KEY = 'avettaCompliance'
export const AVETTA_COMPLIANCE_CHECK_MESSAGE = 'avettaComplianceMsg'
export const AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS = 'avettaComplianceMsgStatus'
export const AVETTA_COMPLIANCE_CPOC_NEXT_STEPS = 'avettaComplianceCpocNextSteps'
