import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {TextInput} from "../FormInput/TextInput";
import {VStack, Center, Heading, Button} from "native-base";
import {SpinnerComponent} from "../SpinnerComponent/spinner";


export function SignInOTP({email, cognitoUser, setCognitoUser, setAlert, setFormState}) {
    const [otp, setOTP] = useState('')
    const [otpError, setOTPError] = useState('')
    const [validatingOTP, setValidatingOTP] = useState(false)
    const [resendingOTP, setResendingOTP] = useState(false)

    /**
     * Validates the OTP and Signs in user 
     */
    async function validateAndSignIn() {
        setAlert(undefined)
        if (!otp) {
            setOTPError('Please provide one time passcode')
            return;
        }
        if (otp.length > 8) {
            setOTPError('One time passcode cannot be more than 8 digits')
            return;
        }
        setOTPError('')
        setValidatingOTP(true)
        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
            try {
                await Auth.sendCustomChallengeAnswer(cognitoUser, otp)
            } catch(err) {
                console.error('OTP expired', err)
                setOTPError('OTP expired! Please try again')
                setValidatingOTP(false)
                return;
            }
            try {
                await Auth.currentSession();
                setValidatingOTP(false)
                setFormState("signedIn")
            } catch(err) {
                console.error('User did not enter the right code', err)
                setOTPError('Wrong one time passcode provided! Please try again')
                setValidatingOTP(false)
            }
        } else {
            console.error("OTP Validation error: " + JSON.stringify(cognitoUser));
        }
    }

    /**
     * Resends OTP to the Chime or Slack channel
     */
    async function resendOTP() {
        setAlert(undefined)
        setResendingOTP(true)
        setOTP('')
        setOTPError('')
        try {
            await Auth.signIn(email.toLowerCase()).then(async (user) => {
                setCognitoUser(user)
                if (user.challengeName === 'CUSTOM_CHALLENGE') {
                    setResendingOTP(false)
                    setAlert({
                        header: 'OTP Resent Success',
                        body: 'One time passcode sent again! Please check your Chime or Slack channel',
                        status: 'success'
                    })
                }
            });
        } catch (err) { 
            console.error('Error encountered while signing in user', err)
            setAlert({
                header: 'OTP Resent Failure',
                body: 'Error resending one time passcode! Please try again',
                status: 'error'
            })
        }
    }

    return (
        <VStack space={3} marginTop={'1rem'}>
            <Center><Heading size="md">Sign In OTP</Heading></Center>
            <TextInput value={otp} errorText= {otpError} onChangeHandler = {setOTP} placeholder='Enter one time passcode'/>
            {
                validatingOTP &&
                <SpinnerComponent text = {`Validating OTP...`} />
            }
            {
                resendingOTP &&
                <SpinnerComponent text = {`Resending OTP...`} />
            }
            <VStack space={3} marginTop={"2rem"}>
                <Button colorScheme="lightBlue" variant="rounded" 
                    onPress={validateAndSignIn}>
                    Validate & Sign In
                </Button>
                <Button colorScheme="lightBlue" variant="rounded" 
                    onPress={resendOTP}>
                    Resend
                </Button>
            </VStack>
        </VStack>
    )
}