import React from "react";
import {Box, Button, Center, Divider, FormControl, NativeBaseProvider, View} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Info from "../components/Info/info";

function CheckInFailure(props) {
    return (
        <View marginTop={"2rem"}>
            <Center>
                <Info heading= 'This supplier should not be checked in!'
                      content={`The supplier cannot be allowed at site due to non-compliance. ` +
                          `Please follow next steps!`}
                      status='error'
                      showIcon={ true }
                />
            </Center>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Center>
                    <Button marginTop={"1rem"}
                            minW={300}
                            colorScheme="lightBlue"
                            onPress= {() => {
                                //props.clearCheckInForm()
                                props.checkInPageChanger(6)
                            }}
                            variant="rounded"> Show next steps
                    </Button>
                </Center>
            </NativeBaseProvider>
        </View>
    )
}

export default withAuthenticator(CheckInFailure)