import {withAuthenticator} from "@aws-amplify/ui-react";
import {View} from "react-native-web";
import {styles} from "../styles/style";
import {Box, Button, Center, Input, NativeBaseProvider, Progress, Text, VStack} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import React from "react";
import {useState, useEffect} from "react";
import {Auth} from "aws-amplify";
import {getRandomString} from "../constants/random-string";

function YellowBadgeSetup(props) {

    const[yellowBadgeFormInputState, setYellowBadgeFormInputState] = useState({});
    const [loggedInUser, setLoggedInUser] = useState()

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((data) => {
            if (loggedInUser === undefined) {
                let emailAddress = data.attributes.email
                let index = emailAddress.indexOf('@')
                let loggedUserAlias = emailAddress.slice(0,index)
                setLoggedInUser(loggedUserAlias)
            }
        });
    }, [loggedInUser])

    const handleAliasChange = text => {
        setYellowBadgeFormInputState((prevState) => {
            return {
                ...prevState,
                emailError: false,
                signInCallComplete: undefined
            }
        });
        setYellowBadgeFormInputState((prevState) => {
            return {
                ...prevState, alias: text
            }
        })
    }
    async function addUser() {
        try {
            if (yellowBadgeFormInputState.alias === undefined || yellowBadgeFormInputState.alias === '') {
                setYellowBadgeFormInputState((prevState) => {
                    return {
                        ...prevState,
                        aliasError: true,
                    }
                });
                return;
            }
            setYellowBadgeFormInputState((prevState) => {
                return {
                    ...prevState,
                    addUserCallComplete: false,
                    addUserMessageHeader: '',
                    addUserIcon: '',
                    addUserMessageBody: '',
                    aliasError: false,
                }
            });

            await Auth.signUp({
                username: yellowBadgeFormInputState.alias.toLowerCase() + '@amazon.com',
                password: getRandomString(30), // generate a password for the customer
                attributes: {
                    'custom:badgeType': 'yellow',
                    'custom:addedBy':loggedInUser
                },
            });
            setYellowBadgeFormInputState((prevState) => {
                return {
                    ...prevState,
                    signUpCallComplete: true,
                    signUpMessageHeader: 'Sign up Successful',
                    signUpIcon: 'success',
                    signUpMessageBody: 'Yellow badge employee can do self Check in now!'
                }
            });
        } catch (err) {
            console.log({ err });
        }
    }
    return (
        <View style={styles.header}>
            <Box>
                <VStack>
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        <Input
                            name="alias"
                            onChangeText={handleAliasChange}
                            variant = "underlined"
                            minWidth={300}
                            placeholder={'Alias'}
                            marginTop={'1rem'}
                            value={yellowBadgeFormInputState.alias}
                        />
                        <Button marginTop={"2rem"}
                                colorScheme="lightBlue"
                                variant={"rounded"}
                                onPress={addUser}
                                minWidth={100}>Add User</Button>
                    </NativeBaseProvider>
                </VStack>
            </Box>
        </View>
    );
}

export default withAuthenticator(YellowBadgeSetup)