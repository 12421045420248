import React, {useState, useEffect} from "react";
import {Box, Button, Center, Divider, Flex, HStack, NativeBaseProvider, Text, View, VStack} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Label from "../components/Label/label";
import {CHECKIN_PAGE_INDEX_START_INCLUSIVE, CHECKIN_NEXT_STEPS} from "../utils/constants";
import AlertDialog from "../components/AlertDialog/alert-dialog";
import submitDataAndUpdateThePage from "../submitData";
import {Auth} from "aws-amplify";

function ReviewCheckIn(props) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    /**
     * This labels maps keeps the translation of
     * various keys to user friendly label names.
     */
    const [labelsMap, setLabelsMap] = useState({
        suppliernameslist : 'Supplier Representative(s)',
        alias : 'Amazon Alias',
        jobriskassessment: 'Job Assessment',
        country: 'Country',
        sitecode: 'Site Code',
        contractorname: 'Supplier/Contractor/Vendor',
        avettacompliance: 'Avetta Compliance Status',
        daysonsite: 'Day(s) on site',
        orgname: 'Org',
        lseevent: 'Event',
        pocheck: 'Valid PO Number?',
        poNumber: 'PO Number'
    });
    // do not use until I fix it.
    const [intentToSubmit, setIntentToSubmit] = useState(false)
    // uncomment only for local testing
    // useEffect(()=> {
    //     console.log("Page 4-> state of the form Now is " + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])
    //
    // useEffect(()=> {
    //     console.log("Page 4-> state of  lse radio " + JSON.stringify(props.lseRadioData))
    //         console.log("Page 4-> state of cat radio " + JSON.stringify(props.catRadioData))
    //         console.log("Page 4-> state of orientation radio " + JSON.stringify(props.orientationRadioData))
    //         console.log("Page 4-> state of jha radio " + JSON.stringify(props.jhaRadioData))
    //         console.log("Page 4-> state of po check radio " + JSON.stringify(props.poCheckRadioData))
    // }, [
    //     props.lseRadioData,
    //     props.catRadioData,
    //     props.orientationRadioData,
    //     props.jhaRadioData,
    //     props.poCheckRadioData
    //     ]
    // )

    // uncomment only for testing
    useEffect(()=> {
        console.log("Review CheckIn Data" + JSON.stringify(props.checkInFormData))
    }, [props.checkInFormData])

    /**
     * Stub to submit the data
     */
    const submitCheckInData = () => {
       setButtonDisabled(true)
       submitDataAndUpdateThePage(props, CHECKIN_NEXT_STEPS)
    }
    return (
        <View minW={300} maxW={300} style={{
            flexDirection: "column",
            padding: 1
        }}>
            <Center>
                <Label labelText = "Review Check-In Details" ></Label>
            </Center>

            <VStack marginTop={'1rem'}>
                {
                    Object.keys(props.checkInFormData).map(key => (
                            <Box>
                                    { labelsMap[key.toLowerCase()] !== undefined && key != 'poNumber' &&
                                        <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                            <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                                <Text style={{ padding: '0.3rem'}}>{ labelsMap[key.toLowerCase()] }</Text>
                                            </View>
                                            <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                                <Text>
                                                    {
                                                        key.toLowerCase() === 'suppliernameslist' && <Box>
                                                            {
                                                                props.checkInFormData[key].map((item) => item.name)
                                                            }

                                                        </Box>
                                                    }
                                                    {
                                                        key.toLowerCase() === 'country' && <Box>
                                                            {props.checkInFormData[key].toUpperCase()}
                                                        </Box>
                                                    }
                                                    {
                                                        key.toLowerCase() === 'avettacompliance' && <Box>
                                                            {props.checkInFormData[key].toUpperCase()}
                                                        </Box>
                                                    }
                                                    {
                                                        key.toLowerCase() !== 'suppliernameslist' &&
                                                        key.toLowerCase() !== 'country'  &&
                                                        key.toLowerCase() !== 'avettacompliance' && <Box>
                                                            {props.checkInFormData[key]}
                                                        </Box>
                                                    }
                                                </Text>
                                            </Flex>
                                        </Box>
                                    }
                            </Box>
                        )
                    )
                }
                {
                    Object.keys(props.lseRadioData).map(key => (
                        <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                    <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                        <Text style={{ padding: '0.3rem'}}>{labelsMap[key.toLowerCase()]}</Text>
                                    </View>
                                    <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                        <Box>
                                            <Text> {props.lseRadioData[key]} </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                        )
                    )
                }
                {
                    Object.keys(props.poCheckRadioData).map(key => (
                        <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                                <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                    <Text style={{ padding: '0.3rem'}}>{labelsMap[key.toLowerCase()]}</Text>
                                </View>
                                <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                    <Box>
                                        <Text> {props.poCheckRadioData[key]} </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        )
                    )
                }
                {
                    props.poCheckRadioData["poCheck"] === "Yes" ?
                        <Box marginTop={'0.5rem'} marginBottom={'0.5rem'}>
                            <View borderRadius="md" bgColor={"lightBlue.500"} style={{flexDirection: "row", height: "2.0rem"}}>
                                <Text style={{ padding: '0.3rem'}}>{labelsMap["poNumber"]}</Text>
                            </View>
                            <Flex bgColor={"light.200"} borderRadius="md" style={{flexDirection: "column", padding: '0.5rem'}}>
                                <Box>
                                    <Text> {props.checkInFormData["poNumber"]} </Text>
                                </Box>
                            </Flex>
                        </Box> : <></>
                }
            </VStack>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"1rem"}
                        onPress= {submitCheckInData}
                        colorScheme="lightBlue"
                        variant="rounded"
                        isDisabled={buttonDisabled}> Complete Check In </Button>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkInPageChanger(CHECKIN_PAGE_INDEX_START_INCLUSIVE)}
                        variant="rounded"
                        isDisabled={buttonDisabled}> Edit
                </Button>
            </NativeBaseProvider>
        </View>
    )
}
export default withAuthenticator(ReviewCheckIn)