import {Radio, FormControl, WarningOutlineIcon} from "native-base";
import React, {useEffect} from "react";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {useState} from "react";

function get(props) {
    let _ = require('lodash')
    let options = []
    _.forEach(props.options, function (object) {
        options.push(
            <Radio
                key={object.key}
                my={props.attributeName}
                marginTop={"0.1rem"}
                value={object.value}
            >
                {object.id}
            </Radio>)
    })
    return options
}

function SimpleRadio(props) {
    const [radioValue, setRadioValue] = React.useState("0");
    const [attributeName, setAttributeName] = useState(props.attributeName)
    /**
     * Add the value in the formData
     * @param value
     */
    const handleSelection = (values) => {
        props.checkInFormDataRadioSetters((prev) => {
            return {
            ...prev,
            [attributeName]: values
            }
        });
        // clear the errors on valid user selection
        props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })

        props.handleRadioChange && props.handleRadioChange(values)
    }

    useEffect(() => {
        let selectedRadio = props.checkInFormDataRadio[props.attributeName]
        if (selectedRadio) {
            setRadioValue(selectedRadio)
        }
    });

    let radioOptions = get(props);

    return (
        <FormControl isRequired = {props.required}
                     isInvalid= {props.checkInValidationError !== undefined &&
                                 attributeName in props.checkInValidationError &&
                                 props.checkInValidationError[attributeName] !== ''}>
            <FormControl.Label _text={{
                fontSize: "s",
                bold: false
            }}>
                {props.label}
            </FormControl.Label>
            <Radio.Group
                name={props.attributeName}
                colorScheme={props.colorScheme}
                defaultValue={radioValue}
                value={radioValue}
                accessibilityLabel={props.placeholder}
                onChange = { (values) =>
                {
                    setRadioValue(values)
                    handleSelection(values)
                }
            }
                alignItems="flex-start">
                {radioOptions}
            </Radio.Group>
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                { props.checkInValidationError !== undefined && props.checkInValidationError[attributeName] }
            </FormControl.ErrorMessage>
        </FormControl>

    );
}

export default withAuthenticator(SimpleRadio);