import {styles} from "./style";
import {Image} from "react-native-web";
import {withAuthenticator} from "@aws-amplify/ui-react";

function RatingUp() {
    const source = "thumbsup.jpeg"
    return (
        <Image defaultSource = {source} style={styles.image} />
    );
}

export default withAuthenticator(RatingUp);