// to be interoperable we need to import from react-native instead of react-native-web.
// react-native-web module performs the translation.
import { StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    image: {
        borderColor: 'white',
        borderWidth: 0.5,
        height: 80 ,
        width: 150,
        resizeMode: 'cover',
        top: 10,
        marginVertical: "1rem"
    }
});