import React from "react";
import {
    Box,
    FlatList,
    Avatar,
    HStack,
    VStack,
    Text,
    Spacer,
    Center,
    NativeBaseProvider,
    FormControl, Button, View
} from "native-base";
import {
    CHECKOUT_SUPPLIER_COMPANY_NAME,
    CHECKOUT_SUPPLIER_INDEX,
    CHECKOUT_SUPPLIER_NAME_KEY,
    CHECKOUT_SUPPLIER_RECORD_PK,
    CHECKOUT_SUPPLIER_RECORD_SK,
    IS_METADATA_V2_AVAILABLE,
    COUNTRY
} from "../constants/check-out-workflow-constants";
import Label from "../components/Label/label";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import {CHECKOUT_PAGE_START_INDEX_INCLUSIVE, HOME_PAGE_INDEX} from "../utils/constants";
import { COUNTRY_CODE_TO_NAME_MAP } from "src/constants/region-country-constants";

function handleCheckout(props, supplierRecord) {
    props.checkOutFormDataSetter({
        ...props.checkOutFormDataSetter,
        [CHECKOUT_SUPPLIER_NAME_KEY]: supplierRecord.supplierNameKey,
        [CHECKOUT_SUPPLIER_RECORD_PK]: supplierRecord.pk,
        [CHECKOUT_SUPPLIER_RECORD_SK]: supplierRecord.sk,
        [CHECKOUT_SUPPLIER_COMPANY_NAME]: supplierRecord.supplierCompanyName,
        [CHECKOUT_SUPPLIER_INDEX]: supplierRecord.index,
        [IS_METADATA_V2_AVAILABLE]: supplierRecord.isMetadataV2Available,
        [COUNTRY]: supplierRecord.country
    });
    // start the checkout flow
    return props.pageChanger(CHECKOUT_PAGE_START_INDEX_INCLUSIVE);
}

const CheckInlist = (props) => {
    return <Box>
        <Center>
            <FormControl.Label marginTop={"2rem"} marginBottom={"2rem"} >
                {
                    props.data.length === 0 &&
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        { props.checkout && <Label labelText={"Well done! No action required"}/>}
                        { !props.checkout && <Label labelText={"No records found!"}/>}
                        <Button marginTop={"2rem"}
                                colorScheme="lightBlue"
                                onPress={() => props.pageChanger(HOME_PAGE_INDEX)}
                                variant="rounded">
                            Back to home
                        </Button>
                    </NativeBaseProvider>
                }
                {
                    props.data.length > 0 && props.checkout && <Label labelText={"Pending check outs"}></Label>
                }
                {
                    props.data.length > 0 && !props.checkout && <Label labelText={props.title === '' || props.title === undefined ? "Your Check Ins" : props.title}></Label>
                }
            </FormControl.Label>
        </Center>
        <Center>

        <FlatList data={props.data} renderItem={({
                                                     item: supplierRecord
                                                 }) => <Box borderBottomWidth="1" _dark={{
            borderColor: "muted.50"
        }} borderColor="muted.800" pl={["0", "4"]} pr={["0", "5"]} py="2">

            <HStack space={[2, 3]} justifyContent="space-between">
                <Avatar size="30px" bg={`${supplierRecord.avetta_color_code.toLowerCase()}.500`}/>
                <VStack style={{ width: 200}}>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800" bold>
                            {supplierRecord.supplierCompanyName || "Not Provided"}
                        </Text>
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800">
                            {supplierRecord.name}
                        </Text>
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800" bold>
                            {supplierRecord.siteCode}
                        </Text>
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800">
                            {COUNTRY_CODE_TO_NAME_MAP[supplierRecord.country]}
                        </Text>
                    </View>

                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text color="coolGray.600"  _dark={{
                            color: "warmGray.200"
                        }} >
                            {supplierRecord.jobType}
                        </Text>
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        <Text color="coolGray.600" bold _dark={{
                            color: "warmGray.200"
                        }}>
                            Avetta Status: {supplierRecord.avetta_status}
                        </Text>
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'row'}}>
                        {
                            supplierRecord.eventFlag &&
                            <Text color="red.600" bold _dark={{color: "red.500"}}>Event</Text>
                        }
                    </View>
                    <View style={{flexGrow: 1, flexDirection: 'column'}}>
                        <Text bold fontSize="xs" _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800">
                            Check in completed
                        </Text>
                        <Text fontSize="xs" _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800">
                            by {supplierRecord.checkInBy}
                        </Text>
                        <Text fontSize="xs" _dark={{
                            color: "warmGray.50"
                        }} color="coolGray.800">
                            on {supplierRecord.checkInTime}
                        </Text>
                    </View>
                    {
                        supplierRecord.checkedOut &&
                        <View style={{flexGrow: 1, flexDirection: 'column'}}>
                                <Text bold fontSize="xs" _dark={{
                                    color: "warmGray.50"
                                }} color="coolGray.800">
                                    Check out completed
                                </Text>
                            <Text fontSize="xs" _dark={{
                                color: "warmGray.50"
                            }} color="coolGray.800" >
                                 on {supplierRecord.checkOutTime}
                            </Text>
                        </View>
                    }
                    { !supplierRecord.checkedOut &&
                        <View style={{flexGrow: 1, flexDirection: 'row'}}>
                            <NativeBaseProvider theme={RoundedButtonTheme}>
                                <Center>
                                    <Button marginTop={"1rem"} colorScheme="lightBlue" onPress={() =>
                                        handleCheckout(props, supplierRecord)
                                    } variant="rounded" maxWidth={200} minW={200}> Check Out </Button>
                                </Center>
                            </NativeBaseProvider>
                        </View>
                    }
                </VStack>
                <Spacer />
            </HStack>
        </Box>}
                  keyExtractor={item => item.id}
        />
    </Center>
    </Box>;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    return (
        <NativeBaseProvider>
            <Center flex={1} px="3">
                <CheckInlist {...props}/>
            </Center>
        </NativeBaseProvider>
    );
};