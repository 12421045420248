import React, {useState} from 'react';
import {Auth, Hub} from 'aws-amplify';
import {TextInput} from "../FormInput/TextInput";
import {VStack, Center, Heading, Button} from "native-base";
import {SpinnerComponent} from "../SpinnerComponent/spinner";
import { getRandomString } from '../../constants/random-string';
import { SignUpConfigs, SignUpTypes } from './signInConstants';

export function SignUp({email, emailErrorText, handleEmailChange, validateEmail, authType, setAlert, setFormState}) {
    const [webHookUrl, setWebHookUrl] = useState('')
    const [webHookUrlError, setWebHookUrlError] = useState('')
    const [sendingVerificationCode, setSendingVerificationCode] = useState(false)

    function validateUrl(url, signUpConfig) {
        if(!url.host.includes(signUpConfig.hostName) || signUpConfig.pathParams.some(pathParam => !url.pathname.includes(pathParam)) ||
                signUpConfig.searchParams.some(searchParam => !url.searchParams.has(searchParam))) {
            return false
        }
        return true
    }
    
    /**
     * Validates the Chime or Slack Web Hook Url
     */
    function validateWebHookUrl() {
        if (webHookUrl === undefined || webHookUrl === '') {
            setWebHookUrlError('Web hook url cannot be empty')
            return false
        }
        let webHookUrlErrorMessage = `Please enter valid ${authType} web hook url address`
        try {
            let url = new URL(webHookUrl)
            if (authType === SignUpTypes.CHIME) {
                if (!validateUrl(url, SignUpConfigs.CHIME)) {
                    setWebHookUrlError(webHookUrlErrorMessage)
                    return false
                }
            } else {
                if (!validateUrl(url, SignUpConfigs.SLACK)) {
                    setWebHookUrlError(webHookUrlErrorMessage)
                    return false
                }
            }
        } catch (err) {
            console.error("Error while parsing web hook url: ", err)
            setWebHookUrlError(webHookUrlErrorMessage)
            return false
        }
        setWebHookUrlError('')
        return true
    }

    /**
     * Listens to sign in failure events and updates the form accordingly
     */
    Hub.listen('auth', (data) => {
        const event = data.payload.event;
        if(event === "signUp_failure") {
            if (data.payload.data.code === 'UsernameExistsException') {
                setAlert({
                    header: 'Sign Up Failure',
                    body: 'Username already exists! Please go back to sign in',
                    status: 'error'
                })
            }
            else if (data.payload.data.code === 'InvalidParameterException') {
                setAlert({
                    header: 'Sign Up Failure',
                    body: 'Please provide a valid email address!',
                    status: 'error'
                })
            }
            else if(data.payload.data.code === 'UserNotConfirmedException') {
                setAlert({
                    header: 'Sign Up Failure',
                    body: 'Code was already sent to your email! Please provide it',
                    status: 'error'
                })
                setFormState("confirmUser");
            } else {
                console.error(`Sign Up failed with ${data.payload.data.code} error`)
                setAlert({
                    header: 'Sign Up Failure',
                    body: `${data.payload.data.code} Error Occurred. Please try to sign up again!`,
                    status: 'error'
                })
            }
        }
    })

    /**
     * Sends Verification Code to user email address
     */
    async function signUp() {
        try {
            if (!validateEmail(email)) {
                return;
            } 
            if (!validateWebHookUrl()) {
                return;
            } 
            setSendingVerificationCode(true)
            setAlert(undefined)
            await Auth.signUp({
                username: email.toLowerCase(),
                password: getRandomString(30),
                attributes: {
                    'custom:authType': authType,
                    'custom:webHookUrl': webHookUrl
                }
            })
            setSendingVerificationCode(false)
            setAlert({
                header: 'Sign Up Confirmation',
                body: 'Please provide Verification code sent to your email',
                status: 'success'
            })
            setFormState("confirmUser");
        } catch (err) {
            console.error('Error encountered while signing up user', err)
            setSendingVerificationCode(false)
        }
    }

    function setBackToSignIn() {
        setAlert(undefined)
        setFormState('signIn')
    }

    return(
        <VStack space={3} marginTop={'1rem'}>
            <Center>
                <Heading size="md" marginTop={"1rem"}>Sign Up With {authType}</Heading>
            </Center>
            <TextInput value={email} errorText= {emailErrorText} onChangeHandler = {handleEmailChange} placeholder='Email Address'/>
            <TextInput value={webHookUrl} errorText= {webHookUrlError} onChangeHandler = {setWebHookUrl} placeholder={`${authType} Web Hook Url`}/>
            {
                sendingVerificationCode &&
                <SpinnerComponent text = 'Sending verification code to email. Please wait....' />
            }
            <VStack space={3} w="100%" marginTop={"2rem"}>
                <Button 
                        colorScheme="lightBlue"
                        variant={"rounded"}
                        onPress={signUp}>
                        Sign Up
                </Button>
                <Button 
                        colorScheme="lightBlue"
                        variant={"rounded"}
                        onPress={setBackToSignIn}>
                        Back to Sign In
                </Button>
            </VStack>
        </VStack>
    )
}
