import { NON_HRO_OPERATION, OTHER_HRO_OPERATION } from "../utils/constants";
const ACTIVITY_JOB_LIST = [
    'Aerial Lift / Scissors Lift' ,
    'Aircraft Maintenance',
    'Amazon robotics',
    'Asbestos Abatement',
    'Building Automation',
    'Confined Space',
    'Construction, installation, or demolition work',
    'Cranes or Rigging',
    'Drywall',
    'Electrical Work',
    'Electrical and Instrumentation',
    'Electrical Shock / Arc Flash Exposure',
    'Elevators / Escalators',
    'Equipment Service / Maintenance',
    'Excavation or Trenching',
    'Facilities Management',
    'Fuel Transfer',
    'General Contractor',
    'Hazardous Energy Control (Lock-Out/Tag-Out)',
    'Hazardous Materials / Chemicals',
    'Hot Work',
    'HVAC',
    'Janitorial / Cleaning',
    'Lead Abatement',
    'Lifting Operations',
    'Lifting & Rigging',
    'Mechanical / Piping Contractor',
    'Painting',
    'Paving',
    'Plumbing',
    'Powered Industrial Trucks (PIT)',
    'Roof Work',
    'Scaffolding',
    'Signage / Commercial Signs',
    'Sprinkler / Fire Protection',
    'Steel Structures Erection and Misc. Metals',
    'Window Washing',
    'Working in Isolation/Lone Worker',
    'Work at Heights',
    'Yard Work'
]
const SORTED_UNIQUE_ACTIVITY_JOB_LIST = [...new Set(ACTIVITY_JOB_LIST)].sort()
SORTED_UNIQUE_ACTIVITY_JOB_LIST.unshift(NON_HRO_OPERATION)
//SORTED_UNIQUE_ACTIVITY_JOB_LIST.push(OTHER_HRO_OPERATION)
export const JOB_TYPE_ACTIVITY_LIST = SORTED_UNIQUE_ACTIVITY_JOB_LIST.map(jobType => ({"id" : jobType, "value" : jobType}))