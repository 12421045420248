import { withAuthenticator } from "@aws-amplify/ui-react";
import {
  Button,
  FormControl,
  Input,
  Progress,
  Text,
  Center,
  WarningOutlineIcon
} from "native-base";
import React, { useEffect } from "react";
import Label from "../Label/label";
import { useState } from "react";
import { Auth } from "aws-amplify";
import {
  AVETTA_COMPLIANT,
  AVETTA_COMPLIANT_NO_HRO,
  AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO,
  AVETTA_CONDITIONALLY_COMPLIANT_HRO,
  AVETTA_NOT_COMPLIANT_NO_HRO,
  AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT,
  AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT,
  SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO,
  SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT,
  SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT,
  AVETTA_NOT_ACTIVE_NO_HRO,
  AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT,
  AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT,
  AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO,
  AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT,
  AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT,
  ERROR_EXCEPTION,
  AvettaComplianceBasedNextStepsMessages,
  AvettaComplianceMessageStatus,
  AvettaComplianceCheckMessages    
} from "../../constants/avetta-compliance-based-next-steps-messages";
import {GET_AVETTA_COMPLIANCE_PATH, AVETTA_COMPLIANCE_DEFAULT_STATUS_CODE, isAvettaCompliant, isAvettaConditionalyCompliant, isHro, isLseEvent, isNotAvettaCompliantOrNotAvailable } from "../../utils/constants";
import Info from "../Info/info";
import {
    AVETTA_COMPLIANCE_CHECK_KEY,
    AVETTA_COMPLIANCE_CHECK_MESSAGE,
    AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS,
    AVETTA_COMPLIANCE_CPOC_NEXT_STEPS,
    AVETTA_COMPLIANCE_STATUS_CODE,
    PROFILE_COUNTRY,
    PROFILE_SITE_CODE,
    TEXT_COMPONENT_ATTRIBUTE_NAME_KEY
} from "../../constants/attribute-names-constants";
import {getSupplierCheckInApiEndpoint} from "../../config"
import { COUNTRY_TO_REGION_MAP } from "src/constants/region-country-constants";

let interval = undefined

/* Every Progress bar increment value by PROGRESS_INCREMENT */
const PROGRESS_INCREMENT = 10

/* Every 2 seconds update the progress bar by PROGRESS_INCREMENT */
const INTERVAL_TIME_MS = 2000

/* Maximum value of progress bar */
const MAX_PROGRESS_BAR_VALUE = 100

/* Avetta API Timeout */
const AVETTA_API_TIMEOUT_MS = 20000

/* Stop the workflow */
export const STOP_WORKFLOW =  'stopWorklow'

function TextInputComponent(props) {

    const [avettaDisabled, setAvettaDisabled] = useState(props.avettaDisabled === undefined? true:props.avettaDisabled)
    const [readonly, setReadOnly] = useState(props.readonly || false)
    const [value, setValue] = React.useState(props.checkInFormData[props.attributeName] !== undefined? props.checkInFormData[props.attributeName]:'');
    const [loadingText, setLoadingText] = React.useState("")
    const [loading, isLoading] = React.useState(false)
    const [progress, setProgress] = React.useState(0)
    const [complianceCheckComplete, setComplianceCheckComplete] = React.useState(false)
    const [complianceCheckCompleteMessage, setComplianceCheckCompleteMessage] = React.useState("")
    const [complianceCheckCompleteMessageStatus, setComplianceCheckCompleteMessageStatus] = React.useState("")
    const [complianceCheckInProgress, setComplianceCheckInProgress] = React.useState("")
    const [attributeName, setAttributeName] = useState(props.attributeName)
    const [cpocNextSteps, setCpocNextSteps] = useState([])

    const AVETTA_DONE = 'done'
    const AVETTA_IN_PROGRESS = 'in_progress'
    const AVETTA_FAILED = 'failed'

    /**
     * Once the compliance check is complete and if the
     * customer wants to go back and forth between screens,
     * we need to prevent trigger the avetta compliance check again.
     */
    useEffect(() => {
        // this is a shared component and hence only do this for avetta compliance
        // as not all props are sent for other input
        if (props.attributeName === TEXT_COMPONENT_ATTRIBUTE_NAME_KEY) {
            let complianceCheckValue = props.checkInFormData[AVETTA_COMPLIANCE_CHECK_KEY]
            if (complianceCheckValue === AVETTA_DONE && !loading) {
                props.setNextDisabled(false)
                props.setPrevDisabled(false)
                setValue(props.checkInFormData[props.attributeName])
                setComplianceCheckComplete(true)
                setComplianceCheckCompleteMessage(props.checkInFormData[AVETTA_COMPLIANCE_CHECK_MESSAGE] || '')
                setComplianceCheckCompleteMessageStatus(props.checkInFormData[AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS])
            } else if(complianceCheckValue === AVETTA_IN_PROGRESS) {
              props.setNextDisabled(true)
              props.setPrevDisabled(false)
              setComplianceCheckComplete(true)
              setValue(props.checkInFormData[props.attributeName])
              setComplianceCheckCompleteMessage(props.checkInFormData[AVETTA_COMPLIANCE_CHECK_MESSAGE] || '')
              setComplianceCheckCompleteMessageStatus(props.checkInFormData[AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS])
            }
            else {
                //this was casuing issue, i tired re-triggering and it works as expected
                // if you end re-triggering, we need to clear the success message
                setComplianceCheckComplete(false)
                setComplianceCheckCompleteMessage('')
                setComplianceCheckCompleteMessageStatus('')
            }
        }
    });

    useEffect(() => {
        if (loading) {
            interval = setInterval(() => {
                    setProgress((prev) => prev + PROGRESS_INCREMENT)
                }, INTERVAL_TIME_MS)
        } else {
            clearInterval(interval)
        }
    }, [loading])


    useEffect(() => {
        if (progress === MAX_PROGRESS_BAR_VALUE) {
            clearLoading();
            clearInterval(interval);
        }
    }, [progress]);

    const setLoading = () => {
        isLoading(true)
        setLoadingText("")
        setComplianceCheckCompleteMessage("")
        setComplianceCheckInProgress("Avetta Compliance Check in progress! This might take a minute")
        props.setNextDisabled(true)
        props.setPrevDisabled(true)
        props.setStopCheckInFlow(false)
        props.checkInFormDataSetter((previous) => {
            return {
                ...previous,
                [STOP_WORKFLOW] : false
            }
        });
    }
    const setAvettaWorkflow = (stopWorkflow) => {
        props.setStopCheckInFlow(stopWorkflow)
        props.checkInFormDataSetter((previous) => {
            return {
                ...previous,
                [STOP_WORKFLOW] : stopWorkflow
            }
        });
    }
    const clearLoading = () => {
        isLoading(false)
        setLoadingText("")
        setProgress(0)
        setComplianceCheckComplete(true)
    }

    /**
     * Found a case when the compliance check is being done
     * customer was able to press previous. This should not be allowed.
     * Hence, while the check is happening, disabling both the buttons.
     * However, On initial page load they should be able to go back to previous screen.
     *
     */
     const handleAvettaCompliance = () => {
        setLoading();
        setTimeout(setAvettaComplianceTextAndMessages(), AVETTA_API_TIMEOUT_MS);
      };

      function setAvettaComplianceTextAndMessages() {
        try {
          let token = "";
            Auth.currentAuthenticatedUser().then((data) => {
                token = data.signInUserSession.idToken.jwtToken;
                let requestHeaders = new Headers();
                let companyName = props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY]
                requestHeaders.append("Authorization", "Bearer " + token);
                requestHeaders.append(
                    "country_code",
                    props.checkInFormData["country"] === undefined ? props.checkInFormData[PROFILE_COUNTRY].toLowerCase() :
                        props.checkInFormData["country"].toLowerCase()
                );
                requestHeaders.append(
                  "supplier_company_name",
                    companyName
                );
                requestHeaders.append(
                  "site_code",
                    props.checkInFormData["siteCode"] === undefined ? props.checkInFormData[PROFILE_SITE_CODE].toLowerCase() :
                        props.checkInFormData["siteCode"].toLowerCase()
                );
                requestHeaders.append("default_sk", "amazon us supplier");
                const requestOptions = {
                  method: "GET",
                  headers: requestHeaders,
                  redirect: "follow",
                };
                const url = getSupplierCheckInApiEndpoint(COUNTRY_TO_REGION_MAP[props.checkInFormData['country']]) + GET_AVETTA_COMPLIANCE_PATH
                fetch(url, requestOptions)
                  .then((response) => {
                    return response.json().then((data) => ({
                      status: response.status,
                      data,
                    }));
                  })
                  .then((result) => {
                    const responseStatus = result.status;
                      props.checkInFormDataSetter((previous) => {
                          return {
                              ...props.checkInFormData,
                              [TEXT_COMPONENT_ATTRIBUTE_NAME_KEY] : companyName
                          }
                      });
                    if(responseStatus === 409){ //multiple choices
                      setAvettaComplianceTextForMultipleCompanyFound(result.data);
                    } else if (responseStatus === 404) {
                      setAvettaComplianceTextAndStatus(result.data);
                      return;
                    } 
                    else if(responseStatus === 412) {
                      setAvettaComplianceTextForOtherAllowedSites(result.data)
                      return;
                    } 
                    else if (responseStatus >= 500) {
                      setAvettaComplianceTextAndStatus(result.data);
                      return;
                    } else if (responseStatus === 200) {
                      if (result.data.avetta_client_status.toLowerCase() === 'disconnected' &&
                          result.data.allowed_sites !== undefined &&
                          result.data.allowed_sites.length > 0 ) {
                          setAvettaComplianceTextForOtherAllowedSites(result.data)
                      } else {
                          setAvettaComplianceTextAndStatus(result.data);
                      }
                      return;
                    } else {
                      setAvettaComplianceTextAndStatusForError()
                      return;
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                    setAvettaComplianceTextAndStatusForError()
                  });
              });
        } catch (err) {
          setAvettaComplianceTextAndStatusForError()
        }
      }
    
      function setAvettaComplianceTextForOtherAllowedSites(data) {
        let avettaComplianceMessageKey = ''
        let avettaNextStepComplianceMessageKey = ''
        let supplierName = data ? data["supplier_name"] : props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY];
        let supplierActualName  = supplierName ? supplierName.replace(/[^()a-zA-Z0-9!&#/-?' ']/g, '') : undefined
        if (!isHro(props)) {
          avettaNextStepComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO
          avettaComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_NO_HRO
        } else if(isHro(props) && !isLseEvent(props)) {
          avettaNextStepComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT
          avettaComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_NOT_LSE_EVENT
          setAvettaWorkflow(true) // do not allow to check in
        } else if(isHro(props) && isLseEvent(props)) {
          avettaNextStepComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT
          avettaComplianceMessageKey = AVETTA_SUPPLIER_NOT_CONNECTED_FOR_SITE_HRO_AND_LSE_EVENT
        }
        setMsgEnableBtnsAndMarkAPICallComplete(
            supplierActualName,
            avettaNextStepComplianceMessageKey,
            AvettaComplianceMessageStatus.Info,
            avettaComplianceMessageKey,
            AVETTA_COMPLIANCE_DEFAULT_STATUS_CODE
        )
      }

      function setAvettaComplianceTextAndStatus(data = undefined) {
        const complianceStatus = (data && data["avetta_compliance_status"]) ? data["avetta_compliance_status"] : undefined;
        const avettaClientStatus = (data && data["avetta_client_status"]) ? data["avetta_client_status"] : undefined;
        let supplierName = (data && data["supplier_name"]) ? data["supplier_name"] : props.checkInFormData[TEXT_COMPONENT_ATTRIBUTE_NAME_KEY];
        let supplierActualName  = supplierName ? supplierName.replace(/[^()a-zA-Z0-9!&#/-?' ']/g, '') : undefined
        let avettaComplianceMessageKey = ''
        let avettaNextStepComplianceMessageKey = ''
        let avettaMessageStatus
        if(avettaClientStatus && avettaClientStatus.toLowerCase() === 'active') {
          setAvettaWorkflow(false)
          if(complianceStatus && isAvettaCompliant(complianceStatus) && isHro(props)){
            avettaNextStepComplianceMessageKey = AVETTA_COMPLIANT
            avettaComplianceMessageKey = AVETTA_COMPLIANT
            avettaMessageStatus = AvettaComplianceMessageStatus.Success
          } else if(complianceStatus && isAvettaCompliant(complianceStatus) &&  !isHro(props)){
                avettaNextStepComplianceMessageKey = AVETTA_COMPLIANT_NO_HRO
                avettaComplianceMessageKey = AVETTA_COMPLIANT_NO_HRO
                avettaMessageStatus = AvettaComplianceMessageStatus.Info
          } else if(complianceStatus && isAvettaConditionalyCompliant(complianceStatus) && !isHro(props)){
            avettaNextStepComplianceMessageKey = AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO
            avettaComplianceMessageKey = AVETTA_CONDITIONALLY_COMPLIANT_NO_HRO
            avettaMessageStatus = AvettaComplianceMessageStatus.Info
          } else if(complianceStatus && isAvettaConditionalyCompliant(complianceStatus) && isHro(props)){
              avettaNextStepComplianceMessageKey = AVETTA_CONDITIONALLY_COMPLIANT_HRO
              avettaComplianceMessageKey = AVETTA_CONDITIONALLY_COMPLIANT_HRO
              avettaMessageStatus = AvettaComplianceMessageStatus.Warning
          } else if (isNotAvettaCompliantOrNotAvailable(complianceStatus) && !isHro(props)) {
            avettaNextStepComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_NO_HRO : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO
            avettaComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_NO_HRO : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO
            avettaMessageStatus = AvettaComplianceMessageStatus.Info
            setAvettaWorkflow(false) // allow the check in
          } else if(isNotAvettaCompliantOrNotAvailable(complianceStatus) && isHro(props) && !isLseEvent(props)) {
            avettaNextStepComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT
            avettaComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_HRO_NOT_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT
            avettaMessageStatus = complianceStatus ? AvettaComplianceMessageStatus.Error : AvettaComplianceMessageStatus.Info
            setAvettaWorkflow(true) // do not allow to check in
          } else if(isNotAvettaCompliantOrNotAvailable(complianceStatus) && isHro(props) && isLseEvent(props)) {
            avettaNextStepComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT
            avettaComplianceMessageKey = complianceStatus ? AVETTA_NOT_COMPLIANT_HRO_AND_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT
            avettaMessageStatus = complianceStatus ? AvettaComplianceMessageStatus.Error : AvettaComplianceMessageStatus.Info
            // If compliance status is present block the check in, if missing then allow the check in
            complianceStatus ? setAvettaWorkflow(true) : setAvettaWorkflow(false)
          } else {
            avettaNextStepComplianceMessageKey = ERROR_EXCEPTION
            avettaComplianceMessageKey = ERROR_EXCEPTION
            avettaMessageStatus = AvettaComplianceMessageStatus.Error
          }
        } else { // avetta client status is not active. Ie; Inactive, Disconnected, etc or supplier not found
          if (!isHro(props)) {
            avettaNextStepComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_NO_HRO : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO
            avettaComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_NO_HRO : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_NO_HRO
            avettaMessageStatus = AvettaComplianceMessageStatus.Info
            setAvettaWorkflow(false)
          } else if(isHro(props) && !isLseEvent(props)) {
            avettaNextStepComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT
            avettaComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_HRO_NOT_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_NOT_LSE_EVENT
            avettaMessageStatus = AvettaComplianceMessageStatus.Info
            setAvettaWorkflow(true) // do not allow to check in
          } else if(isHro(props) && isLseEvent(props)) {
            avettaNextStepComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT
            avettaComplianceMessageKey = avettaClientStatus ? AVETTA_NOT_ACTIVE_HRO_AND_LSE_EVENT : SUPPLIER_NOT_FOUND_OR_REGISTERED_IN_AVETTA_HRO_AND_LSE_EVENT
            avettaMessageStatus = AvettaComplianceMessageStatus.Info
            // if avetta client status is inactive, block the check in, if not found then allow check in
            avettaClientStatus ? setAvettaWorkflow(true) : setAvettaWorkflow(false)
          } else {
            avettaNextStepComplianceMessageKey = ERROR_EXCEPTION
            avettaComplianceMessageKey = ERROR_EXCEPTION
            avettaMessageStatus = AvettaComplianceMessageStatus.Error
          }
        }
        setMsgEnableBtnsAndMarkAPICallComplete(supplierActualName,
            avettaNextStepComplianceMessageKey,
            avettaMessageStatus,
            avettaComplianceMessageKey,
            complianceStatus
        )
      }

      function setAvettaComplianceTextAndStatusForError() {
        let avettaComplianceMessageWithInfo = AvettaComplianceCheckMessages[ERROR_EXCEPTION].steps
        setMsgAndMarkAPICallInProgress(avettaComplianceMessageWithInfo, AvettaComplianceMessageStatus.Error)
      }
    
      function setAvettaComplianceTextForMultipleCompanyFound(data) {
        let avettaComplianceMessage = ''
        let listOfCompaniesFound = []
        data.Items.forEach(function(companyInfo, index) {
          listOfCompaniesFound.push(companyInfo.supplier_display_name)
        });
        avettaComplianceMessage = ["Multiple companies found for this name:", JSON.stringify(listOfCompaniesFound,null,2), "Please enter the full name and try again."]
        setMsgAndMarkAPICallInProgress(avettaComplianceMessage, AvettaComplianceMessageStatus.Info)
      }
    
      function setMsgAndMarkAPICallInProgress(avettaComplianceMessage, avettaMessageStatus) {
        props.checkInFormDataSetter({
          ...props.checkInFormData,
          [AVETTA_COMPLIANCE_CHECK_KEY]: AVETTA_IN_PROGRESS,
          [AVETTA_COMPLIANCE_CHECK_MESSAGE]: avettaComplianceMessage,
          [AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS]: avettaMessageStatus,
        });
        clearLoading();
      }
    
      function setMsgEnableBtnsAndMarkAPICallComplete(supplierActualName,
                                                      avettaNextStepComplianceMessageKey,
                                                      avettaMessageStatus,
                                                      avettaComplianceMessageKey,
                                                      complianceStatus) {
        const nextSteps = AvettaComplianceBasedNextStepsMessages[avettaNextStepComplianceMessageKey]
        let avettaComplianceMessageWithInfo = AvettaComplianceCheckMessages[avettaComplianceMessageKey].steps
        avettaComplianceMessageWithInfo = avettaComplianceMessageWithInfo.map((line) => line.replace("{{SUPPLIER_NAME}}", supplierActualName || ""))
        props.setNextDisabled(false);
        props.setPrevDisabled(false);
        props.checkInFormDataSetter((previous) => {
            return {
                ...previous,
                [AVETTA_COMPLIANCE_CHECK_KEY]: AVETTA_DONE,
                [AVETTA_COMPLIANCE_CHECK_MESSAGE]: avettaComplianceMessageWithInfo,
                [AVETTA_COMPLIANCE_STATUS_CODE]: complianceStatus || AVETTA_COMPLIANCE_DEFAULT_STATUS_CODE,
                [AVETTA_COMPLIANCE_CPOC_NEXT_STEPS]: nextSteps,
                [AVETTA_COMPLIANCE_CHECK_MESSAGE_STATUS]: avettaMessageStatus,
                [TEXT_COMPONENT_ATTRIBUTE_NAME_KEY]: supplierActualName,
            }
        });
        clearLoading();
      }

    let options = []
    if (props.rightElement === true) {
         options.push(<Button size="xs"
                              rounded="none"
                              w="100"
                              h="full"
                              onPress={handleAvettaCompliance}
                              isLoading={loading}
                              isLoadingText={loadingText}
                              isDisabled={avettaDisabled}
         >Avetta Check</Button>
         )
    }

    const handleChange = text => {
        if (text.length === 0 || text === ''){
            setValue('');
            setAvettaDisabled(true)
            props.checkInFormDataSetter((prev) => {
                return {
                    ...prev,
                    [attributeName] : ''
                }
            });
            return;
        } else {
            setValue(text);
            setAvettaDisabled(false)
            props.checkInFormDataSetter((prev) => {
                return {
                    ...prev,
                    [attributeName] : text
                }
            });
        }
        // clear the errors on valid user selection
        props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
    }

    // TODO https://issues.amazon.com/issues/ISC-ST-2024
    if (props.required === true) {
        return (
            <Center>
            <FormControl isRequired = {props.required} isInvalid={props.checkInValidationError !== undefined && attributeName in props.checkInValidationError && props.checkInValidationError[attributeName] !== ''}>
                <FormControl.Label marginTop={props.marginTop ? props.marginTop : "0rem"}>{props.label}</FormControl.Label>
                <Input marginTop={"1rem"}
                       minWidth={props.minWidth}
                       maxWidth={props.maxWidth}
                       placeholder = {props.placeholder}
                       onChangeText={handleChange}
                       variant = "underlined"
                       InputRightElement= {options}
                       value={value}
                       isReadOnly={readonly}
                       flex={1}
                />
                { loading &&
                    (
                        <Center>
                            <Progress colorScheme="emerald"
                                      marginTop="1rem"
                                      value={progress}
                                      max={100}
                                      minW={300}/>
                            <Center>
                                <Text marginTop="1rem">
                                    {complianceCheckInProgress}
                                </Text>
                            </Center>
                        </Center>
                    )
                }
                {complianceCheckComplete &&  
                 <Center>
                      <Info heading= {complianceCheckCompleteMessage[0]}
                            content={complianceCheckCompleteMessage.slice(1)}
                            status={complianceCheckCompleteMessageStatus} showIcon={ true }/>
                </Center>
                }
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    { props.checkInValidationError !== undefined && props.checkInValidationError[attributeName] }
                </FormControl.ErrorMessage>
            </FormControl>
            </Center>
        );
    } else {
        return (
            <Center>
            <FormControl isInvalid={props.checkInValidationError !== undefined && attributeName in props.checkInValidationError && props.checkInValidationError[attributeName] !== ''}>
                <FormControl.Label marginTop={props.marginTop ? props.marginTop : "1rem"}>{props.label}</FormControl.Label>
                <Input marginTop={"1rem"}
                       minWidth={props.minWidth}
                       maxWidth={props.maxWidth}
                       placeholder = {props.placeholder}
                       onChangeText={handleChange}
                       variant = "underlined"
                       InputRightElement= {options}
                       value={value}
                       isReadOnly={readonly}
                       flex={1}
                />
                { loading &&
                    (<Center>
                        <Progress colorScheme="emerald"
                                  value={progress}
                                  max={100}
                                  minW={300}/>
                        <Center>
                            <Text marginTop="1rem">
                                {complianceCheckInProgress}
                            </Text>
                        </Center>
                    </Center>
                    )
                }
                { complianceCheckComplete && <Label marginTop="1rem" labelText={complianceCheckCompleteMessage} showAsRequired = {false}></Label>}
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    { props.checkInValidationError !== undefined && props.checkInValidationError[attributeName] }
                </FormControl.ErrorMessage>
            </FormControl>
            </Center>
        );
    }
}

export default withAuthenticator(TextInputComponent);
