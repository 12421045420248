// Check if current date <= initial checkin date (sk) + onsite days
// and if expected_num_of_days_on_site > 1
export function isEligibleForMultiDaysCheckIn(checkInRecord) {
    try {
        const daysOnSite = checkInRecord.expected_num_of_days_on_site
        const today = new Date()
        // Get the date from sk and convert it to a date object
        const checkInDate = new Date(checkInRecord.sk.substring(0,10))
        // Add one day buffer to since Date object in JS is off by 1 day for the format yyyy-mm-dd
        checkInDate.setDate(checkInDate.getDate() + daysOnSite + 1)
        return daysOnSite > 1 && today <= checkInDate;
    } catch (e) {
        console.error(e)
        return false
    }
}