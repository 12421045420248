import { Auth } from 'aws-amplify'
import {
    CHECK_IN_PATH,
    DEFAULT_NUM_OF_DAYS_ON_SITE,
    isLseEvent,
} from "./utils/constants";
import { getSupplierCheckInApiEndpoint } from "./config"
import { COUNTRY_TO_REGION_MAP } from './constants/region-country-constants';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function submitDataAndUpdateThePage(propsWithAllFormData, nextPage) {
    const formDataPayload = getSubmitPayload(propsWithAllFormData)
    return sleep(10).then(() => {
        let token = "";
        Auth.currentAuthenticatedUser().then((data) => {
            let myHeaders = new Headers();
            token = data.signInUserSession.idToken.jwtToken;
            myHeaders.append("Authorization", "Bearer " + token)
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(formDataPayload),
                redirect: 'follow',
            };
            const url = getSupplierCheckInApiEndpoint(COUNTRY_TO_REGION_MAP[propsWithAllFormData.checkInFormData.country]) + CHECK_IN_PATH
            fetch(url, requestOptions)
            .then((response) => {
                return response.json().then((data) => ({
                    response: response,
                    status: response.status,
                    data,
                }));
            })
            .then((result) => {
                if (result.response.ok) {
                    propsWithAllFormData.checkInPageChanger(nextPage)
                } else {
                    alert('Error occurred while checking in the supplier, please try again.')
                }
            })
            .catch(error => {
                alert('Error occurred while checking in the supplier, please try again.')
            });
        });       
    });
}

function getSubmitPayload(propsWithAllFormData) {
    let supplier_representatives_list = []
    propsWithAllFormData.checkInFormData.supplierNamesList.forEach(element =>  supplier_representatives_list.push(element.name));
    let checkOutDate = new Date();
    checkOutDate.setDate(new Date().getDate() + ((propsWithAllFormData.checkInFormData.daysOnSite || 1) - 1 ))
    const isThisLseEvent = isLseEvent(propsWithAllFormData)
    const numOfDaysOnSiteParsedInt = getNumOfDaysOnSiteParsedInt(propsWithAllFormData);

    const payloadValuesJson = {
        "cpoc_amazon_alias": propsWithAllFormData.checkInFormData.alias,
        "supplier_representatives_list": supplier_representatives_list,
        "avetta_compliance_status_code": propsWithAllFormData.checkInFormData.avettaComplianceStatusCode,
        "cpoc_org_name": propsWithAllFormData.checkInFormData.orgName,
        "supplier_company_name": propsWithAllFormData.checkInFormData.contractorName,
        "country": propsWithAllFormData.checkInFormData.country,
        "site_code": propsWithAllFormData.checkInFormData.siteCode,
        "sk": propsWithAllFormData.checkInFormData.sk,
        "job_type": propsWithAllFormData.checkInFormData.jobRiskAssessment,
        "containsSev": isThisLseEvent,
        "checkout_day": checkOutDate.toISOString(),
        "po_number": propsWithAllFormData.checkInFormData.poNumber,
        "po_order_valid": propsWithAllFormData.poCheckRadioData.poCheck === "Yes",
        "supplier_virtual_orientation_completed": propsWithAllFormData.checkInFormData.preRequisite === "Yes",
        "jha_approved_by_whs": propsWithAllFormData.checkInFormData.preRequisite === "Yes",
        "supplier_category_approved": propsWithAllFormData.checkInFormData.preRequisite === "Yes",
        "expected_num_of_days_on_site": numOfDaysOnSiteParsedInt
    };
    return payloadValuesJson;
}

function getNumOfDaysOnSiteParsedInt(propsWithAllFormData) {
    let daysOnSite = DEFAULT_NUM_OF_DAYS_ON_SITE
    try {
        daysOnSite = parseInt(propsWithAllFormData.checkInFormData.daysOnSite);
    } catch (e) {
        console.log('No-Op');
    }
    return daysOnSite || DEFAULT_NUM_OF_DAYS_ON_SITE;
}

export default submitDataAndUpdateThePage
