import React, {useState, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {Box, Link, ScrollView} from "native-base";
import SignInHeader from "../SignInHeader/header";
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { SignInOTP } from './SignInOTP';
import { ConfirmUser } from './ConfirmUser';
import Info from "../Info/info";
import App from "../../App";

export function Authentication() {
    const [email, setEmail] = useState('')
    const [emailErrorText, setEmailErrorText] = useState('')
    const [loggedInUser, setLoggedInUser] = useState()
    const [cognitoUser, setCognitoUser] = useState('')
    const [formState, setFormState] = useState(loggedInUser !== undefined ? 'signedIn' : 'signIn')
    const [authType, setAuthType] = useState('')
    const [alert, setAlert] = useState(undefined)


    useEffect(() => {
        Auth.currentAuthenticatedUser().then((data) => {
            if (loggedInUser === undefined) {
                let emailAddress = data.attributes.email
                let index = emailAddress.indexOf('@')
                let loggedUserAlias = emailAddress.slice(0,index)
                setLoggedInUser(loggedUserAlias)
            } else {
                setFormState("signedIn");
            }
        });
    }, [loggedInUser])


     /**
     * Validates the user email
     * @param email
     * @returns {boolean}
     */
     function validateEmail(email)
     {
        if(email === undefined || email === '') {
            setEmailErrorText('Email address cannot be empty')
            return false
        }
         let index = email.indexOf('@')
         let emailAddressDomain = email.slice(index + 1)
         const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
         if(emailAddressDomain.toLowerCase() === 'amazon.com' && EMAIL_REGEX.test(email)) {
            setEmailErrorText('')
            return (true)
         } else {
            setEmailErrorText('Please enter valid amazon email address')
            return (false)
         }
     }

     /**
      * Signs Out a User
      */
     async function signOut() {
        try {
            await Auth.signOut()
            /* Once the user successfully signs in, update the form state to show the signed in state */
            setFormState('signIn')
        } catch (err) { console.error("Error occurred while sign out", err) }
    }

    /**
     * Redirects user to Sign In page
     */
     async function setSignIn() {
        setFormState("signIn")
        setEmail('')
    }

    return (
        <>
            <ScrollView width={"100%"}>
                {
                    formState !== 'signedIn' && <SignInHeader/>
                }
                {
                    formState !== 'signedIn' &&
                    <Info heading='Sign Up User Guide'
                        content={
                         <Link href='https://tiny.amazon.com/15feqxzq6/SignUp'>https://tiny.amazon.com/15feqxzq6/SignUp</Link>}
                        showIcon={true}
                        colorScheme='info'
                        status='info'>
                    </Info>
                }
                {   alert !== undefined && 
                    <Info heading= {alert.header}
                        content={alert.body}
                        showIcon={true}
                        colorScheme={alert.status}
                        status ={alert.status}>
                    </Info>
                }
                {
                    formState === 'signIn' && 
                    <SignIn
                        email={email}
                        emailErrorText={emailErrorText} 
                        handleEmailChange={setEmail} 
                        validateEmail={validateEmail} 
                        setAuthType={setAuthType} 
                        setCognitoUser={setCognitoUser} 
                        setAlert={setAlert} 
                        setFormState={setFormState} 
                    />
                }
                {
                    formState === 'signUp' && 
                    <SignUp
                        email={email} 
                        emailErrorText={emailErrorText} 
                        handleEmailChange={setEmail} 
                        validateEmail={validateEmail} 
                        authType={authType} 
                        setAlert={setAlert} 
                        setFormState={setFormState} 
                    />
                }
                {
                    formState === 'signInOTP' && 
                    <SignInOTP 
                        email={email} 
                        cognitoUser={cognitoUser} 
                        setCognitoUser={setCognitoUser} 
                        setAlert={setAlert}
                        setFormState={setFormState}
                    /> 
                }
                {
                    formState === 'confirmUser' && 
                    <ConfirmUser 
                        email={email} 
                        setAlert={setAlert}
                        setFormState={setFormState} 
                    />
                }
            </ScrollView>
            {
                formState === 'signedIn' && 
                <Box>
                    <App signOut={signOut} setSignIn={setSignIn} />
                </Box>
            }
        </>
    )

}
