import {Avatar, Box, Button, Center, Flex, HStack, NativeBaseProvider, Text, View, VStack} from "native-base";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import {getAvettaColorCodeFromCheckInEntryRecord, RE_CHECK_IN_PAGE} from "../../utils/constants";
import React from "react";

const StyledRecord = ({record, buttonLabel, props}) => {
    if  (!record) {
        return null
    }
    const representativeMetaKey = record.supplier_representatives_metadata_v2 ? Object.keys(record.supplier_representatives_metadata_v2) : []
    const siteCode = record.pk
    const sk = record.sk
    const contractorName = record.supplier_company_name
    const country = record.country
    const jobRiskAssessment = record.job_type
    const containsSev = record.containsSev
    const avatarColorBasedOnComplianceLevel = getAvettaColorCodeFromCheckInEntryRecord(record).toLowerCase()

    let curSupplierNamesList = []
    representativeMetaKey.forEach( (key) => {
        const representativeEntry = record.supplier_representatives_metadata_v2[key]
            ? record.supplier_representatives_metadata_v2[key][0] : null;
        if (representativeEntry) {
            let obj = {name: representativeEntry.contractor_name}
            curSupplierNamesList.push(obj)
        }
    })

    const reCheckIn = (props) => {
        // Specific events are not stored and defaulted to 'Sev-1' based on flag containsSev, which will determined Avetta check
        props.checkInFormDataRadioSetters((prev) => {
            return {
                ...prev,
                ['lseEvent']: containsSev? 'Sev-1': undefined
            }
        });
        props.checkInFormDataSetter((prev) => {
            return {
                alias: prev.alias,
                siteCode: siteCode,
                supplierNamesList: curSupplierNamesList,
                sk: sk,
                contractorName: contractorName,
                country: country,
                jobRiskAssessment: jobRiskAssessment
            }
        });
        // this makes sure that validation error message are cleared, if one already exists from previous check-in
        props.clearCheckInFormValidationErrorsMessages();
        props.checkInPageChanger(RE_CHECK_IN_PAGE);
    }

    return (
        <Box borderBottomWidth="1" borderColor="muted.500" pl={["0", "4"]} pr={["0", "5"]} py="2">
            <HStack space={[2, 3]} justifyContent="space-between">
                <Avatar size="30px" bg={`${avatarColorBasedOnComplianceLevel}.500`}/>
                <VStack style={{ width: 250}}>
                    <Text color="coolGray.800" bold>
                        Company Name: {contractorName || "Not Provided"}
                    </Text>
                    <Text color="coolGray.600">
                        Job Type: {record.job_type}
                    </Text>
                    <Text color="coolGray.600" bold>
                        Avetta Status: {record.avetta_compliance_status}
                    </Text>
                    {
                        record.containsSev &&
                        <Text color="red.600" bold>
                            Event
                        </Text>
                    }
                    <Text color="coolGray.600" bold>
                        Representative List:
                    </Text>
                    <Flex direction="row" flexWrap='wrap'>
                        {curSupplierNamesList.map(supplierRepInfo => {
                            return (
                                <View borderRadius="md" bgColor="lightBlue.100" style={{padding: '0.2rem', margin:'2px'}}>
                                    <Text> {supplierRepInfo&&supplierRepInfo.name} </Text>
                                </View>
                            )
                        })}
                    </Flex>
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        <Center>
                            <Button
                                marginTop={"1rem"}
                                bg="info.500"
                                variant="rounded"
                                maxWidth={200}
                                minW={200}
                                onPress={() => reCheckIn(props)}
                            >
                                {buttonLabel}
                            </Button>
                        </Center>
                    </NativeBaseProvider>
                </VStack>
            </HStack>
        </Box>
    )
}

export default StyledRecord;