import { AppRegistry } from "react-native";

// Uncomment this  when ready to switch to Expo and remove the above line
//import {registerRootComponent} from 'expo';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import HomeScreen from "./HomeScreen";
Amplify.configure(awsExports);
/**
 * remove this block of code when switching to expo
 */
AppRegistry.registerComponent("HomeScreen", () => HomeScreen);
AppRegistry.runApplication("HomeScreen", {
    rootTag: document.getElementById("root")
});


// // Uncomment the below line of code when ready to switch to Expo and remove the above line
//registerRootComponent(HomeScreen);