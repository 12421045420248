import { Auth } from 'aws-amplify'
import { CHECK_IN_PATH } from "./utils/constants";
import { getSupplierCheckInApiEndpoint, getSupportedRegions } from "./config"

// Get checkins for last 90 day(s) for the CPOC
const getCheckins = async (setCheckInData, setPendingCheckOutList, setMyCheckInList) => {
    try {
        let token = "";
        Auth.currentAuthenticatedUser().then(async (data) => {
          token = data.signInUserSession.idToken.jwtToken;
          const cpocEmail = data.attributes.email
          const alias = cpocEmail.substring(0, cpocEmail.lastIndexOf("@"));
          let requestHeaders = new Headers();
          requestHeaders.append("Authorization", "Bearer " + token);
          requestHeaders.append("operation_type", 'GetLastXDaysForAliasSKGSI');
          requestHeaders.append("query_gsi_alias", alias);
          requestHeaders.append("last_x_days", '90');
          const requestOptions = {
            method: "GET",
            headers: requestHeaders,
            redirect: "follow",
          };
          let checkInList = [];
          for(const region of getSupportedRegions()) {
            const url = getSupplierCheckInApiEndpoint(region) + CHECK_IN_PATH;
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                const checkInData = await response.json()
                checkInList.push(...checkInData.Items);
            }
          }
          setCheckInData(checkInList);
          setPendingCheckOutList(getCheckInListWithOrWithoutCheckedOut(checkInList, true));
          setMyCheckInList(getCheckInListWithOrWithoutCheckedOut(checkInList, false));
        });
    } catch (err) {
    }
}

function constructCheckInRecord(checkInEntry, representativesMetaKey, representativesMetaData, avettaColorCode) {
    let checkInObj = {};

    // Data from checkInEntry
    checkInObj['pk'] = checkInEntry.pk;
    checkInObj['sk'] = checkInEntry.sk;
    checkInObj['jobType'] = checkInEntry.job_type;
    checkInObj['avetta_status'] = checkInEntry.avetta_compliance_status;
    checkInObj['avetta_color_code'] = avettaColorCode
    checkInObj['eventFlag'] = checkInEntry.containsSev;
    checkInObj['supplierCompanyName'] = checkInEntry.supplier_company_name || '';
    checkInObj['checkInBy'] = checkInEntry.alias;
    checkInObj['country'] = checkInEntry.country;
    checkInObj['siteCode'] = checkInEntry.pk;
    // This field is used in checkout lambda to update the correct meta_data attribute
    checkInObj['isMetadataV2Available'] = checkInEntry.supplier_representatives_metadata_v2 !== undefined

    // Data from representativesMetaData, which is the children of checkInEntry
    checkInObj['supplierNameKey'] = representativesMetaKey;
    checkInObj['name'] = representativesMetaData.contractor_name;
    checkInObj['checkInTime'] = new Date(representativesMetaData.checkInTime).toLocaleString();
    checkInObj['checkedOut'] = representativesMetaData.checkedOut;
    checkInObj['checkOutTime'] =
        representativesMetaData.actualCheckOutTime ? new Date(representativesMetaData.actualCheckOutTime).toLocaleString() : ""
    checkInObj['index'] = representativesMetaData.index ? representativesMetaData.index : 0;

    return checkInObj;
}

export function getCheckInListWithOrWithoutCheckedOut(checkInList, excludeCheckedOut) {
    const checkInsList = []
    checkInList.forEach((checkInEntry) => {
        // Default to color gray if company is not found
        let avetta_color_code = (
            checkInEntry.avetta_compliance_status_code === 'NA' ||
            checkInEntry.avetta_compliance_status_code === 'Not Found' ||
            checkInEntry.avetta_compliance_status_code === undefined ||
            checkInEntry.avetta_compliance_status_code === ''
        ) ? 'gray' : checkInEntry.avetta_compliance_status_code;

        // If supplier_representatives_metadata_v2 exists, then use it. Otherwise, use supplier_representatives_metadata
        let supplierRepEntries = checkInEntry.supplier_representatives_metadata_v2
            ? Object.entries(checkInEntry.supplier_representatives_metadata_v2)
            : Object.entries(checkInEntry.supplier_representatives_metadata);

        // Loop through all the entries of supplier_representatives_metadata_v2 or supplier_representatives_metadata
        supplierRepEntries.forEach(supplierInfo => {
            // If using supplier_representatives_metadata_v2 then supplierInfo[1] is an array of representatives records,
            // we find the entry with the biggest index value, that is the latest representatives records
            // If using supplier_representatives_metadata, then we use supplierInfo[1] directly since is just an object
            const representativesDetail = checkInEntry.supplier_representatives_metadata_v2 && Array.isArray(supplierInfo[1])
                ? supplierInfo[1].reduce((prev, current) => (prev.index > current.index) ? prev : current)
                : supplierInfo[1];

            const checkInRecord = constructCheckInRecord(checkInEntry, supplierInfo[0], representativesDetail, avetta_color_code)

            // Logic to decide whether to include the checkin record in the list
            if(excludeCheckedOut){
                if(!representativesDetail.checkedOut) {
                    checkInsList.push(checkInRecord);
                }
            } else {
                checkInsList.push(checkInRecord);
            }
        })
    })
    return checkInsList
}

export default getCheckins