import {Alert, Box, Center, CloseIcon, HStack, IconButton, VStack} from "native-base";
import {Text} from "react-native";
import {withAuthenticator} from "@aws-amplify/ui-react";
import {useState} from "react";

function showClose(props) {
    if (props.showClose === true) {
        return <IconButton variant="unstyled" _focus={{
            borderWidth: 0
        }} icon={<CloseIcon size="3" />} _icon={{
            color: "coolGray.600"
        }} />
    }
}

/**
 * https://docs.nativebase.io/alert
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Banner(props) {
    const [colorScheme, setColorScheme] = useState(props.colorScheme ? props.colorScheme: "info")
    const [status, setStatus] = useState(props.status ? props.status: "info")
    const [textColorScheme, setTextColorScheme] = useState(props.textColorScheme ? props.textColorScheme : "coolGray")
    return (
        <Alert padding={'1rem'} minW={300} maxW={props.maxWidth || "300"} status={status} colorScheme={colorScheme} marginTop={"2rem"}>
            <VStack space={2} flexShrink={1}>
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        { props.showIcon === true && <Alert.Icon /> }
                        <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                            {props.heading}
                        </Text>
                    </HStack>
                </HStack>
                <Box  _text={{
                    color: `${textColorScheme}.600`,
                }}>
                    {"All contractors/subcontractors must check-in.\n"}
                </Box>
                <Box _text={{
                    color: `${textColorScheme}.600`
                }}>
                    {"Exception(s):"}
                    <Box  _text={{
                        color: `${textColorScheme}.600`,
                        bold: true
                    }}>
                        {"Sole Proprietor(s)"}
                    </Box>
                </Box>
                <Box  _text={{
                    color: `${textColorScheme}.600`,
                    bold: true
                }}>
                    {"Yellow badges"}
                    <Box  _text={{
                        color: `${textColorScheme}.600`,
                    }}>
                        {"(e.g. Security Guards, PPE, Food Vending Attendants, PIT Repair, Janitorial Staff, RME)"}
                    </Box>
                </Box>
                <Box>
                    {"The Supplier Check-In/Out Application does not replace existing security process or any other process to bring a supplier on site."}
                </Box>

            </VStack>
        </Alert>
    )
}


export default withAuthenticator(Banner);