import {withAuthenticator} from "@aws-amplify/ui-react";
import TextComponent from "../components/TextInputComponent/component";
import React from "react";
import {View} from "react-native-web";
import {Button, NativeBaseProvider} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import DynamicTextComponent from "../components/DynamicTextComponent/dynamic-text-component";
import {MAX_DAYS_ON_SITE} from "../utils/constants";

const ACCEPTABLE_DAYS_RANGE = 'Acceptable days range can be between 1 and 15';

function Page2Extended(props) {
    const validatePage2Extended = (props) => {
        if (props.checkInFormData['supplierNamesList'] === undefined || props.checkInFormData['supplierNamesList'].length === 0 ) {
            props.checkInValidationSetter((prev) => { return  {...prev, supplierNamesList : 'Please provide at least a supplier name and click + button'} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, supplierNamesList : ''} })
        }
        if (props.checkInFormData['daysOnSite'] === undefined || props.checkInFormData['daysOnSite'] === ''){
            props.checkInValidationSetter((prev) => { return  {...prev, daysOnSite : 'Please provide number of days on site'} })
            return;
        } else if (isNaN(props.checkInFormData['daysOnSite']) || props.checkInFormData['daysOnSite'] > MAX_DAYS_ON_SITE || props.checkInFormData['daysOnSite'] <= 0) {
            props.checkInValidationSetter((prev) => { return  {...prev, daysOnSite : ACCEPTABLE_DAYS_RANGE} })
            return;
        } else {
            props.checkInValidationSetter((prev) => { return  {...prev, daysOnSite : ''} })
        }

        // if everything is great then move on
        props.checkInPageChanger(4)
    }

    return (
        <View style={{flex: 1}} >
            <View style={{ flex: 4}}>
                <DynamicTextComponent attributeName = 'supplierNamesList'
                                      placeholder='Full Name'
                                      {... props}/>
            </View>
            <TextComponent label = "Days on site"
                           marginTop="1rem"
                           placeholder = "Days on Site"
                           required = {true}
                           attributeName='daysOnSite'
                           {... props}
            />
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Button marginTop={"0.5rem"}
                        colorScheme="lightBlue"
                        onPress= {() => validatePage2Extended(props)}
                        variant="rounded"> Next </Button>
                <Button marginTop={"1rem"}
                        colorScheme="lightBlue"
                        onPress= {() => props.checkInPageChanger(2)}
                        variant="rounded"> Previous </Button>
            </NativeBaseProvider>
        </View>
    );
}

export default withAuthenticator(Page2Extended);