import {withAuthenticator} from "@aws-amplify/ui-react";
import React, {useState} from "react";
import {View} from "react-native-web";
import {
    NativeBaseProvider
} from "native-base";

/** TODO  https://issues.amazon.com/issues/ISC-ST-1934 */
import Page1 from "./Page1";
import CheckInFailure from "./checkin-failure";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page2Extended from "./Page2.1";
import ReviewCheckIn from "./review-check-in";
import CheckInNextSteps from "./check-in-next-steps";
import CheckInThankYou from "./check-in-thank-you";
import MultiDateCheckInPage from "./multi-date-check-in";
import {HOME_PAGE_INDEX, MULTI_CHECK_IN_PAGE, RE_CHECK_IN_PAGE, REVIEW_RE_CHECK_IN_PAGE} from "../utils/constants";
import ReCheckIn from "./ReCheckIn/re-check-in";
import ReviewReCheckIn from "./ReCheckIn/review-re-check-in";

function CheckInWorkflow(props) {
    const [checkInPage, setCheckInPage] = useState(1);

    return (
        <NativeBaseProvider>
            <View>
                { checkInPage === 1 &&
                    <Page1
                        page={1}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                { checkInPage === 2 &&
                    <Page2
                    page={2}
                    pageChanger={props.pageChanger}
                    checkInPageChanger={setCheckInPage}
                    { ...props }
                    />
                }
                { checkInPage === 3 &&

                    <Page2Extended
                        page={3}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                {
                    checkInPage === 4 &&
                    <Page3
                        page={4}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                {
                    checkInPage === 5 &&
                    <ReviewCheckIn
                        page={5}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                {
                    checkInPage === 6 &&
                    <CheckInNextSteps
                        page={6}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        signOut={props.signOut}
                        user= {props.user}
                        { ...props }
                    />
                }
                {
                    checkInPage === 7 && renderThankYouPage(props, setCheckInPage)
                }
                {
                    checkInPage === 8 && renderFailurePage(props, setCheckInPage)
                }
                {
                    checkInPage === MULTI_CHECK_IN_PAGE &&
                    <MultiDateCheckInPage
                        page={MULTI_CHECK_IN_PAGE}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        checkInData={props.checkInData}
                        checkInFormDataRadioSetters = {props.lseRadioSetter}
                        { ...props }
                    />
                }
                {
                    checkInPage == RE_CHECK_IN_PAGE &&
                    <ReCheckIn
                        page={RE_CHECK_IN_PAGE}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                {
                    checkInPage === REVIEW_RE_CHECK_IN_PAGE &&
                    <ReviewReCheckIn
                        page={REVIEW_RE_CHECK_IN_PAGE}
                        pageChanger={props.pageChanger}
                        checkInPageChanger={setCheckInPage}
                        { ...props }
                    />
                }
                {
                    checkInPage === HOME_PAGE_INDEX && props.pageChanger(HOME_PAGE_INDEX) && props.clearCheckInForm()
                }
            </View>
        </NativeBaseProvider>
    )

}

/**
 * Adding render method to make it readable
 * @param props
 * @param setCheckInPage
 * @returns {JSX.Element}
 */
function renderFailurePage (props, setCheckInPage) {
    return (
        <CheckInFailure
            page={8}
            pageChanger={props.pageChanger}
            checkInPageChanger={setCheckInPage}
            signOut={props.signOut}
            user= {props.user}
            { ...props }
        />
    )
}

/**
 * Adding render method to make it readable
 * @param props
 * @param setCheckInPage
 * @returns {JSX.Element}
 */
function renderThankYouPage (props, setCheckInPage) {
    return (
        <CheckInThankYou
            page={7}
            pageChanger={props.pageChanger}
            checkInPageChanger={setCheckInPage}
            signOut={props.signOut}
            user= {props.user}
            { ...props }
        />
    )
}

export default withAuthenticator(CheckInWorkflow)