import React from "react";
import {Box, Center, NativeBaseProvider, Select} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Info from "../components/Info/info";
import RateAppModal from "../components/Modal/rate-app-modal";
import {STOP_WORKFLOW} from "../components/TextInputComponent/component";
import {CHECKIN_PAGE_INDEX_END_INCLUSIVE, HOME_PAGE_INDEX} from "../utils/constants";

const AVETTA_COMPLIANCE_CPOC_NEXT_STEPS = 'avettaComplianceCpocNextSteps'

function getNextMessages(nextStepMessages) {
    let _ = require('lodash')
    let messages = []
    let status = nextStepMessages.status
    _.forEach(nextStepMessages.steps, function (message) {
        messages.push(message + '\n')
    })
    // reusing the alert to show the further info/action customer need to take. It also does not make sense to icon for this message box.
    return <Center><Info content={messages} status={status} showIcon={false}/></Center>
}

function CheckInNextSteps(props) {

    // only uncomment for testing
    // useEffect(()=> {
    //     console.log("Next Steps-> state of the form Now is " + JSON.stringify(props.checkInFormData))
    // }, [props.checkInFormData])

    let messages = getNextMessages({...props.checkInFormData[AVETTA_COMPLIANCE_CPOC_NEXT_STEPS]})
    let companyName = props.checkInFormData['contractorName'] !== undefined ? `from Company ${props.checkInFormData['contractorName']}` : ''
    let stopWorkflow =  props.checkInFormData[STOP_WORKFLOW]

    const message = `Please acknowledge (below) steps and follow them to ensure safety of contractors and our employees.`
    return (
        <Box>
            <Center>
                <Info heading= 'Your data has been saved'
                      content=
                          {
                            stopWorkflow !== true ?
                          `Supplier Representatives ${companyName} has been checked In! \n\n${message}` :
                          `Supplier Representatives ${companyName} cannot be checked In! \n\n${message}`
                        }
                      status= {stopWorkflow === true ? `error` :  `info`}
                      showIcon={ false }
                />
            </Center>
            { messages }
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <RateAppModal animationType={'slide'}
                              nextPage={stopWorkflow !== true ? CHECKIN_PAGE_INDEX_END_INCLUSIVE : HOME_PAGE_INDEX}
                              pageChanger={props.checkInPageChanger}
                              />
            </NativeBaseProvider>
        </Box>
    )
}

export default withAuthenticator(CheckInNextSteps)