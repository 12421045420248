import {FormControl, TextArea, WarningOutlineIcon} from "native-base";
import {withAuthenticator} from "@aws-amplify/ui-react";
import React from "react";
import {useState} from "react";

function TextAreaComponent(props) {

    const [attributeName, setAttributeName] = useState(props.attributeName)
    const [textAreaValue, setTextAreaValue] = React.useState(props.checkInFormData[props.attributeName] !== undefined
        ? props.checkInFormData[props.attributeName] : '');

    const handleChange = text => {
        setTextAreaValue(text);
        props.checkInFormDataSetter({
            ...props.checkInFormData,
            [attributeName] : text
        });
        // clear the errors on valid user selection
        props.checkInValidationSetter((prev) => { return  {...prev, [attributeName] : ''} })
    }

    return (
        <FormControl isRequired = {props.required} isInvalid={props.checkInValidationError !== undefined && attributeName in props.checkInValidationError && props.checkInValidationError[attributeName] !== ''}>
        <TextArea isRequired={props.required} value={textAreaValue}
                  onChange={e => {
                      setTextAreaValue(e.currentTarget.value)
                      props.checkInFormDataSetter({
                          ...props.checkInFormData,
                          [attributeName] : e.currentTarget.value
                      });
                  }} // for web
                  onChangeText={text => {
                      setTextAreaValue(text)
                      props.checkInFormDataSetter({
                          ...props.checkInFormData,
                          [attributeName] : text
                      });
                  }
                  } // for android and ios
                  minW={props.minWidth}
                  maxW={props.maxWidth}
                  placeholder={props.placeholder}
                  maxlength={props.maxLength}
        />
            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                { props.checkInValidationError !== undefined && props.checkInValidationError[attributeName] }
            </FormControl.ErrorMessage>
        </FormControl>
    )
}

export default withAuthenticator(TextAreaComponent)