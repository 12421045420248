import {withAuthenticator} from "@aws-amplify/ui-react";
import {Box, Button, Center, NativeBaseProvider} from "native-base";
import {styles} from "../../styles/style";
import SimpleDropdown from "../../components/SimpleDropdown/simple-dropdown";
import {JOB_TYPE_ACTIVITY_LIST} from "../../constants/activity-job-list-constants";
import SimpleRadio from "../../components/SimpleRadio/simple-radio";
import {RoundedButtonTheme} from "../../themes/rounded-button-theme";
import {View} from "react-native-web";
import React from "react";
import TextComponent from "../../components/TextInputComponent/component";
import {useState, useEffect} from "react";
import {ORGANIZATIONS_DROPDOWN_LIST} from "../../constants/org-names-dropdown-constants";
import {getProfile, saveProfile} from "../../service-integrations/profile";
import {
    PROFILE_CONTRACTOR,
    PROFILE_COUNTRY,
    PROFILE_JOB_ASSESSMENT,
    PROFILE_LSE_EVENT, PROFILE_ORG,
    PROFILE_SITE_CODE
} from "../../constants/attribute-names-constants";

function ProfileSetup(props) {
    const [nextDisabled, setNextDisabled] = useState(true)
    const [prevDisabled, setPrevDisabled] = useState(false)
    const [stopCheckInFlow, setStopCheckInFlow] = useState(false)

    // useEffect(() => {
    //     props.checkInFormDataSetter((prev) => { return  {...prev, country : 'uk'} })
    // })
    // un comment only for testing
    useEffect(()=> {
        console.log("useEffect" + JSON.stringify(props.checkInFormData))
    }, [props.checkInFormData])

    // useEffect(()=> {
    //     getProfile(props).then(() => {});
    //   console.log("useEffect%My Profile-> data" + JSON.stringify(props.checkInFormData))
    // }, []);

    const validateAndSaveProfile = (props) => {
        // if (props.checkInFormData['country'] === undefined || props.checkInFormData['country'] === '' ) {
        //     props.checkInValidationSetter((prev) => { return  {...prev, country : 'Please select country'} })
        //     return;
        // } else {
        //     props.checkInValidationSetter((prev) => { return  {...prev, country : ''} })
        // }
        // if (props.checkInFormData['siteCode'] === undefined || props.checkInFormData['siteCode'] === ''){
        //     props.checkInValidationSetter((prev) => { return  {...prev, siteCode : 'Please select site code'} })
        //     return;
        // } else {
        //     props.checkInValidationSetter((prev) => { return  {...prev, siteCode : ''} })
        // }
        // if (props.checkInFormData['jobRiskAssessment'] === undefined || props.checkInFormData['jobRiskAssessment'] === ''){
        //     props.checkInValidationSetter((prev) => { return  {...prev, jobRiskAssessment : 'Please select job operation'} })
        //     return;
        // } else {
        //     props.checkInValidationSetter((prev) => { return  {...prev, jobRiskAssessment : ''} })
        // }
        // if (props.lseRadioData['lseEvent'] === undefined || props.lseRadioData['lseEvent'] === ''){
        //     props.checkInValidationSetter((prev) => { return  {...prev, 'lseEvent' : 'Please select an event'} })
        //     return;
        // } else {
        //     props.checkInValidationSetter((prev) => { return  {...prev, 'lseEvent' : ''} })
        // }
        // if everything is great then move on
        //props.checkInPageChanger(2)

        saveProfile(props).then((resolve, reject) => {});
    }
    return (
        <View style={styles.header}>
            <Box>
                <SimpleDropdown label = "Country where services being provided"
                                placeholder = "In what Country are services being provided?"
                                required = {true}
                                minWidth={300}
                                options = {[
                                    {
                                        id: 'US',
                                        value: 'us'
                                    },
                                    {
                                        id: 'CA',
                                        value: 'ca'
                                    },
                                    {
                                        id: 'UK',
                                        value: 'uk'
                                    }
                                ]}
                                defaultValue = 'us'
                                attributeName = {PROFILE_COUNTRY}
                                { ...props }

                />

                <SimpleDropdown label = "Site code"
                                placeholder = "Site code"
                                required = {true}
                                minWidth={300}
                                // TODO: Get Site Dropdown List based on country
                                options = {[]}
                                defaultValue='DSM5'
                                attributeName = {PROFILE_SITE_CODE}
                                { ...props }

                />

                <SimpleDropdown label = "Type of work being performed"
                                placeholder = "Type of work being performed"
                                required = {true}
                                minWidth={300}
                                options = {JOB_TYPE_ACTIVITY_LIST}
                                defaultValue='Fix Conveyer- HRO'
                                attributeName = {PROFILE_JOB_ASSESSMENT}
                                { ...props }
                />


                <SimpleRadio label = "Select an event (If applicable)"
                             placeholder = "Select an event (If applicable)"
                             required = {true}
                             colorScheme = "red"
                             options = {[
                                 {
                                     id: 'Sev-1',
                                     value: 'Sev-1',
                                     key: 'sev1'
                                 },
                                 {
                                     id: 'Sev-2',
                                     value: 'Sev-2',
                                     key: 'sev2'
                                 },
                                 {
                                     id: 'Not applicable',
                                     value: 'Not Applicable',
                                     key: 'NA'
                                 }
                             ]}
                             attributeName = {PROFILE_LSE_EVENT}
                             checkInFormDataRadio = {props.lseRadioData}
                             checkInFormDataRadioSetters = {props.lseRadioSetter}
                             { ...props }
                />

                <SimpleDropdown label = "Provide your org name"
                                placeholder = "Provide your org name"
                                required = {true}
                                minWidth={100}
                                maxWidth={300}
                                options = {ORGANIZATIONS_DROPDOWN_LIST}
                                defaultValue='GPT'
                                attributeName = {PROFILE_ORG}
                                { ...props }
                />

                <TextComponent placeholder = "Contractor/Vendor Company"
                               required={true}
                               label = "Name of the company providing services?"
                               maxWidth='300'
                               minWidth='300'
                               rightElement = {true}
                               setNextDisabled = {setNextDisabled}
                               setPrevDisabled = {setPrevDisabled}
                               setStopCheckInFlow = {setStopCheckInFlow}
                               attributeName = {PROFILE_CONTRACTOR}
                               {...props}
                />

            </Box>
            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Center>
                    <Button marginTop={"1rem"}
                            minW={300}
                            colorScheme="lightBlue"
                            onPress={() => validateAndSaveProfile(props)}
                            variant="rounded"
                    >Save</Button>
                </Center>
            </NativeBaseProvider>
        </View>
    )
}

export default withAuthenticator(ProfileSetup)