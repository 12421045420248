import React from "react";
import {Box, Button, Center, Divider, FormControl, NativeBaseProvider, View} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Info from "../components/Info/info";
import {HOME_PAGE_INDEX} from "../utils/constants";

function CheckInThankYou(props) {
    return (
        <View marginTop={"2rem"}>
            <Center>
                <Info heading= 'Thank you'
                      content={`Thank you ${props.checkInFormData['alias']} for using this App to Check In !`}
                      status='success'
                      showIcon={ true }
                />
            </Center>
            <Center>
                <Info heading= 'About App'
                      content={`Designed and developed by GPO Tech Organization!`}
                      status='info'
                      showIcon={ true }
                />
            </Center>

            <NativeBaseProvider theme={RoundedButtonTheme}>
                <Center>
                    <Button marginTop={"1rem"}
                            minW={300}
                            colorScheme="lightBlue"
                            onPress= {() => {
                                props.checkInFormDataSetter(previous => {
                                    return {
                                        ...previous,
                                        supplierNamesList: []
                                    }
                                });
                                props.checkInPageChanger(1)
                            }
                    }
                            variant="rounded"> Add more representatives
                    </Button>
                    <Button marginTop={"1rem"}
                            minW={300}
                            colorScheme="lightBlue"
                            onPress= {() => {
                                props.clearCheckInForm()
                                props.pageChanger(HOME_PAGE_INDEX)
                            }}
                            variant="rounded"> I'm done
                    </Button>
                </Center>
            </NativeBaseProvider>
        </View>
    )
}

export default withAuthenticator(CheckInThankYou)