import {AlertDialog, Button, Center} from "native-base";
import {withAuthenticator} from "@aws-amplify/ui-react";
import React, {useRef} from "react";

const AlertDialogComponent = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const onClose = () => {
        setIsOpen(false);
        // submit the data here either checkIn/Checkout
        // setTimeout(() => {
        //     props.checkInPageChanger(props.thankYouPage)
        // }, 5000)
    }

    const cancelRef = useRef(null);
    return <Center>
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Submit</AlertDialog.Header>
                <AlertDialog.Body>
                    You are about to submit data.
                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button variant="unstyled" colorScheme="coolGray" onPress={onClose} ref={cancelRef}>
                            Cancel
                        </Button>
                        <Button variant={'rounded'} colorScheme="danger" onPress={onClose}>
                            Submit
                        </Button>
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    </Center>;
};

export default withAuthenticator(AlertDialogComponent);