import React, {useEffect} from "react";
import {Badge, Box, Button, Center, Container, FormControl, HStack, NativeBaseProvider, VStack} from "native-base";
import {RoundedButtonTheme} from "../themes/rounded-button-theme";
import getCheckins from "../getCheckIns";
import {
    CHECKIN_LIST_PAGE_INDEX, CHECKIN_PAGE_INDEX_START_INCLUSIVE,
    CHECKOUT_PAGE_ACTION_PAGE_INDEX,
    SITE_CHECKINS_FORM,
    YELLOW_BADGE_SETUP
} from "../utils/constants";
import Banner from "../components/Banner/banner";

export function WelcomePage({ pageChanger,
                                signOut,
                                user,
                                checkInDataSetter,
                                pendingCheckoutListSetter,
                                myCheckInListDataSetter,
                                checkInData,
                                pendingCheckOutList,
                                myCheckInList,
                                siteCheckInListSetter,
                                siteCheckInList,
                                clearCheckInForm,
                                clearCheckInFormValidationErrorsMessages }) {
    useEffect(() => {
        getCheckins(checkInDataSetter, pendingCheckoutListSetter, myCheckInListDataSetter)
    }, []);

    function handleOnPressForFreshCheckIn() {
        // clears form data on every fresh check in
        clearCheckInForm();
        // clears previously existing validation error messages on every fresh check in
        clearCheckInFormValidationErrorsMessages();
        // directs user to fresh check in page
        pageChanger(CHECKIN_PAGE_INDEX_START_INCLUSIVE);
    }

    return (
        <Box>
                <Center>
                    <Banner
                          heading= "We are live!"
                    />
                </Center>
                <VStack>
                    <NativeBaseProvider theme={RoundedButtonTheme}>
                        <Center>
                            <FormControl.Label>Welcome to Vendor/Contractor Check In tool</FormControl.Label>
                        </Center>
                        <Button size={"lg"} minWidth={300} marginTop={"2rem"} colorScheme="lightBlue" onPress={() => handleOnPressForFreshCheckIn()
                        } variant="rounded"> Check-In Contractors(s)/Subcontractor(s) </Button>
                            <VStack>
                                <Badge
                                    marginTop={"2rem"}
                                    marginRight={"0.5rem"}
                                    colorScheme={pendingCheckOutList.length === 0 ? "success:" : "danger"}
                                    rounded="full" mb={-4} mr={-4} zIndex={1} variant="solid" alignSelf="flex-end" _text={{
                                    fontSize: 12
                                }}>
                                    {pendingCheckOutList.length}
                                </Badge>
                                <Button bg={pendingCheckOutList.length === 0 ? "green.500": "orange.500"} size={"lg"} minWidth={300} colorScheme="orange" onPress={() =>
                                    pageChanger(CHECKOUT_PAGE_ACTION_PAGE_INDEX)
                                } variant="rounded"> My Pending Check-Outs </Button>
                            </VStack>
                            <Button bg="green.500"
                                    marginTop={"2rem"}
                                    marginBottom={"2rem"}
                                    size={"lg"}
                                    minWidth={300}
                                    colorScheme="lightBlue" onPress={() =>
                                pageChanger(CHECKIN_LIST_PAGE_INDEX)
                            } variant="rounded"> My Check-Ins history </Button>
                        <Button bg={"lightBlue.500"}
                                size={"lg"}
                                minWidth={300}
                                colorScheme={"lightBlue.500"}
                                onPress={
                                    () =>
                                        pageChanger(SITE_CHECKINS_FORM)
                                } variant="rounded"> Avetta Compliance by Site </Button>
                        {/*<Button bg={"lightBlue.500"}*/}
                        {/*        size={"lg"}*/}
                        {/*        marginTop={"2rem"}*/}
                        {/*        minWidth={300}*/}
                        {/*        colorScheme={"lightBlue.500"}*/}
                        {/*        onPress={*/}
                        {/*            () =>*/}
                        {/*                pageChanger(YELLOW_BADGE_SETUP)*/}
                        {/*        } variant="rounded"> My Yellow Badge Employees </Button>*/}
                    </NativeBaseProvider>
                </VStack>
            </Box>
    )
}